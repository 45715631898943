import img1 from '@assets/Personalization/collage/img1.png'
import img2 from '@assets/Personalization/collage/img2.png'
import img3 from '@assets/Personalization/collage/img3.png'
import img4 from '@assets/Personalization/collage/img4.png'
import img5 from '@assets/Personalization/collage/img5.png'

const ImageGridPersonalization = () => {
  return (
    <div className='relative mx-auto grid w-full max-w-[1300px] grid-cols-4 items-center justify-center gap-3 bg-black'>
      {/* Imagen izquierda superior */}
      <div className='relative col-span-1'>
        <img
          src={img4}
          alt='Image 1'
          className='h-auto w-full object-cover'
        />
      </div>

      {/* Imagen central más grande */}
      <div className='relative col-span-2 row-span-2 p-[1px]'>
        <img
          src={img1}
          alt='Image 3'
          className='h-auto w-full object-cover'
        />
      </div>
      {/* Imagen derecha superior */}
      <div className='relative col-span-1'>
        <img
          src={img5}
          alt='Image 2'
          className='h-auto w-full object-cover'
        />
      </div>

      {/* Imagen izquierda inferior */}
      <div className='relative col-span-1'>
        <img
          src={img3}
          alt='Image 4'
          className='h-auto w-full object-cover'
        />
      </div>

      {/* Imagen derecha inferior */}
      <div className='relative col-span-1'>
        <img
          src={img2}
          alt='Image 5'
          className='h-auto w-full object-cover'
        />
      </div>
    </div>
  )
}
export default ImageGridPersonalization
