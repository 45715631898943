import estrella from '@assets/estrella.png'
import logo from '@assets/logo.png'
import usuario from '@assets/usuario.png'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../store'
import handleLogout from './actions/handleLogout'
import { useWebsocketContext } from './Context'

type Props = {
  imgRedirectHome: boolean
  creditosActuales?: number
}
export default function Navbar({ imgRedirectHome = false, creditosActuales }: Props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const texto = store((s) => s.translations)
  const token = store((s) => s.userToken) || sessionStorage.getItem('userToken')
  const navigate = useNavigate()
  const userInfo = store((s) => s.userInfo)
  const { connection } = useWebsocketContext()
  const name = userInfo.name ? userInfo.name.split(' ')[0]?.toUpperCase() : ''
  function t(t: string) {
    return texto[t] ?? t
  }
  function handleLogoClick() {
    if (imgRedirectHome) {
      handleScrollAndRedirect('/')
    }
  }
  function handleUserMenuToggle() {
    setUserMenuOpen((prev) => !prev)
  }
  async function handleUserLogout() {
    setUserMenuOpen(false)
    await handleLogout(connection)
    setTimeout(() => {
      sessionStorage.removeItem('userToken')
      window.location.href = '/'
    }, 500)
  }
  const handleScrollAndRedirect = (path: string, sectionId?: string): void => {
    window.scrollTo(0, 0)
    if (window.location.pathname === '/' && sectionId) {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      navigate(path)
      if (sectionId) {
        setTimeout(() => {
          const section = document.getElementById(sectionId)
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' })
          }
        }, 500)
      }
    }
  }
  return (
    <nav className='fixed z-30 w-full bg-black bg-opacity-80 text-white'>
      <div className='flex w-full items-center justify-between px-4 py-3'>
        <img
          src={logo}
          onClick={handleLogoClick}
          alt='Logo For2Inspiration'
          className='h-12 cursor-pointer'
          loading='lazy'
        />

        <div className='hidden flex-grow items-center justify-end space-x-6 md:flex'>
          <div
            onClick={() => handleScrollAndRedirect('/')}
            className='cursor-pointer hover:text-gray-400'
          >
            {t('MSG_217')}
          </div>
          {token ? (
            <>
              <div className='justify-end space-x-6 md:flex'>
                <div
                  onClick={() => handleScrollAndRedirect('/user')}
                  className='cursor-pointer hover:text-gray-400'
                >
                  {t('MSG_422')}
                </div>
              </div>
              <div
                onClick={() => handleScrollAndRedirect('/user/plans')}
                className='cursor-pointer hover:text-gray-400'
              >
                <div className='flex h-8 w-20 items-center justify-between rounded-lg border-[1.5px] border-white bg-main'>
                  <img
                    src={estrella}
                    alt='Star image'
                    className='ml-1 mt-0.5 h-6 w-6'
                  />
                  <p className='mr-1.5 pt-1 text-white'>{creditosActuales}</p>
                </div>
              </div>
              <div className='relative'>
                <button
                  onClick={handleUserMenuToggle}
                  className='flex hover:text-gray-400 focus:outline-none'
                >
                  <img
                    src={usuario}
                    alt='Profile image'
                    className='h-8 w-8'
                  />
                  <p className='text-md ml-2 mr-2 mt-1.5 font-creato-medium text-main'>{name}</p>
                </button>
                <div
                  className={`absolute right-0 z-20 mt-5 w-[13rem] rounded-md bg-black bg-opacity-50 py-2 shadow-xl ${
                    userMenuOpen ? 'block' : 'hidden'
                  }`}
                >
                  <div
                    onClick={() => handleScrollAndRedirect('/')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_105')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/gallery')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_486')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/', 'solutions')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_484')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/user/modify-data')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_444')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/user')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_163')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/user/plans')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_107')}
                  </div>
                  <div
                    onClick={handleUserLogout}
                    className='block cursor-pointer px-4 py-2 text-sm text-main hover:bg-gray-700'
                  >
                    {t('MSG_99')}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='relative'>
                <button
                  onClick={handleUserMenuToggle}
                  className='flex hover:text-gray-400 focus:outline-none'
                >
                  <div className='hover:text-gray-400'>{t('MSG_51')} </div>
                </button>
                <div
                  className={`absolute right-0 z-20 mt-6 w-44 rounded-md bg-black bg-opacity-50 py-2 shadow-xl ${
                    userMenuOpen ? 'block' : 'hidden'
                  }`}
                >
                  <div
                    onClick={() => handleScrollAndRedirect('/inspiration/info')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_28')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/decoration/info')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_30')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/redecoration/info')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_32')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/rendering/info')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_455')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/exteriors/info')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_475')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/planner/info')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_494')}
                  </div>
                  <div
                    onClick={() => handleScrollAndRedirect('/personalization/info')}
                    className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
                  >
                    {t('MSG_515')}
                  </div>
                </div>
              </div>
              <div
                onClick={() => handleScrollAndRedirect('/plans')}
                className='cursor-pointer hover:text-gray-400'
              >
                {t('MSG_57')}
              </div>
              <div
                onClick={() => handleScrollAndRedirect('/gallery')}
                className='cursor-pointer hover:text-gray-400'
              >
                {t('MSG_485')}
              </div>
              <div
                onClick={() => handleScrollAndRedirect('/login')}
                className='mr-4 cursor-pointer text-main hover:text-gray-400'
              >
                {t('MSG_58')}
              </div>
            </>
          )}
        </div>
        <div className='md:hidden'>
          <button
            onClick={() => setMenuOpen((prev) => !prev)}
            aria-label={menuOpen ? 'Cerrar menú' : 'Abrir menú'}
            aria-expanded={menuOpen}
            className='relative flex h-6 w-6 flex-col items-center justify-center'
          >
            <span
              className={`block h-0.5 w-6 bg-current transition-transform duration-300 ease-in-out ${
                menuOpen ? 'translate-y-0.5 rotate-45' : '-translate-y-1.5'
              }`}
            ></span>
            <span
              className={`block h-0.5 w-6 bg-current transition-opacity duration-300 ease-in-out ${
                menuOpen ? 'opacity-0' : 'opacity-100'
              }`}
            ></span>
            <span
              className={`block h-0.5 w-6 bg-current transition-transform duration-300 ease-in-out ${
                menuOpen ? '-translate-y-0.5 -rotate-45' : 'translate-y-1.5'
              }`}
            ></span>
          </button>
        </div>
      </div>
      {/* Mobile menu */}
      <div
        className={`md:hidden ${menuOpen ? 'block' : 'hidden'} relative z-20 bg-black bg-opacity-40`}
      >
        <div
          onClick={() => handleScrollAndRedirect('/')}
          className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
        >
          {t('MSG_105')}
        </div>
        <div
          onClick={() => handleScrollAndRedirect('/gallery')}
          className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
        >
          {t('MSG_486')}
        </div>
        <div
          onClick={() => handleScrollAndRedirect('/', 'solutions')}
          className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
        >
          {t('MSG_484')}
        </div>
        {token ? (
          <>
            <div
              onClick={() => handleScrollAndRedirect('/user/modify-data')}
              className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
            >
              {t('MSG_444')}
            </div>
            <div
              onClick={() => handleScrollAndRedirect('/user')}
              className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
            >
              {t('MSG_163')}
            </div>
            <div
              onClick={() => handleScrollAndRedirect('/user/plans')}
              className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
            >
              {t('MSG_107')}
            </div>
            <div
              onClick={handleUserLogout}
              className='block cursor-pointer px-4 py-2 text-sm text-main hover:bg-gray-700'
            >
              {t('MSG_99')}
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => handleScrollAndRedirect('/plans')}
              className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
            >
              {t('MSG_362')}
            </div>
            <div
              onClick={() => handleScrollAndRedirect('/register')}
              className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-700'
            >
              {t('MSG_108')}
            </div>
            <div
              onClick={() => handleScrollAndRedirect('/login')}
              className='block cursor-pointer px-4 py-2 text-sm text-main hover:bg-gray-700'
            >
              {t('MSG_58')}
            </div>
          </>
        )}
      </div>
    </nav>
  )
}
