import { useWebsocketContext } from '@components/Context'
import Footer from '@components/Footer'
import ImageGridHome from '@components/ImageGridHome'
import Navbar from '@components/Navbar'
import PremiumPlans from '@components/plans/PremiumPlans'
import PricingPlans from '@components/plans/PricingPlan'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function InspirationInfo() {
  const { creditosActuales } = useWebsocketContext()

  return (
    <>
      <div className='bg-black'>
        <ScrollProgressBar />
        <Navbar
          imgRedirectHome={true}
          creditosActuales={creditosActuales}
        />
        <div className='h-20 w-full bg-black'></div>
        <ImageGridHome />
        <PricingPlans darkMode={true} />
        <PremiumPlans />
      </div>
      <Footer />
    </>
  )
}
