import { useEffect, useState } from 'react'
import t from '../../store/languageActions'
import { motion } from 'framer-motion'
import d37 from '@assets/Personalization/animation/1.png'
import d38 from '@assets/Personalization/animation/2.png'
import d39 from '@assets/Personalization/animation/3.png'
import d40 from '@assets/Personalization/animation/4.png'

export const imageGroup = [d37, d38, d39, d40]

export default function AnimatedImagesPersonalization() {
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % imageGroup.length)
    }, 4000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div className='w-full space-y-4 bg-black p-2 text-left text-white sm:top-6 sm:p-6'>
        <p className='px-1 font-creato-bold text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_522')}
        </p>
      </div>

      <div className='-mt-4 mb-6 w-full space-y-4 bg-black p-2 text-left text-white sm:top-6 sm:p-6'>
        <p className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_521')}
        </p>
      </div>
      <div className='relative m-auto flex h-[50vh] w-[90vw] items-center justify-center bg-black sm:h-[55vh] sm:w-[85vw] md:h-[60vh] md:w-[75vw] lg:h-[60vh] lg:w-[50vh] xl:h-[70vh] xl:w-[80vh]'>
        {imageGroup.map((image, index) => (
          <motion.div
            key={index}
            className='absolute flex h-full w-full items-center justify-center'
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentImage ? 1 : 0 }}
            transition={{ duration: 1.5 }}
          >
            <img
              src={image}
              alt={`animated-photo-${index}`}
              className='h-full w-full object-cover'
            />
          </motion.div>
        ))}
      </div>
    </>
  )
}
