import { useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import { useWebsocketContext } from './Context'
import SectionModifyData from './SectionModifyData'
import { ScrollProgressBar } from './ScrollProgressBar'

export default function PageModifyData() {
  const isUserLogged = sessionStorage.getItem('userToken')
  const { creditosActuales } = useWebsocketContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const redirect = useNavigate()
  useLayoutEffect(() => {
    if (!isUserLogged) redirect('/')
  }, [isUserLogged])
  return (
    <div className='font-creato-medium'>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome
        creditosActuales={creditosActuales}
      />

      <SectionModifyData />

      <Footer />
    </div>
  )
}
