import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import t from '../../store/languageActions'
import image1 from '@assets/Personalization/comparing/1.png'
import image2 from '@assets/Personalization/comparing/2.png'

import CustomHandle from '@components/CustonHandle'

const ComparingPersonalization: React.FC = () => {
  return (
    <>
      <div className='mt-4 w-full space-y-4 bg-black p-2 text-left text-white sm:top-6 sm:p-6'>
        <p className='px-1 font-creato-bold text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_524')}
        </p>
      </div>

      <div className='mb-6 w-full space-y-4 bg-black p-2 text-left text-white sm:top-6 sm:p-6'>
        <p className='-mt-4 px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_523')}
        </p>
      </div>
      <div className='relative mt-4 w-full overflow-hidden'>
        <div className='relative w-full'>
          <div
            className='relative'
            style={{ overflow: 'hidden', width: '80%', margin: '0 auto', maxWidth: '1300px' }}
          >
            <ReactCompareSlider
              itemOne={
                <ReactCompareSliderImage
                  src={image1}
                  alt='stay image before generate IA'
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={image2}
                  alt='stay image after generate IA'
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              handle={<CustomHandle />}
            />
          </div>
        </div>
      </div>
      <div className='mb-2 mt-4 w-full space-y-4 bg-black text-left text-white sm:top-6 sm:p-6'>
        <p className='px-1 font-creato-bold text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_526')}
        </p>
      </div>

      <div className='-mt-4 mb-6 w-full space-y-4 bg-black p-2 text-left text-white sm:top-6 sm:p-6'>
        <p className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_525')}
        </p>
      </div>
    </>
  )
}

export default ComparingPersonalization
