import { useEffect, useRef, useState } from 'react'
import t from '../store/languageActions'

interface BannerImageHeaderProps {
  img: string[]
}

export default function BannerImageHeader({ img }: BannerImageHeaderProps) {
  const [currImageIndex, setCurrImageIndex] = useState(0)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [prevImageIndex, setPrevImageIndex] = useState<number | null>(null)
  const increment = useRef(0)

  useEffect(() => {
    const timeout = setInterval(() => {
      const nextImageIndex = (currImageIndex + 1) % img.length
      const imgElement = new Image()
      imgElement.src = `${img[nextImageIndex]}?w=1200`

      imgElement.onload = () => {
        setPrevImageIndex(currImageIndex)
        setCurrImageIndex(nextImageIndex)
        setIsImageLoaded(true)
      }

      increment.current += 1
    }, 2500)

    return () => {
      clearInterval(timeout)
    }
  }, [currImageIndex, img])

  const currentImageUrl = `${img[currImageIndex]}?w=1200`
  const previousImageUrl = prevImageIndex !== null ? `${img[prevImageIndex]}?w=1200` : null

  return (
    <div className='relative h-screen w-full'>
      {previousImageUrl && (
        <div
          className={`absolute left-0 top-0 h-full w-full bg-cover bg-center transition-opacity duration-1000 ${
            isImageLoaded ? 'opacity-0' : 'opacity-100'
          }`}
          style={{
            backgroundImage: `url('${previousImageUrl}')`,
          }}
        />
      )}
      <div
        className={`absolute left-0 top-0 h-full w-full bg-cover bg-center transition-opacity duration-1000 ${
          isImageLoaded ? 'opacity-100' : 'opacity-0'
        }`}
        style={{
          backgroundImage: `url('${currentImageUrl}')`,
        }}
      />
      <div className='relative flex h-screen w-full items-center justify-start'>
        <div className='flex h-full items-center bg-black bg-opacity-40 pr-4 md:pr-36'>
          <div className='pl-4 text-left md:pl-10'>
            <div className='font-serif text-4xl font-bold leading-snug tracking-tight text-gray-200 md:text-6xl lg:text-7xl xl:text-8xl'>
              <div className='mb-4'>
                <span className='block font-montserrat-bold text-main'>{t('MSG_101')}</span>
              </div>
              <div className='mb-4'>
                <span className='block font-chopin font-thin'>{t('MSG_60')}</span>
              </div>
              <div>
                <span className='block font-chopin font-thin'>{t('MSG_61')}</span>
              </div>
              <div>
                <span className='block font-chopin font-thin'>
                  {t('MSG_537')}
                  <span className='ml-5 font-creato-bold font-thin tracking-wide'>
                    {t('MSG_134')}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <picture>
        <source
          media='(max-width: 600px)'
          srcSet={`${currentImageUrl}?w=600`}
        />
        <source
          media='(max-width: 1200px)'
          srcSet={`${currentImageUrl}?w=1200`}
        />
        <img
          src={currentImageUrl}
          alt={`Imagenes in the banner showing a description of ${t('MSG_101')}`}
          style={{ display: 'none' }}
          onLoad={() => setIsImageLoaded(true)}
        />
      </picture>
    </div>
  )
}
