import { useNavigate } from 'react-router-dom'
import t from '../store/languageActions'
import Decoracion from '@assets/Decoracion.jpg'
import Inspiration from '@assets/Inspiracion.jpg'
import Redecoracion from '@assets/ReDecoracion.jpg'
import Renderizado from '@assets/RenderingPageInfo/renderizado.jpg'
import Exteriores from '@assets/Exteriores/exteriores.jpg'
import Planner from '@assets/Planner/planificación.jpg'
import Personalization from '@assets/personalization.png'
import CarouselSection from './CarouselSection'
import { YouTubeIcon } from './YouTubeIcon'

export default function CardSection({ img }: { img: string[] }) {
  const redirect = useNavigate()
  function handleButtonClick(path: string) {
    redirect(path)
    window.scrollTo(0, 0)
  }
  return (
    <div
      className='bg-white py-16'
      id='solutions'
    >
      <div className='mx-1 sm:mx-2 md:mx-12 lg:mx-10 xl:mx-20 2xl:mx-[15%]'>
        {/* Primera fila: 4 cards */}
        <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
          {/* Card 1 */}
          <div className='flex flex-col rounded-lg bg-white shadow-2xl'>
            <div className='h-80 w-full sm:h-72 lg:h-96'>
              <img
                src={Inspiration}
                className='h-full w-full rounded-t-lg object-cover'
                alt='AI-generated inspiration proposal image'
                loading='lazy'
              />
            </div>
            <div className='flex flex-grow flex-col justify-between p-4'>
              <div>
                <h3 className='mb-4 font-montserrat-bold text-xl font-bold text-main'>
                  {t('MSG_67')}
                </h3>
                <p className='mb-6 text-start text-gray-700'>{t('MSG_29')}</p>
              </div>
              <div className='flex items-center justify-center space-x-3'>
                <button
                  className='w-36 transform rounded-md bg-main px-4 py-2.5 text-white transition-transform duration-200 ease-in-out hover:scale-105'
                  onClick={() => handleButtonClick('/inspiration/info')}
                >
                  {t('MSG_69')}
                </button>
                <YouTubeIcon navigate='https://youtube.com/shorts/cklDzlZ8vGM?si=PCrBxSfCYaWIT28z' />
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className='flex flex-col rounded-lg bg-white shadow-2xl'>
            <div className='h-80 w-full sm:h-72 lg:h-96'>
              <img
                src={Decoracion}
                className='h-full w-full rounded-t-lg object-cover'
                alt='AI generated Decoration proposal image'
                loading='lazy'
              />
            </div>
            <div className='flex flex-grow flex-col justify-between p-4'>
              <div>
                <h3 className='mb-4 font-montserrat-bold text-xl font-bold text-main'>
                  {t('MSG_70')}
                </h3>
                <p className='mb-6 text-start text-gray-700'>{t('MSG_31')}</p>
              </div>
              <div className='flex items-center justify-center space-x-3'>
                <button
                  className='w-36 transform rounded-md bg-main px-4 py-2.5 text-white transition-transform duration-200 ease-in-out hover:scale-105'
                  onClick={() => handleButtonClick('/decoration/info')}
                >
                  {t('MSG_69')}
                </button>
                <YouTubeIcon navigate='https://youtube.com/shorts/oZ3EJE0QRf8?si=eAsZLLEJ8rWawMeE' />
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className='flex flex-col rounded-lg bg-white shadow-2xl'>
            <div className='h-80 w-full sm:h-72 lg:h-96'>
              <img
                src={Redecoracion}
                className='h-full w-full rounded-t-lg object-cover'
                alt='AI generated Redecoration proposal image'
                loading='lazy'
              />
            </div>
            <div className='flex flex-grow flex-col justify-between p-4'>
              <div>
                <h3 className='mb-4 font-montserrat-bold text-xl font-bold text-main'>
                  {t('MSG_68')}
                </h3>
                <p className='mb-6 text-start text-gray-700'>{t('MSG_33')}</p>
              </div>
              <div className='flex items-center justify-center space-x-3'>
                <button
                  className='w-36 transform rounded-md bg-main px-4 py-2.5 text-white transition-transform duration-200 ease-in-out hover:scale-105'
                  onClick={() => handleButtonClick('/redecoration/info')}
                >
                  {t('MSG_69')}
                </button>
                <YouTubeIcon navigate='https://youtube.com/shorts/j1zuwhZO16M?si=FwIvThMvPR6QQJWQ' />
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className='flex flex-col rounded-lg bg-white shadow-2xl'>
            <div className='h-80 w-full sm:h-72 lg:h-96'>
              <img
                src={Renderizado}
                className='h-full w-full rounded-t-lg object-cover'
                alt='Proposal image of an AI-generated rendering from a drawing'
                loading='lazy'
              />
            </div>
            <div className='flex flex-grow flex-col justify-between p-4'>
              <div>
                <h3 className='mb-4 font-montserrat-bold text-xl font-bold text-main'>
                  {t('MSG_456')}
                </h3>
                <p className='mb-6 text-start text-gray-700'>{t('MSG_463')}</p>
              </div>
              <div className='flex items-center justify-center space-x-3'>
                <button
                  className='w-36 transform rounded-md bg-main px-4 py-2.5 text-white transition-transform duration-200 ease-in-out hover:scale-105'
                  onClick={() => handleButtonClick('/rendering/info')}
                >
                  {t('MSG_69')}
                </button>
                <YouTubeIcon navigate='https://youtube.com/shorts/rgVDa70gwpI?si=iqgKAZl6jYpls-Pg' />
              </div>
            </div>
          </div>
        </div>
        {/* Segunda fila: 3 cards centradas */}
        <div className='mb-10 flex justify-center'>
          <div className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:w-3/4 lg:grid-cols-3'>
            {/* Card 5 */}
            <div className='flex flex-col rounded-lg bg-white shadow-2xl'>
              <div className='h-80 w-full sm:h-72 lg:h-96'>
                <img
                  src={Exteriores}
                  className='h-full w-full rounded-t-lg object-cover'
                  alt='Modern facade on the exterior of a house'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-grow flex-col justify-between p-4'>
                <div>
                  <h3 className='mb-4 font-montserrat-bold text-xl font-bold text-main'>
                    {t('MSG_467')}
                  </h3>
                  <p className='mb-6 text-start text-gray-700'>{t('MSG_470')}</p>
                </div>
                <div className='flex items-center justify-center space-x-3'>
                  <button
                    className='w-36 transform rounded-md bg-main px-4 py-2.5 text-white transition-transform duration-200 ease-in-out hover:scale-105'
                    onClick={() => handleButtonClick('/exteriors/info')}
                  >
                    {t('MSG_69')}
                  </button>
                  <YouTubeIcon navigate='https://youtube.com/shorts/Ak8io867EOk?si=dfBz3S240aWdGvlR' />
                </div>
              </div>
            </div>

            {/* Card 6 */}
            <div className='flex flex-col rounded-lg bg-white shadow-2xl'>
              <div className='h-80 w-full sm:h-72 lg:h-96'>
                <img
                  src={Planner}
                  className='h-full w-full rounded-t-lg object-cover'
                  alt='Exteriors'
                />
              </div>
              <div className='flex flex-grow flex-col justify-between p-4'>
                <div>
                  <h3 className='mb-4 font-montserrat-bold text-xl font-bold text-main'>
                    {t('MSG_495')}
                  </h3>
                  <p className='mb-6 text-start text-gray-700'>{t('MSG_508')}</p>
                </div>
                <div className='flex items-center justify-center'>
                  <button
                    className='w-36 transform rounded-md bg-main px-4 py-2.5 text-white transition-transform duration-200 ease-in-out hover:scale-105'
                    onClick={() => handleButtonClick('/planner/info')}
                  >
                    {t('MSG_69')}
                  </button>
                </div>
              </div>
            </div>

            {/* Card 7 */}
            <div className='flex flex-col rounded-lg bg-white shadow-2xl'>
              <div className='h-80 w-full sm:h-72 lg:h-96'>
                <img
                  src={Personalization}
                  className='h-full w-full rounded-t-lg object-cover'
                  alt='Modern facade on the exterior of a house'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-grow flex-col justify-between p-4'>
                <div>
                  <h3 className='mb-4 font-montserrat-bold text-xl font-bold text-main'>
                    {t('MSG_514')}
                  </h3>
                  <p className='mb-6 text-start text-gray-700'>{t('MSG_528')}</p>
                </div>
                <div className='flex items-center justify-center space-x-3'>
                  <button
                    className='w-36 transform rounded-md bg-main px-4 py-2.5 text-white transition-transform duration-200 ease-in-out hover:scale-105'
                    onClick={() => handleButtonClick('/personalization/info')}
                  >
                    {t('MSG_69')}
                  </button>
                  <YouTubeIcon navigate='https://youtube.com/shorts/NElPS3VwXMQ?si=YluvFA9Knf5rOJPu' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CarouselSection img={img} />
    </div>
  )
}
