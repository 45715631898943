import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import image1 from '@assets/RenderingPageInfo/izquierdaBath.jpg'
import image2 from '@assets/RenderingPageInfo/derechaBath.jpg'
import image3 from '@assets/RenderingPageInfo/izquierdaComedor.jpg'
import image4 from '@assets/RenderingPageInfo/derechaComedor.jpg'
import image5 from '@assets/RenderingPageInfo/izquierdaDormi.jpg'
import image6 from '@assets/RenderingPageInfo/derechaDormi.jpg'
import image7 from '@assets/RenderingPageInfo/izquierdaSalon.jpg'
import image8 from '@assets/RenderingPageInfo/derechaSalon.jpg'
import CustomHandle from '@components/CustonHandle'

const ComparingRenderingInfo: React.FC = () => {
  return (
    <div className='relative mt-4 w-full overflow-hidden'>
      {/* Primer Comparador */}
      <div className='relative w-full'>
        <div
          className='relative'
          style={{ overflow: 'hidden', width: '80%', margin: '0 auto', maxWidth: '990px' }}
        >
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={image1}
                alt='stay image before generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={image2}
                alt='stay image after generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            handle={<CustomHandle />}
          />
        </div>
      </div>

      {/* Segundo Comparador */}
      <div className='relative mt-4 w-full'>
        <div
          className='relative'
          style={{ overflow: 'hidden', width: '80%', margin: '0 auto', maxWidth: '990px' }}
        >
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={image3}
                alt='stay image before generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={image4}
                alt='stay image after generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            handle={<CustomHandle />}
          />
        </div>
      </div>
      {/* Tercero Comparador */}
      <div className='relative mt-4 w-full'>
        <div
          className='relative'
          style={{ overflow: 'hidden', width: '80%', margin: '0 auto', maxWidth: '990px' }}
        >
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={image5}
                alt='stay image before generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={image6}
                alt='stay image after generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            handle={<CustomHandle />}
          />
        </div>
      </div>

      {/* Cuarto Comparador */}
      <div className='relative mt-4 w-full'>
        <div
          className='relative'
          style={{ overflow: 'hidden', width: '80%', margin: '0 auto', maxWidth: '990px' }}
        >
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={image7}
                alt='stay image before generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={image8}
                alt='stay image after generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            handle={<CustomHandle />}
          />
        </div>
      </div>
    </div>
  )
}

export default ComparingRenderingInfo
