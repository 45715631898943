import { ReactCompareSliderHandle } from 'react-compare-slider'
const CustomHandle = () => (
  <ReactCompareSliderHandle
    buttonStyle={{
      backdropFilter: undefined,
      WebkitBackdropFilter: undefined,
      backgroundColor: 'white',
      color: 'rgb(184,134,38)',
      border: 1,
      marginLeft: '-15px',
      marginRight: '-15px',
    }}
    style={{
      backgroundColor: 'transparent',
      width: '5px',
      height: '100%',
      zIndex: 1000,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20px',
        height: '20px',
        backgroundColor: 'white',
        borderRadius: '50%',
        boxShadow: '0 0 10px rgba(184,134,38,0.7)',
      }}
    ></div>
  </ReactCompareSliderHandle>
)
export default CustomHandle
