import getQuestions from '@proxies/ia/getQuestions'
import { useLayoutEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'

import estrella from '@assets/estrella.png'
import { store } from '../../store'
import { useWebsocketContext } from '@components/Context'
import Navbar from '@components/Navbar'
import ImageOptions from '@components/ImageOptions'
import t from '../../store/languageActions'
import CardGrid from '@components/CardGrid'
import Footer from '@components/Footer'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export type Estancias = {
  imagenUrl1: string
  texto: string
  idGrupoPreguntasIa: number
  acceso: boolean
}[]

export default function InspirationPage() {
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const navigate = useNavigate()
  const [selectedCard, setSelectedCard] = useState<number | null>(null)
  const estancias = useLoaderData() as Estancias
  const { creditosActuales } = useWebsocketContext()

  useLayoutEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])

  async function handleCardClick(index: number, id: number) {
    if (!estancias?.[index]?.acceso) return
    setSelectedCard(index)
    const nextPageInfo = await getQuestions(() => {}, id)
    const selectedEstancia = estancias[index]
    navigate('/inspiration/styles', {
      state: { respuestas: nextPageInfo, texto: selectedEstancia?.texto, concatIds: [id] },
    })
    window.scrollTo(0, 0)
  }

  function handleButtonClick() {
    const concatenatedSelections = ''
    const texto = ''
    navigate('/inspiration/styles2/generate', {
      state: {
        concatenatedSelections,
        texto,
        concatIds: [selectedCard],
        triggerGenerate: true,
      },
    })
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageOptions
        title={t('MSG_67')}
        subtitle={t('MSG_109')}
        buttonText={t('MSG_37')}
        imgSrc='https://api.for2inspiration.ai/Images/MotorIA/nLjjDu/f4ca0cd7-1ca6-46d4-9082-3dacd1792a59-0.png'
        onButtonClick={handleButtonClick}
      />
      <h1 className='my-8 text-center text-2xl font-bold text-main'>{t('MSG_111')}</h1>
      <p className='-mt-6 mb-4 font-light'> {t('MSG_139')}</p>
      <div className='container mx-auto px-4'>
        <div className='-mx-2 flex flex-wrap'>
          {estancias &&
            estancias
              .filter((estancia) => estancia.idGrupoPreguntasIa !== 214)
              .map((estancia, index) => (
                <div
                  key={index}
                  className='w-full p-2 sm:w-1/2 md:w-1/3 lg:w-1/4'
                >
                  <CardGrid
                    imageUrl={estancia.imagenUrl1}
                    text={estancia.texto}
                    isSelected={selectedCard === index}
                    onClick={async () => handleCardClick(index, estancia.idGrupoPreguntasIa)}
                    acceso={estancia.acceso}
                  />
                </div>
              ))}
        </div>
      </div>
      <div className='container mx-auto flex flex-1 justify-end py-4'>
        <button
          className='flex w-[280px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800'
          onClick={handleButtonClick}
        >
          {t('MSG_37')}
          <div className='ml-4 flex h-6 w-10 items-center rounded-lg border-[1.5px] border-white bg-main'>
            <img
              src={estrella}
              alt='Star image'
              className='ml-1 h-4 w-4'
            />
            <p className='ml-1 text-white'>1</p>
          </div>
        </button>
      </div>
      <Footer />
    </div>
  )
}
