import ComparingRenderingInfo from '@components/redecoration/ComparingRenderingInfo'
import { useWebsocketContext } from '../Context'
import Footer from '../Footer'
import Navbar from '../Navbar'
import CarouselRendering from './CarouselRendering'
import PortadaInfoRendering from './PortadaInfoRendering'
import PricingPlans from '@components/plans/PricingPlan'
import PremiumPlans from '@components/plans/PremiumPlans'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function RenderingInfo() {
  const { creditosActuales } = useWebsocketContext()

  return (
    <>
      <div className='bg-black'>
        <ScrollProgressBar />
        <Navbar
          imgRedirectHome={true}
          creditosActuales={creditosActuales}
        />
        <div className='h-14 w-full bg-black'></div>
        <PortadaInfoRendering />
        <CarouselRendering />
        <ComparingRenderingInfo />
        <PricingPlans darkMode={true} />
        <PremiumPlans />
      </div>
      <Footer />
    </>
  )
}
