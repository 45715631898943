import { useEffect, useRef } from 'react'

interface CarouselSectionProps {
  img: string[]
}

export default function CarouselSection({ img }: CarouselSectionProps) {
  const imgRef = useRef<(HTMLImageElement | null)[]>([])

  const isValidImageUrl = (url: string) => {
    return url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const image = entry.target as HTMLImageElement
            image.src = image.dataset.src || ''
            observer.unobserve(image)
          }
        })
      },
      { threshold: 0.1 },
    )

    imgRef.current.forEach((img) => {
      if (img) observer.observe(img)
    })

    return () => observer.disconnect()
  }, [])

  return (
    <div className='relative mt-6 w-full overflow-hidden'>
      <div className='flex animate-slide-left-infinite'>
        {img.filter(isValidImageUrl).map((el, index) => {
          const encodedUrl = encodeURI(el)

          const srcSet = `
              ${encodedUrl}?w=400 400w,
              ${encodedUrl}?w=800 800w,
              ${encodedUrl}?w=1200 1200w
            `

          return (
            <div
              key={index}
              className='h-96 w-[700px] flex-shrink-0 px-1 md:px-1.5'
            >
              <img
                ref={(element) => (imgRef.current[index] = element)}
                data-src={encodedUrl}
                className='h-full w-full object-cover'
                alt={`Carousel image ${index}`}
                srcSet={srcSet}
                sizes='(max-width: 600px) 400px, (max-width: 1200px) 800px, 1200px'
                loading='lazy'
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
