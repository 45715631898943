import { useState } from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import ShareModal from './ShareModal'
import DeleteModal from './DeleteModal'
import { getBaseHeaders, getBaseUrl } from '@proxies/index'
import compararOn from '@assets/Comparar ON.png'
import compararOff from '@assets/Comparar Off.png'
import borrar from '@assets/borrar.png'
import compartir from '@assets/compartir.png'
import favorito from '@assets/favorito.png'
import favoritoRojo from '@assets/favoritorojo.png'
import descargas from '@assets/descargas.png'
import deleteImage from '@proxies/ia/deleteImage'
import toggleFavourite from '@proxies/user/toggleFavourite'
import t from '../store/languageActions'
import CustomHandle from './CustonHandle'
type ProjectCardProps = {
  title: string
  date: string
  description: string
  imgSrc: string
  favourite: boolean
  index: number
  id: number
  onDelete: (id: number) => void
  tipo: number
  textoDelFront: string
  inputImagenUrl: string | null
}
function getTipoTrabajo(tipo: number): string {
  switch (tipo) {
    case 0:
      return `${t('MSG_159')} ${t('MSG_161')}`
    case 1:
      return `${t('MSG_159')} ${t('MSG_28')}`
    case 2:
      return `${t('MSG_159')} ${t('MSG_30')}`
    case 3:
      return `${t('MSG_159')} ${t('MSG_32')}`
    case 4:
      return `${t('MSG_159')} ${t('MSG_455')}`
    case 5:
      return `${t('MSG_159')} ${t('MSG_475')}`
    case 7:
      return `${t('MSG_159')} ${t('MSG_515')}`
    default:
      return `${t('MSG_159')} ${t('MSG_494')}`
  }
}
export default function ProjectCard({
  title,
  date,
  description,
  imgSrc,
  favourite,
  index,
  id,
  onDelete,
  tipo,
  textoDelFront,
  inputImagenUrl,
}: ProjectCardProps) {
  const [isComparing, setIsComparing] = useState(false)
  const [isFavorited, setIsFavorited] = useState(favourite)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  function toggleComparar() {
    setIsComparing(!isComparing)
  }
  function toggleFavorito() {
    setIsFavorited(!isFavorited)
    toggleFavourite(id)
  }
  const downloadImage = async (idTrabUrlia: number, imageName: string = 'descarga') => {
    try {
      const urlDownload = getBaseUrl(`api/TrabajosIA/downloadimage/${idTrabUrlia}`)
      const response = await fetch(urlDownload, getBaseHeaders())
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} - ${response.statusText}`)
        const errorText = await response.text()
        console.error(`HTTP Error: ${response.status} - ${errorText}`)
        throw new Error('Network response was not ok')
      }
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${imageName}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading the image: ', error)
    }
  }
  const handleDelete = async () => {
    try {
      await deleteImage(id)
      console.log(`Image with id ${id} deleted successfully.`)
      onDelete(id)
      setIsDeleteModalOpen(false)
    } catch (error) {
      console.error('Error deleting the image: ', error)
    }
  }
  const formattedDate = new Date(date).toLocaleDateString('es-ES', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })
  const formattedTime = new Date(date).toLocaleTimeString('es-ES', {
    hour: '2-digit',
    minute: '2-digit',
  })
  return (
    <div className='overflow-hidden bg-white shadow-lg'>
      <div className='flex w-full items-center justify-center bg-white sm:h-[300px] md:h-[350px] lg:h-[410px]'>
        {!isComparing ? (
          <a
            href={imgSrc}
            download
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='max-h-[400px] max-w-full object-contain hover:cursor-pointer'
              src={imgSrc}
              alt={title}
              loading='lazy'
              draggable={false}
            />
          </a>
        ) : (
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={inputImagenUrl || imgSrc}
                alt={title}
                className='max-h-[400px] min-h-[400px] max-w-full object-contain'
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={imgSrc}
                alt={title}
                className='max-h-[400px] min-h-[400px] max-w-full object-contain'
              />
            }
            handle={<CustomHandle />}
          />
        )}
      </div>
      <div className='mt-4 h-auto px-4 text-start sm:mt-8 md:mt-2 lg:mt-0'>
        <h2 className='truncate text-xl font-bold'>
          {title && title.trim() !== '' ? (
            <span className='block sm:line-clamp-2 md:line-clamp-3 lg:line-clamp-5'>{title}</span>
          ) : (
            <>&nbsp;</>
          )}
        </h2>
        <p>
          {t('MSG_95')} {formattedDate || ' '} {formattedTime || ' '}
        </p>
        <p className='whitespace-normal text-sm'>{getTipoTrabajo(tipo) || ' '}</p>

        <div className='flex h-full flex-col'>
          {tipo === 7 ? (
            <>
              <p className='overflow-hidden text-ellipsis whitespace-normal text-sm'>
                {description && description.trim() !== '' ? (
                  <span className='block sm:line-clamp-2 md:line-clamp-3 lg:line-clamp-5'>
                    {description}
                  </span>
                ) : (
                  <>&nbsp;</>
                )}
              </p>
              <p className='truncate whitespace-nowrap text-sm'>
                {textoDelFront && textoDelFront.trim() !== '' ? (
                  <span className='block sm:line-clamp-2 md:line-clamp-3 lg:line-clamp-5'>
                    {textoDelFront}
                  </span>
                ) : (
                  <>&nbsp;</>
                )}
              </p>
            </>
          ) : (
            <>
              <p className='truncate whitespace-nowrap text-sm'>
                {textoDelFront && textoDelFront.trim() !== '' ? (
                  <span className='block sm:line-clamp-2 md:line-clamp-3 lg:line-clamp-5'>
                    {textoDelFront}
                  </span>
                ) : (
                  <>&nbsp;</>
                )}
              </p>
              <p className='overflow-hidden text-ellipsis whitespace-normal text-sm'>
                {description && description.trim() !== '' ? (
                  <span className='block sm:line-clamp-2 md:line-clamp-3 lg:line-clamp-5'>
                    {description}
                  </span>
                ) : (
                  <>&nbsp;</>
                )}
              </p>
            </>
          )}
        </div>
      </div>
      <div className='flex items-center justify-between px-4 pb-4'>
        <div className='mt-2 flex space-x-4'>
          <button
            className='text-black hover:text-gray-600'
            onClick={toggleFavorito}
          >
            <img
              src={isFavorited ? favoritoRojo : favorito}
              alt='Heart image for favorites'
              className='h-5 w-6'
            />
          </button>
          <button
            className='hover text-black'
            onClick={() => downloadImage(id, `image_descarga${index}`)}
          >
            <img
              src={descargas}
              alt='Download'
              className='h-6 w-6'
            />
          </button>
          <button
            className='text-black hover:text-gray-600'
            onClick={() => setIsShareModalOpen(true)}
          >
            <img
              src={compartir}
              alt='Share'
              className='h-5 w-5'
            />
          </button>
          <button
            className='text-black hover:text-gray-600'
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <img
              src={borrar}
              alt='Delete'
              className='h-6 w-6'
            />
          </button>
        </div>

        {(tipo === 2 ||
          tipo === 3 ||
          tipo === 4 ||
          tipo === 5 ||
          (tipo === 7 && inputImagenUrl)) && (
          <div className='flex items-center'>
            <p className='mr-1 text-xs'>{t('MSG_94')}</p>
            <button
              onClick={toggleComparar}
              className='focus:outline-none'
            >
              <img
                src={isComparing ? compararOn : compararOff}
                alt='Comparing button'
                className='h-5 w-12'
              />
            </button>
          </div>
        )}
      </div>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onRequestClose={() => setIsShareModalOpen(false)}
        imgSrc={imgSrc}
      />
    </div>
  )
}
