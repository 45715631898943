import Navbar from '@components/Navbar'
import PricingPlans from '@components/plans/PricingPlan'
import PremiumPlans from '@components/plans/PremiumPlans'
import Footer from '@components/Footer'
import { useWebsocketContext } from '@components/Context'
import PortadaInfoDecoration from './PortadaInfoDecoration'
import CarouselDecorationPage from './CarouselDecorationPage'
import CarouselDecoration2Page from './CarouselDecoration2Page'
import ComparingDecorationInfo from './ComparingDecorationInfo'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function DecorationInfo() {
  const { creditosActuales } = useWebsocketContext()

  return (
    <>
      <div className='bg-black'>
        <ScrollProgressBar />
        <Navbar
          imgRedirectHome={true}
          creditosActuales={creditosActuales}
        />
        <div className='h-14 w-full bg-black'></div>
        <PortadaInfoDecoration />
        <CarouselDecorationPage />
        <ComparingDecorationInfo />
        <CarouselDecoration2Page />
        <PricingPlans darkMode={true} />
        <PremiumPlans />
      </div>
      <Footer />
    </>
  )
}
