import register, { mockBody } from '@proxies/user/register'
export default async function handleRegister(e: React.FormEvent<HTMLFormElement>) {
  if (!('elements' in e.target)) return
  const elements = e.target.elements as Record<string, HTMLInputElement>
  if (!('email' in elements) || !('password' in elements) || !('name' in elements)) return
  const { email, password, name } = elements
  if (!email || !password) return
  try {
    await register({
      ...mockBody,
      correo: email.value,
      nombre: name.value,
      password: password.value,
      passwordConfirmar: password.value,
    })
  } catch (e: unknown) {
    console.error('Error on register', e)
    return false
  }
  return true
}
