import { getBaseHeaders, getBaseUrl } from '..'

export type HistoryReturn = {
  descripcionProyecto: string | null
  favorito: boolean | null
  fechaSolicitud: string
  idTrabUrlia: number
  imagenUrl: string
  inputImagenUrl: string | null
  tipoTrabajo: number
  tituloProyecto: string | null
  textoDelFront?: string | null | undefined
}

export default async function getUserHistory(
  favState: boolean = false,
  search: string,
  signal?: AbortSignal,
) {
  const info = await fetch(getBaseUrl('api/TrabajosIA/consultaHist'), {
    ...getBaseHeaders({
      method: 'post',
      body: JSON.stringify({
        favorito: favState,
        descripcion: search,
      }),
      headers: { 'Content-Type': 'application/json' },
    }),
    signal,
  })

  const data = (await info.json()) as HistoryReturn[]
  return data
}
