import { useNavigate } from 'react-router-dom'
import ConstructionIcon from '../assets/coming soon.png'
import { useWebsocketContext } from './Context'
import IncidentModal from './IncidentModal'
import { useState } from 'react'
import { store } from '../store'

type CardProps = {
  title: string
  description: string
  buttonText: string
  imgSrc: string
  navigateTo?: string | (() => void)
  isUnderConstruction?: boolean
  requiresSpecialPlan?: boolean
}
export default function CardCreative({
  title,
  description,
  buttonText,
  imgSrc,
  navigateTo,
  isUnderConstruction = false,
  requiresSpecialPlan = false,
}: CardProps) {
  const navigate = useNavigate()
  const { creditosActuales, tipoPlan } = useWebsocketContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  function closeModal() {
    setIsModalOpen(false)
  }
  function handleNavigate() {
    if (requiresSpecialPlan) {
      if (tipoPlan !== 4 && tipoPlan !== 5) {
        setIsModalOpen(true)
        setModalMessage(t('MSG_513'))
        return
      }
    }
    if (creditosActuales === 0) {
      setIsModalOpen(true)
      setModalMessage(t('MSG_464'))
    } else if (navigateTo) {
      window.scrollTo(0, 0)
      if (typeof navigateTo === 'function') {
        navigateTo()
      } else {
        navigate(navigateTo)
      }
    }
  }

  return (
    <>
      <div className='relative mx-auto mb-4 flex w-full max-w-xs flex-col overflow-hidden border-2 border-yellow-600 bg-white shadow-xl'>
        {isUnderConstruction && (
          <div className='absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50'>
            <div className='relative flex items-center justify-center'>
              <img
                src={ConstructionIcon}
                alt='coming soon'
                className='h-64'
              />
            </div>
          </div>
        )}
        <img
          src={imgSrc}
          alt={title}
          className='h-72 w-full object-cover'
        />
        <div className='flex h-64 flex-grow flex-col p-4'>
          <h2 className='mb-2 text-center font-montserrat-bold text-xl text-main'>{title}</h2>
          <p className='mb-3 max-h-32 flex-grow overflow-hidden text-ellipsis text-left font-creato-light text-[17.4px] text-gray-700'>
            {description}
          </p>
          <div className='mt-2 text-center'>
            <button
              className='pointer-events-auto w-full transform rounded-md bg-black py-2 text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
              onClick={handleNavigate}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
      <IncidentModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        message={modalMessage}
        onCloseNavigate={closeModal}
      />
    </>
  )
}
