import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../store'

export function FreeTrialModal() {
  const [isVisible, setIsVisible] = useState(true)
  const navigate = useNavigate()
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  function handleClose() {
    setIsVisible(false)
  }

  function redirectToRegister() {
    navigate('/register')
  }

  if (!isVisible) return null

  return (
    <div className='pointer-events-none fixed z-20 flex h-full w-full items-center justify-center'>
      <div className='pointer-events-auto relative h-auto w-11/12 bg-black bg-opacity-80 p-8 text-center text-white shadow-lg md:w-[600px] lg:-mr-[40%] lg:w-[600px]'>
        <div
          className='absolute inset-0 animate-border-snake bg-gradient-to-r from-yellow-700 via-gray-400 to-gray-700 p-0.5'
          style={{
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            WebkitMaskComposite: 'xor',
            backgroundSize: '200% 200%',
          }}
        ></div>
        <button
          onClick={handleClose}
          className='absolute right-3 top-0.5 text-3xl text-gray-400 hover:text-gray-600'
        >
          &times;
        </button>
        <h2 className='mb-5 mt-4 font-creato-light text-4xl md:text-6xl'>
          {t('MSG_538')} <span className='font-creato-bold'> {t('MSG_539')}</span>!
        </h2>
        <p className='mb-12 font-creato-light text-2xl md:text-4xl'>
          {t('MSG_540')}
          <span className='mt-1 block'> {t('MSG_541')}</span>
        </p>
        <button
          onClick={redirectToRegister}
          className='mx-auto w-64 transform rounded-md bg-main px-6 py-3 text-lg text-white transition-transform duration-200 ease-in-out hover:scale-105'
        >
          {t('MSG_542')}
        </button>
      </div>
    </div>
  )
}
