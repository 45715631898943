import t from '../../store/languageActions'
import image1 from '@assets/Carousel2DecorationPageInfo/estarContemporaneo.jpg'
import image2 from '@assets/Carousel2DecorationPageInfo/estarEscandinavo.jpg'
import image3 from '@assets/Carousel2DecorationPageInfo/estarAirbnb.jpg'
import image4 from '@assets/Carousel2DecorationPageInfo/estarBarroco.jpg'
import image5 from '@assets/Carousel2DecorationPageInfo/estarBauhaus.jpg'
import image6 from '@assets/Carousel2DecorationPageInfo/estarBiophilic.jpg'
import image7 from '@assets/Carousel2DecorationPageInfo/estarBohemio.jpg'
import image8 from '@assets/Carousel2DecorationPageInfo/estarClasico.jpg'
import image9 from '@assets/Carousel2DecorationPageInfo/estarCyber.jpg'
import image10 from '@assets/Carousel2DecorationPageInfo/estarDecoart.jpg'
import image11 from '@assets/Carousel2DecorationPageInfo/estarFarmhouse.jpg'
import image12 from '@assets/Carousel2DecorationPageInfo/estarFuturista.jpg'
import image13 from '@assets/Carousel2DecorationPageInfo/estarGamingRoom.jpg'
import image14 from '@assets/Carousel2DecorationPageInfo/estarGaudi.jpg'
import image15 from '@assets/Carousel2DecorationPageInfo/estarHalloween.jpg'
import image16 from '@assets/Carousel2DecorationPageInfo/estarHampton.jpg'
import image17 from '@assets/Carousel2DecorationPageInfo/estarIndustrial.jpg'
import image18 from '@assets/Carousel2DecorationPageInfo/estarJapanes.jpg'
import image19 from '@assets/Carousel2DecorationPageInfo/estarMaximalista.jpg'
import image20 from '@assets/Carousel2DecorationPageInfo/estarMediterraneo.jpg'
import image21 from '@assets/Carousel2DecorationPageInfo/estarMidcentury.jpg'
import image22 from '@assets/Carousel2DecorationPageInfo/estarMinimalista.jpg'
import image23 from '@assets/Carousel2DecorationPageInfo/estarModerno.jpg'
import image24 from '@assets/Carousel2DecorationPageInfo/estarMontana.jpg'
import image25 from '@assets/Carousel2DecorationPageInfo/estarNavidad.jpg'
import image26 from '@assets/Carousel2DecorationPageInfo/estarRosa.jpg'
import image27 from '@assets/Carousel2DecorationPageInfo/estarRustico.jpg'
import image28 from '@assets/Carousel2DecorationPageInfo/estarTribal.jpg'
import image29 from '@assets/Carousel2DecorationPageInfo/estarTropical.jpg'
import image30 from '@assets/Carousel2DecorationPageInfo/estarVintage.jpg'
import image31 from '@assets/Carousel2DecorationPageInfo/estarZen.jpg'
const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image30,
  image31,
]
export default function CarouselDecoration2Page() {
  return (
    <div className='relative w-full overflow-hidden'>
      <div className='left-0 top-2 w-full space-y-4 bg-black p-2 text-left text-white sm:top-6 sm:p-6'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_130')}
        </h1>
        <h2 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_131')}
        </h2>
        <h2 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_132')}
        </h2>
        <h2
          className='px-1 font-creato-medium text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'
          style={{ marginTop: '3rem' }}
        >
          {t('MSG_133')} <span className='font-creato-medium font-bold'> {t('MSG_134')}</span>.
        </h2>
      </div>
      <div className='mt-8 flex animate-slide-left-infinite'>
        {images.map((el, index) => (
          <div
            key={index}
            className='h-60 w-1/2 flex-shrink-0 md:w-1/4'
          >
            <img
              className='h-full w-full object-cover'
              src={el}
              alt={`gallery-photo-${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
