import { getBaseUrl, getUserToken } from '..'

export const getUserAct = async () => {
  try {
    const response = await fetch(getBaseUrl('api/Auth/getUserAct'), {
      method: 'GET',
      headers: {
        Authorization: getUserToken(),
        'Accept-Language': navigator.language ?? 'es',
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json()
    } else {
      console.error('Error al recuperar los datos del usuario')
      throw new Error('Error al recuperar los datos del usuario')
    }
  } catch (error) {
    console.error('Error en la llamada a la API:', error)
    throw error
  }
}
