import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import t from '../store/languageActions'

type SuccessModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}

Modal.setAppElement('#root')

export default function SuccessModal({ isOpen, onRequestClose }: SuccessModalProps) {
  const navigate = useNavigate()

  const handleNavigateLogin = () => {
    onRequestClose()
    navigate('/login')
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Registro Exitoso'
      className='relative mx-auto my-8 h-auto w-[95%] max-w-2xl overflow-y-auto rounded-md bg-white px-8 py-10 text-black sm:w-[90%] md:w-[85%] lg:w-[75%] xl:w-[60%]'
      overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-10'
    >
      <div className='relative flex flex-col items-center px-6 py-4'>
        <button
          className='absolute -right-5 -top-9 text-2xl text-gray-400 hover:text-gray-200'
          onClick={handleNavigateLogin}
        >
          &times;
        </button>
        <p className='mt-4 text-center font-creato-bold text-lg text-main'>{t('MSG_298')}</p>
        <p className='text-md mt-4 text-center font-creato-light'>
          {t('MSG_548')}
          <button
            onClick={handleNavigateLogin}
            className='ml-2 text-blue-500 underline'
          >
            {t('MSG_301')}
          </button>
        </p>
      </div>
    </Modal>
  )
}
