import img1 from '@assets/Personalization/ExamplesPrompt/img1.png'
import img2 from '@assets/Personalization/ExamplesPrompt/img2.png'
import img3 from '@assets/Personalization/ExamplesPrompt/img3.png'
import img4 from '@assets/Personalization/ExamplesPrompt/img4.png'
import img5 from '@assets/Personalization/ExamplesPrompt/img5.png'
import img6 from '@assets/Personalization/ExamplesPrompt/img6.png'
import img7 from '@assets/Personalization/ExamplesPrompt/img7.png'
import img8 from '@assets/Personalization/ExamplesPrompt/img8.png'
import img9 from '@assets/Personalization/ExamplesPrompt/img9.png'
import img10 from '@assets/Personalization/ExamplesPrompt/img10.png'
import img11 from '@assets/Personalization/ExamplesPrompt/img11.png'
import img12 from '@assets/Personalization/ExamplesPrompt/img12.png'
import img13 from '@assets/Personalization/ExamplesPrompt/img13.png'

import { useEffect } from 'react'
import { store } from '../../store'
type ModalInfoProps = {
  isOpen: boolean
  onClose: () => void
}

export default function ModalInfo({ isOpen, onClose }: ModalInfoProps) {
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
      {/* Contenedor del modal con borde mostaza */}
      <div className='relative mx-auto w-full max-w-5xl border-2 border-main bg-white shadow-xl'>
        {/* Botón de cierre */}
        <button
          className='absolute right-4 top-2 text-xl font-bold text-black'
          onClick={onClose}
        >
          &times;
        </button>

        {/* Contenido del modal */}
        <div className='max-h-[85vh] overflow-auto px-8'>
          <h2 className='mb-4 mt-10 text-center font-creato-medium text-3xl'>{t('MSG_547')}</h2>

          {/* Ejemplo 1 */}
          <div className='mb-8'>
            <h3 className='my-8 text-center font-creato-bold text-5xl'>1</h3>
            <p className='mb-8 text-left font-creato-medium text-lg'>
              A modern Mediterranean-style house with white, textured stucco walls and terracotta
              roof tiles. The facade features large arched windows and glass doors framed in black,
              allowing natural light to flow inside while giving the house a warm, inviting glow. A
              small balcony with wrought iron railings overlooks the front entrance, adding a
              classic Mediterranean touch. The outdoor area is paved with light stone tiles,
              surrounded by lush greenery and low-maintenance plants, perfectly blending with the
              natural environment. Soft, ambient lighting from wall-mounted lanterns highlights the
              house’s architectural features and creates a cozy, welcoming atmosphere as evening
              approaches. The overall design reflects a perfect blend of traditional Mediterranean
              charm and modern elegance
            </p>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              <img
                src={img1}
                alt='Imagen 1'
                className='w-full object-cover'
              />
              <img
                src={img2}
                alt='Imagen 2'
                className='w-full object-cover'
              />
            </div>
          </div>

          {/* Ejemplo 2 */}
          <div className='mb-8'>
            <h3 className='my-8 text-center font-creato-bold text-5xl'>2</h3>
            <p className='mb-8 text-left font-creato-medium text-lg'>
              Create an empty room of 30 square meters, with light wooden floors and white walls.
              With large windows that let in natural light.
            </p>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              <img
                src={img3}
                alt='Imagen 3'
                className='w-full object-cover'
              />
              <img
                src={img4}
                alt='Imagen 4'
                className='w-full object-cover'
              />
            </div>
          </div>

          {/* Ejemplo 3 */}
          <div className='mb-8'>
            <h3 className='my-8 text-center font-creato-bold text-5xl'>3</h3>
            <p className='mb-8 text-left font-creato-medium text-lg'>
              Modern-style (bedroom interior) With mirror and plant and headboard and night light
              and bedside table or night stand and storage bench or ottoman and accent chair and
              dresser closet. . With Neutral Walls and Textures and Natural Materials and Elements
              and Simple, Clean Lines and Simplistic Furniture and Open and Natural Lighting and
              Practicality and Functionality and Neutral Walls and Textures.
            </p>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              <img
                src={img5}
                alt='Imagen 5'
                className='w-full object-cover'
              />
              <img
                src={img6}
                alt='Imagen 6'
                className='w-full object-cover'
              />
            </div>
          </div>

          {/* Ejemplo 4 */}
          <div className='mb-8'>
            <h3 className='my-8 text-center font-creato-bold text-5xl'>4</h3>
            <p className='mb-8 text-left font-creato-medium text-lg'>
              Maximalist-style (living room interior) With coffee tables and electric lamps and rug
              and televisions and sofa and chairs and plant and furniture. With eye-catching and
              bold design and vibrant and bold colors and bold creativity and more is more
              philosophy and playful and bold patterns.
            </p>
            <div className='flex justify-center'>
              <img
                src={img7}
                alt='Imagen 5'
                className='w-full max-w-xl object-cover'
              />
            </div>
          </div>

          {/* Ejemplo 5 */}
          <div className='mb-8'>
            <h3 className='my-8 text-center font-creato-bold text-5xl'>5</h3>
            <p className='mb-8 text-left font-creato-medium text-lg'>
              A ski chalet-style bathroom with warm and rustic elements. The bathroom features a
              wooden vanity with a stone sink, a walk-in shower with natural stone tiles, and a
              soaking tub. Walls are painted in earthy colors like brown and beige, with ski chalet
              decor. Include rustic fixtures such as a copper faucet, a large mirror with a wooden
              frame, and cozy lighting. The overall design should be warm and inviting, with a focus
              on natural materials and cozy decor.
            </p>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              <img
                src={img8}
                alt='Imagen 5'
                className='w-full object-cover'
              />
              <img
                src={img9}
                alt='Imagen 6'
                className='w-full object-cover'
              />
            </div>
          </div>

          {/* Ejemplo 6 */}
          <div className='mb-8'>
            <h3 className='my-8 text-center font-creato-bold text-5xl'>6</h3>
            <p className='mb-8 text-left font-creato-medium text-lg'>
              Create a european kitchen with a futuristic style, using cutting-edge designs, sleek
              surfaces, and innovative lighting solutions.
            </p>
            <div className='flex justify-center'>
              <img
                src={img10}
                alt='Imagen 5'
                className='w-full max-w-xl object-cover'
              />
            </div>
          </div>

          {/* Ejemplo 7 */}
          <div className='mb-8'>
            <h3 className='my-8 text-center font-creato-bold text-5xl'>7</h3>
            <p className='mb-8 text-left font-creato-medium text-lg'>
              Mediterranean-style (living room interior) with sofa, and rug and chairs and electric
              lamps and furniture and televisions and occasional tables and plant. With blue and
              white walls, and a relaxed, coastal feel.
            </p>
            <div className='flex flex-col items-center'>
              <img
                src={img11}
                alt='Imagen 11'
                className='mb-4 w-full max-w-3xl object-cover'
              />
              <img
                src={img12}
                alt='Imagen 12'
                className='w-full max-w-3xl object-cover'
              />
            </div>
          </div>

          {/* Ejemplo 8 */}
          <div className='mb-8'>
            <h3 className='my-8 text-center font-creato-bold text-5xl'>8</h3>
            <p className='mb-8 text-left font-creato-medium text-lg'>
              Create a modern black exterior house.
            </p>
            <div className='flex justify-center'>
              <img
                src={img13}
                alt='Imagen 5'
                className='w-full max-w-3xl object-cover'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
