import Modal from 'react-modal'
import { useEffect } from 'react'
import { store } from '../store'

type TermsModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}

Modal.setAppElement('#root')

export default function TermsModal({ isOpen, onRequestClose }: TermsModalProps) {
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Términos y condiciones'
      className='relative mx-auto my-8 max-h-[80vh] w-full max-w-4xl overflow-y-auto rounded-md bg-white p-8 text-black'
      overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-20'
    >
      <button
        onClick={onRequestClose}
        className='absolute right-2 top-2 text-xl font-bold'
      >
        &times;
      </button>
      <h2 className='mb-4 text-2xl'>{t('MSG_225')}</h2>
      {t('MSG_226')}
      <br />
      {t('MSG_227')}
      <br />
      {t('MSG_228')}
      <br />
      <br />
      <span className='font-creato-bold'> {t('MSG_229')}</span>
      <br />
      {t('MSG_230')}
      <br />
      {t('MSG_231')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_232')}</p>
      <br />
      {t('MSG_233')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_234')}</p>
      <br />
      {t('MSG_235')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_236')}</p>
      <br />
      {t('MSG_237')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_238')}</p>
      <br />
      {t('MSG_239')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_240')}</p>
      <br />
      {t('MSG_241')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_242')}</p>
      <br />
      {t('MSG_243')}
      <br />
      {t('MSG_244')}
      <br />
      {t('MSG_245')}
      <br />
      {t('MSG_246')}
      <br />
      {t('MSG_247')}
      <br />
      {t('MSG_248')}
      <br />
      {t('MSG_249')}
      <br />
      {t('MSG_250')}
      <br />
      {t('MSG_251')}
      <br />
      {t('MSG_252')}
      <br />
      {t('MSG_253')}
      <br />
      {t('MSG_254')}
      <br />
      {t('MSG_255')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'> {t('MSG_256')}</p>
      <br />
      {t('MSG_257')}
      <br />
      {t('MSG_258')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_259')}</p>
      <br />
      {t('MSG_260')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_261')}</p>
      <br />
      {t('MSG_262')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_263')}</p>
      <br />
      {t('MSG_264')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_265')}</p>
      <br />
      {t('MSG_266')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_267')}</p>
      <br />
      {t('MSG_268')}
      <br />
      <p className='-mb-6 mt-2'>{t('MSG_269')}</p>
      <br />
      {t('MSG_270')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_271')}</p>
      <br />
      {t('MSG_272')}
      <br />
      {t('MSG_273')}
      <br />
      {t('MSG_274')}
      <br />
      {t('MSG_275')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_276')}</p>
      <br />
      {t('MSG_277')}
      <br />
      {t('MSG_278')}
      <br />
      {t('MSG_279')}
      <br />
      {t('MSG_280')}
      <br />
      {t('MSG_281')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_282')}</p>
      <br />
      {t('MSG_283')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_284')}</p>
      <br />
      {t('MSG_285')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_286')}</p>
      <br />
      {t('MSG_287')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_288')}</p>
      <br />
      {t('MSG_289')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_290')}</p>
      <br />
      {t('MSG_291')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_292')}</p>
      <br />
      {t('MSG_293')}
      <br />
      <p className='-mb-6 mt-4 font-creato-bold'>{t('MSG_294')}</p>
      <br />
      {t('MSG_295')}
      <br />
      <span className='pr-2'>{t('MSG_306')}</span>
      <a
        href='mailto:info@for2inspiration.ai'
        className='text-main hover:underline'
      >
        info@for2inspiration.ai
      </a>
    </Modal>
  )
}
