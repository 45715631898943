import { getBaseUrl, getUserToken } from '..'
import { store } from '../../store'

export const modifyUserAct = async (dataToSend: any) => {
  try {
    const response = await fetch(getBaseUrl('api/auth/modifyUserAct'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getUserToken(),
        'Accept-Language': navigator.language ?? 'es',
      },
      body: JSON.stringify(dataToSend),
    })

    if (response.ok) {
      const isJson = response.headers.get('content-type')?.includes('application/json')
      if (isJson) {
        const data = await response.json()

        if (data.textos && typeof data.textos === 'object') {
          store.getState().setTranslations(data.textos)
        }

        return data
      } else {
        return { success: true }
      }
    } else {
      const errorText = await response.text()
      let errorDetail = 'Error al actualizar los datos.'

      try {
        const errorData = JSON.parse(errorText)
        errorDetail = errorData.detail || errorDetail
      } catch (e) {
        console.error('No se pudo parsear la respuesta de error:', errorText)
      }

      console.error('Error en la respuesta de la API:', errorDetail)
      throw new Error(errorDetail)
    }
  } catch (error) {
    console.error('Error en la llamada a la API:', error)
    throw error
  }
}
