import { getBaseHeaders, getBaseUrl } from '..'

export default async function randomTypedImage(
  setImage: (data: string) => void,
  type: 'Portada' | 'Carusel' | 'InteriorDesign',
) {
  const info = await fetch(getBaseUrl(`api/Portada/obtimagenrandom/${type}`), getBaseHeaders())
  console.log('New random image:', { info }) as any as ReadableStream
  // const data =
  const data = await info.text()
  setImage(data)
  return data
}
