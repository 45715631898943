import { useNavigate } from 'react-router-dom'
import { store } from '../../store'
import t from '../../store/languageActions'

type PricingPlansProps = {
  darkMode?: boolean
}

export default function PricingPlans({ darkMode = false }: PricingPlansProps) {
  const navigate = useNavigate()
  const token = store((s) => s.userToken)

  const plans = [
    {
      title: t('MSG_72'),
      description: t('MSG_39'),
      price: '5€',
      credits: `7 ${t('MSG_40')}`,
      highlighted: false,
    },
    {
      title: t('MSG_73'),
      description: t('MSG_42'),
      price: '10€',
      credits: `16 ${t('MSG_40')}`,
      highlighted: true,
    },
    {
      title: t('MSG_74'),
      description: t('MSG_43'),
      price: '20€',
      credits: `40 ${t('MSG_40')}`,
      highlighted: false,
    },
  ]

  const handleButtonClick = () => {
    if (token) {
      navigate('/user/plans')
      window.scrollTo(0, 0)
    } else {
      navigate('/register')
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className={`py-20 ${darkMode ? 'bg-black' : 'bg-white'}`}>
      <div className='container mx-auto px-4'>
        <h2
          className={`mb-12 text-center font-creato-bold text-2xl leading-snug tracking-tight ${
            darkMode ? 'text-white' : 'text-black'
          }`}
        >
          {t('MSG_38')}{' '}
        </h2>
        <div className='flex flex-col items-center justify-center space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0'>
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`relative flex w-full flex-col items-center p-6 shadow-lg lg:w-72 ${
                plan.highlighted ? 'bg-main text-black' : 'bg-white'
              }`}
            >
              <div
                className={`absolute -top-4 left-4 rounded-md border border-gray-300 px-3 py-1 text-sm font-bold shadow-lg ${
                  plan.highlighted ? 'bg-white text-main' : 'bg-main text-white'
                }`}
              >
                {plan.title}
              </div>
              <h3 className='mb-4 mt-8 text-xl'>{plan.description}</h3>
              <p className='mb-4 text-4xl font-bold'>{plan.price}</p>
              <p className='mb-6'>{plan.credits}</p>
              <p className='mb-6 text-xs'>(1 crédito = 1 generación)</p>
              <button
                onClick={handleButtonClick}
                className={`transform rounded-md px-4 py-2 transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95 ${
                  plan.highlighted ? 'bg-white text-main' : 'bg-main text-white'
                }`}
              >
                {t('MSG_75')}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
