import t from '../../store/languageActions'
import d1 from '@assets/Exteriores/1.jpg'
import d2 from '@assets/Exteriores/2.jpg'
import d3 from '@assets/Exteriores/3.jpg'
import d4 from '@assets/Exteriores/4.jpg'
import d5 from '@assets/Exteriores/5.jpg'
import d6 from '@assets/Exteriores/6.jpg'
import d7 from '@assets/Exteriores/7.jpg'
import d8 from '@assets/Exteriores/8.jpg'
import d9 from '@assets/Exteriores/9.jpg'
import d10 from '@assets/Exteriores/10.jpg'
import d11 from '@assets/Exteriores/11.jpg'
import d12 from '@assets/Exteriores/12.jpg'
import d13 from '@assets/Exteriores/13.jpg'
import d14 from '@assets/Exteriores/14.jpg'
import d15 from '@assets/Exteriores/15.jpg'
import d16 from '@assets/Exteriores/16.jpg'

const images = [d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12, d13, d14, d15, d16]
const repeatedImages = Array(5).fill(images).flat()
export default function CarrouselExteriorsInfo() {
  return (
    <div className='h-auto w-full'>
      {/* Header */}
      <div className='w-full bg-black p-4 text-center text-white md:p-6 lg:p-12'>
        <h1 className='ml-4 text-left font-montserrat-bold text-3xl font-bold md:text-5xl lg:-ml-6'>
          {t('MSG_467')}
        </h1>
      </div>

      {/* Carousel Section */}
      <div className='relative h-[50vh] w-full overflow-hidden sm:h-[60vh] md:h-[70vh] lg:h-[80vh]'>
        {/* Texto encima de las imágenes */}
        <div className='absolute left-0 top-8 z-10 w-full bg-black bg-opacity-70 p-4 md:p-6 lg:p-8'>
          <p className='ml-8 text-left font-creato-medium tracking-widest text-white'>
            {t('MSG_471')}
          </p>
        </div>

        {/* Imágenes del carrusel */}
        <div className='flex h-full animate-slide-left-infinite'>
          {repeatedImages.map((el, index) => (
            <div
              key={index}
              className='h-[45vh] w-[85vw] flex-shrink-0 border-4 border-black sm:h-[60vh] sm:w-[80vw] md:h-[70vh] md:w-[70vw] lg:h-[80vh] lg:w-[60vw] xl:h-[70vh] xl:w-[40vw]'
            >
              <img
                className='h-full w-full object-cover'
                src={el}
                alt={`gallery-photo-${index}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
