import t from '../store/languageActions'
import { useWebsocketContext } from './Context'

type PricingCardProps = {
  popupTitle: string
  planName: string
  price: string
  month?: string
  credits: string
  description: string[]
  buttonStyle?: string
  planName2?: string
  handleRedirect?: () => void
  ignoreHighlightButtonStyle?: boolean
}

export default function PricingCard({
  planName,
  price,
  month,
  credits,
  description,
  popupTitle,
  buttonStyle = 'bg-main text-white',
  planName2,
  handleRedirect,
}: PricingCardProps) {
  const { tipoPlan } = useWebsocketContext()

  const highlighted =
    (tipoPlan === 5 && popupTitle.toLowerCase().trim() === 'profesional') ||
    (tipoPlan === 4 && popupTitle.toLowerCase().trim() === 'premium') ||
    (tipoPlan === 1 && popupTitle.toLowerCase().trim() === 'básico 5') ||
    (tipoPlan === 2 && popupTitle.toLowerCase().trim() === 'básico 10') ||
    (tipoPlan === 3 && popupTitle.toLowerCase().trim() === 'básico 20')

  // cuando óscar modifique los planDescripcion
  // const { planDescripcion } = useWebsocketContext()
  //   const highlighted = planDescripcion.toLowerCase() === popupTitle.toLowerCase()

  const isProfesional = popupTitle.toLowerCase().trim() === 'profesional'

  return (
    <div
      className={`relative mb-32 border p-6 shadow-md md:max-w-md ${highlighted ? 'bg-main text-white' : 'bg-white text-black'}`}
    >
      <div
        className={`absolute ${isProfesional ? 'left-[25%]' : 'left-[20%]'} top-0 -translate-x-1/2 -translate-y-1/2 transform rounded-lg px-4 py-1 font-creato-bold shadow-md ${highlighted ? 'bg-white text-main' : 'bg-white text-main'}`}
      >
        {t(popupTitle)}
      </div>
      <div className='mb-4 mt-8 font-creato-bold text-xl'>
        {t(planName)}
        <br />
        {t(planName2 || '')}
      </div>
      <p className='mb-2 text-4xl font-bold'>
        {t(price)}€
        {month && <span className='ml-1 font-creato-light text-[26px]'>{t(month)} </span>}
      </p>

      <p className='mb-2 text-xl'>{t(credits)}</p>
      <p className='text-md mb-8'>{t('MSG_41')}</p>
      <ul className='mb-4'>
        {description.map((item, index) => (
          <li
            key={index}
            className='mb-2 flex items-center'
          >
            <span className='mr-2 text-green-500'>✔</span>
            {t(item)}
          </li>
        ))}
      </ul>
      <button
        className={`mt-12 w-full rounded-md px-4 py-2 ${highlighted ? 'bg-white text-main' : buttonStyle} transform transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95`}
        onClick={handleRedirect}
      >
        {t('MSG_155')}
      </button>
    </div>
  )
}
