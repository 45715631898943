import LoggedUser from '@components/LoggedUser.tsx'
import getGroups from '@proxies/ia/getGroups.ts'
import allTypeImages from '@proxies/images/allTypeImages.ts'
import getUserHistory from '@proxies/user/getUserHistory.ts'
import newSession from '@proxies/user/newSession.ts'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import App from './App.tsx'
import Login from './components/Login.tsx'
import Register from './components/Register.tsx'
import PagePlans from '@components/plans/PagePlans.tsx'
import PagePlansActive from '@components/plans/PagePlansActive.tsx'
import TestAPI from './components/TestAPI.tsx'
import './index.css'
import shuffle from './utils/shuffle.ts'
import AboutUs from '@components/AboutUs.tsx'
import PageModifyData from '@components/PageModifyData.tsx'
import Context from '@components/Context.tsx'
import RenderingInfo from '@components/rendering/RenderingInfo.tsx'
import InspirationInfo from '@components/inspiration/InspirationInfo.tsx'
import DecorationInfo from '@components/decoration/DecorationInfo.tsx'
import RedecorationInfo from '@components/redecoration/RedecorationInfo.tsx'
import InspirationPage from '@components/inspiration/InspirationPage.tsx'
import InspirationStylesPage from '@components/inspiration/InspirationStylesPage.tsx'
import InspirationStyles2Page from '@components/inspiration/InspirationStyles2Page.tsx'
import InspirationGeneratePage from '@components/inspiration/InspirationGeneratePage.tsx'
import DecorationPage from '@components/decoration/DecorationPage.tsx'
import DecorationStylesPage from '@components/decoration/DecorationStylesPage.tsx'
import DecorationStyles2Page from '@components/decoration/DecorationStyles2Page.tsx'
import DecorationGeneratePage from '@components/decoration/DecorationGeneratePage.tsx'
import RedecorationPage from '@components/redecoration/RedecorationPage.tsx'
import RedecorationStylesPage from '@components/redecoration/RedecorationStylesPage.tsx'
import RedecorationStyles2Page from '@components/redecoration/RedecorationStyles2Page.tsx'
import RedecorationGeneratePage from '@components/redecoration/RedecorationGeneratePage.tsx'
import RenderingPage from '@components/rendering/RenderingPage.tsx'
import RenderingStylesPage from '@components/rendering/RenderingStylesPage.tsx'
import RenderingStyles2Page from '@components/rendering/RenderingStyles2Page.tsx'
import RenderingGeneratePage from '@components/rendering/RenderingGeneratePage.tsx'
import ExteriorsPage from '@components/exteriors/ExteriorsPage.tsx'
import ExteriorsInfo from '@components/exteriors/ExteriorsInfo.tsx'
import ExteriorsStylesPage from '@components/exteriors/ExteriorsStylesPage.tsx'
import ExteriorsGeneratePage from '@components/exteriors/ExteriorsGeneratePage.tsx'
import GalleryPage from '@components/Gallery/GalleryPage.tsx'
import PlannerInfo from '@components/planner/PlannerInfo.tsx'
import { initGA } from './analytics.ts'
import PersonalizationInfo from '@components/personalization/PersonalizationInfo.tsx'
import PersonalizationPage from '@components/personalization/PersonalizationPage.tsx'
import PersonalizationGenerate from '@components/personalization/PersonalizationGenerate.tsx'

// Inicializar Google Analytics
initGA()
const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    loader: async () => {
      const portadaImage = await allTypeImages(() => {}, 'Portada')
      const carouselImageArr = await allTypeImages(() => {}, 'Carusel')
      await newSession()
      return {
        portadaImage: shuffle(portadaImage),
        carouselImageArr: shuffle(carouselImageArr),
      }
    },
    errorElement: <div>Algo salió mal.</div>,
  },
  {
    path: '/user',
    element: <LoggedUser />,
    loader: async () => {
      await newSession()
      const projects = await getUserHistory(false, '')
      return { projects }
    },
  },
  {
    path: '/login',
    element: <Login />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/register',
    element: <Register />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/about',
    element: <AboutUs />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/gallery',
    element: <GalleryPage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/plans',
    element: <PagePlans />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/user/plans',
    element: <PagePlansActive />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/user/modify-data',
    element: <PageModifyData />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/inspiration/info',
    element: <InspirationInfo />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/decoration/info',
    element: <DecorationInfo />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/redecoration/info',
    element: <RedecorationInfo />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/rendering/info',
    element: <RenderingInfo />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/exteriors/info',
    element: <ExteriorsInfo />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/planner/info',
    element: <PlannerInfo />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/personalization/info',
    element: <PersonalizationInfo />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/inspiration',
    element: <InspirationPage />,
    loader: async () => {
      await newSession()
      const info = await getGroups()
      // const questions = await getQuestions(("3")=>{},)
      return info
    },
  },
  {
    path: '/inspiration/styles',
    element: <InspirationStylesPage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/inspiration/styles2',
    element: <InspirationStyles2Page />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/inspiration/styles2/generate',
    element: <InspirationGeneratePage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/decoration',
    element: <DecorationPage />,
    loader: async () => {
      await newSession()
      const info = await getGroups()
      return info
    },
  },
  {
    path: '/decoration/styles',
    element: <DecorationStylesPage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/decoration/styles2',
    element: <DecorationStyles2Page />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/decoration/styles2/generate',
    element: <DecorationGeneratePage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/redecoration',
    element: <RedecorationPage />,
    loader: async () => {
      await newSession()
      const info = await getGroups()
      return info
    },
  },
  {
    path: '/redecoration/styles',
    element: <RedecorationStylesPage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/redecoration/styles2',
    element: <RedecorationStyles2Page />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/redecoration/styles2/generate',
    element: <RedecorationGeneratePage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/rendering',
    element: <RenderingPage />,
    loader: async () => {
      await newSession()
      const info = await getGroups()
      return info
    },
  },
  {
    path: '/rendering/styles',
    element: <RenderingStylesPage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/rendering/styles2',
    element: <RenderingStyles2Page />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/rendering/styles2/generate',
    element: <RenderingGeneratePage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/exteriors',
    element: <ExteriorsPage />,
    loader: async () => {
      await newSession()
      const info = await getGroups()
      return info
    },
  },
  {
    path: '/exteriors/styles',
    element: <ExteriorsStylesPage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/exteriors/styles/generate',
    element: <ExteriorsGeneratePage />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/personalization',
    element: <PersonalizationPage />,
    loader: async () => {
      await newSession()
      const info = await getGroups()
      return info
    },
  },
  {
    path: '/personalization/generate',
    element: <PersonalizationGenerate />,
    loader: async () => {
      await newSession()
      return null
    },
  },
  {
    path: '/test',
    element: <TestAPI />,
  },
])
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Context>
      <span className='font-creato-medium'>
        <RouterProvider router={routes} />
      </span>
    </Context>
  </React.StrictMode>,
)
