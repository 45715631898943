import Modal from 'react-modal'
import { useEffect } from 'react'
import t from '../store/languageActions'

type SaveModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}

Modal.setAppElement('#root')

export default function SaveModal({ isOpen, onRequestClose }: SaveModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Guardar Proyecto'
      className='relative mx-auto my-8 max-h-[60vh] w-full max-w-md overflow-y-auto rounded-md bg-white px-16 py-20 text-black'
      overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center'
    >
      <div className='flex flex-col items-center'>
        <p className='text-lg font-bold'>{t('MSG_297')}</p>
        <div className={`mt-4 h-1 bg-main ${isOpen ? 'animate-grow' : ''}`}></div>
      </div>
    </Modal>
  )
}
