import { getBaseHeaders, getBaseUrl } from '..'

export default async function allTypeImages(
  setImage: (data: string) => void,
  type: 'Portada' | 'Carusel' | 'InteriorDesign',
) {
  const info = await fetch(getBaseUrl(`api/Portada/obtimagenes/${type}`), getBaseHeaders())

  const data = await info.json()
  setImage(data)
  return data as string[]
}
