import Decoracion from '@assets/Decoracion.jpg'
import Inspiration from '@assets/Inspiracion.jpg'
import Redecoracion from '@assets/ReDecoracion.jpg'
import Renderizado from '@assets/RenderingPageInfo/renderizado.jpg'
import Exteriores from '@assets/Exteriores/exteriores.jpg'
import Planner from '@assets/Planner/planificación.jpg'
import Personalization from '@assets/personalization.png'
import t from '../store/languageActions'
import CardCreative from './CardCreative'
// import { useWebsocketContext } from './Context'
// import { store } from '../store'

export default function CreativeArea() {
  // const userToken = store((s) => s.userToken)
  // const { creditosActuales } = useWebsocketContext()
  // const userInfo = store((s) => s.userInfo)
  // console.log('usertoken', userToken)

  // const authenticateAndNavigate = async () => {
  //   if (!userToken) {
  //     console.error('Error: El token del usuario no está disponible.')
  //     return
  //   }

  //   try {
  //     const response = await fetch('https://sbapi.for2inspiration.ai/api/auth/syncUsuarioToF2I', {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'text/plain',
  //         Authorization: `buda ${userToken}`,
  //       },
  //     })

  //     const token = await response.text()

  //     const isDevelopment = window.location.origin.includes('localhost')

  //     const targetUrl = isDevelopment
  //       ? 'http://localhost:9000/planner'
  //       : 'https://for2inspiration.for2home.com/planner'

  //     const targetOrigin = isDevelopment
  //       ? 'http://localhost:9000'
  //       : 'https://for2inspiration.for2home.com'

  //     // Abrir en una nueva pestaña usando '_blank'
  //     const f2hApp = window.open(targetUrl, '_blank')

  //     if (!f2hApp || f2hApp.closed || typeof f2hApp.closed === 'undefined') {
  //       // Si la ventana emergente fue bloqueada
  //       console.error('Error: La ventana emergente fue bloqueada o no se pudo abrir.')
  //       return
  //     }

  //     // Usar `setInterval` para intentar enviar el mensaje una vez que la nueva ventana esté disponible
  //     const interval = setInterval(() => {
  //       if (f2hApp && f2hApp.closed === false) {
  //         try {
  //           f2hApp.postMessage(
  //             {
  //               source: 'app1',
  //               type: 'syncData',
  //               creditos: creditosActuales,
  //               tokenF2H: token,
  //               tokenF2I: userToken,
  //               name: userInfo.name,
  //             },
  //             targetOrigin,
  //           )
  //           clearInterval(interval)
  //         } catch (error) {
  //           console.error('Error enviando el mensaje, reintentando...', error)
  //         }
  //       } else {
  //         console.error('La ventana emergente no está disponible o ha sido cerrada.')
  //         clearInterval(interval)
  //       }
  //     }, 1000)
  //   } catch (error) {
  //     console.error('Error al obtener el token de la API:', error)
  //   }
  // }
  return (
    <div className='pointer-events-none relative'>
      <div className='pointer-events-none absolute left-0 top-0 z-0 h-[500px] w-full bg-black text-white'></div>
      <div className='pointer-events-none absolute bottom-0 left-0 z-0 h-1/5 w-full bg-white'></div>
      <div className='pointer-events-none relative z-10 px-4 pt-28'>
        <div className='pointer-events-none mx-auto max-w-screen-2xl'>
          <h1 className='mb-8 text-center font-montserrat-bold text-4xl font-bold text-white lg:text-left'>
            {t('MSG_92')}
          </h1>

          {/* Primera fila: 4 tarjetas */}
          <div className='flex flex-wrap justify-center lg:justify-between'>
            <div className='w-full p-4 sm:w-1/2 lg:w-1/4'>
              <CardCreative
                title={t('MSG_67')}
                description={t('MSG_89')}
                buttonText={t('MSG_86')}
                imgSrc={Inspiration}
                navigateTo='/inspiration'
              />
            </div>
            <div className='w-full p-4 sm:w-1/2 lg:w-1/4'>
              <CardCreative
                title={t('MSG_70')}
                description={t('MSG_90')}
                buttonText={t('MSG_87')}
                imgSrc={Decoracion}
                navigateTo='/decoration'
              />
            </div>
            <div className='w-full p-4 sm:w-1/2 lg:w-1/4'>
              <CardCreative
                title={t('MSG_68')}
                description={t('MSG_91')}
                buttonText={t('MSG_88')}
                imgSrc={Redecoracion}
                navigateTo='/redecoration'
                requiresSpecialPlan={true}
              />
            </div>
            <div className='w-full p-4 sm:w-1/2 lg:w-1/4'>
              <CardCreative
                title={t('MSG_456')}
                description={t('MSG_462')}
                buttonText={t('MSG_461')}
                imgSrc={Renderizado}
                navigateTo='/rendering'
              />
            </div>
          </div>

          {/* Segunda fila: 3 tarjetas centradas */}
          <div className='flex flex-wrap justify-center'>
            <div className='w-full p-4 sm:w-1/2 lg:w-1/4'>
              <CardCreative
                title={t('MSG_467')}
                description={t('MSG_468')}
                buttonText={t('MSG_469')}
                imgSrc={Exteriores}
                navigateTo='/exteriors'
              />
            </div>
            <div className='w-full p-4 sm:w-1/2 lg:w-1/4'>
              <CardCreative
                title={t('MSG_495')}
                description={t('MSG_496')}
                buttonText={t('MSG_494')}
                imgSrc={Planner}
                // navigateTo={authenticateAndNavigate}
                isUnderConstruction={true}
              />
            </div>
            <div className='w-full p-4 sm:w-1/2 lg:w-1/4'>
              <CardCreative
                title={t('MSG_514')}
                description={t('MSG_529')}
                buttonText={t('MSG_527')}
                imgSrc={Personalization}
                navigateTo='/personalization'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
