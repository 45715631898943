import portadaInfoRenderizado from '@assets/RenderingPageInfo/PortadaRenderizado.jpg'
import t from '../../store/languageActions'

export default function PortadaInfoRendering() {
  return (
    <div className='h-auto w-full'>
      <div className='w-full bg-black p-4 text-center text-white md:p-6 lg:p-12'>
        <h1 className='ml-4 text-left font-montserrat-bold text-3xl font-bold md:text-5xl lg:-ml-6'>
          {t('MSG_456')}
        </h1>
      </div>
      <div className='relative h-[70vh] w-full overflow-hidden'>
        <img
          src={portadaInfoRenderizado}
          alt='cover image Info Rendering'
          className='absolute inset-0 h-full w-full object-cover'
        />
        <div className='absolute left-0 top-8 z-10 w-full bg-black bg-opacity-70 p-4 md:p-6 lg:p-8'>
          <p className='ml-8 text-left font-creato-medium tracking-widest text-white'>
            {t('MSG_457')}
          </p>
        </div>
      </div>
    </div>
  )
}
