import t from '../store/languageActions'
import image1 from '@assets/1.jpg'
import image2 from '@assets/2.jpg'
import image3 from '@assets/3.jpg'
import image4 from '@assets/4.jpg'
import image5 from '@assets/5.jpg'
import image6 from '@assets/6.jpg'
import image7 from '@assets/7.jpg'
import image8 from '@assets/8.jpg'

function ImageGridHome() {
  return (
    <div className='grid grid-cols-1 gap-1 md:grid-cols-4'>
      <div className='col-span-1 md:col-span-2'>
        <div className='absolute left-3 top-28 z-20 hidden p-2 font-montserrat-bold text-5xl text-white lg:block'>
          {t('MSG_126')}
        </div>
        <ImageWithOverlay
          imageSrc={image1}
          text1={t('MSG_135')}
          text2={t('MSG_117')}
          text3={t('MSG_118')}
          text4={t('MSG_119')}
          className='h-[520px] sm:h-[600px] md:h-[710px] lg:h-[700px] xl:h-[1445px]'
        />
      </div>
      <div className='col-span-1 md:col-span-2'>
        <img
          src={image2}
          alt='Image room with purple background'
          className='h-full w-full object-cover'
        />
      </div>
      <div className='col-span-1 md:col-span-2'>
        <img
          src={image3}
          alt='Image room in the style of works by Piet Mondrian'
          className='h-full w-full object-cover'
        />
      </div>
      <div className='col-span-1 md:col-span-2'>
        <ImageWithOverlay
          imageSrc={image4}
          text1={t('MSG_120')}
          text2={t('MSG_121')}
          text3={t('MSG_122')}
          className='h-[520px] sm:h-[600px] md:h-[710px] lg:h-[700px] xl:h-[1285px]'
        />
      </div>
      <div className='col-span-1 md:col-span-2'>
        <ImageWithOverlay
          imageSrc={image5}
          text1={t('MSG_123')}
          text2={t('MSG_124')}
        />
      </div>
      <div className='col-span-1 md:col-span-2'>
        <img
          src={image6}
          alt='Image kitchen image with armchair in front'
          className='h-full w-full object-cover'
        />
      </div>
      <div className='col-span-1 md:col-span-2'>
        <img
          src={image7}
          alt='Bathroom image generated'
          className='h-full w-full object-cover'
        />
      </div>
      <div className='col-span-1 md:col-span-2'>
        <ImageWithOverlay
          imageSrc={image8}
          text1={t('MSG_125')}
          className='h-[520px] sm:h-[600px] md:h-[710px] lg:h-[700px] xl:h-[1000px]'
        />
      </div>
    </div>
  )
}

type ImageWithOverlayProps = {
  imageSrc: string
  text1?: string
  text2?: string
  text3?: string
  text4?: string
  className?: string
}

function ImageWithOverlay({
  imageSrc,
  text1,
  text2,
  text3,
  text4,
  className,
}: ImageWithOverlayProps) {
  return (
    <div className='group relative'>
      <img
        src={imageSrc}
        alt='image of different rooms with AI results'
        className={`h-full w-full object-cover ${className}`}
      />
      <div className='absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-70 p-4 md:p-9'>
        {text1 && (
          <p className='mx-4 text-left font-chopin text-sm text-white sm:text-base md:mx-9 md:text-lg lg:text-xl'>
            {text1}
          </p>
        )}
        {text2 && (
          <p className='mx-4 mt-2 text-left font-chopin text-sm text-white sm:text-base md:mx-9 md:text-lg lg:text-xl'>
            {text2}
          </p>
        )}
        {text3 && (
          <p className='mx-4 mt-2 text-left font-chopin text-sm text-white sm:text-base md:mx-9 md:text-lg lg:text-xl'>
            {text3}
          </p>
        )}
        {text4 && (
          <p className='mx-4 mt-2 text-left font-chopin text-sm text-white sm:text-base md:mx-9 md:text-lg lg:text-xl'>
            {text4}
          </p>
        )}
      </div>
    </div>
  )
}

export default ImageGridHome
