import atras from '@assets/atras.png'
import { useNavigate } from 'react-router-dom'
import { store } from '../../store'

type PersonalizationAlertProps = {
  prompt?: string
  noPrompt?: string
  navigateTo: string
  state?: any
  fn?: () => void
}

export default function AlertTextPrompt({
  prompt,
  noPrompt,
  navigateTo,
  state,
  fn,
}: PersonalizationAlertProps) {
  const navigate = useNavigate()
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  const handleNavigate = () => {
    navigate(navigateTo, { state })
  }

  return (
    <div className='relative m-auto mt-4 bg-white shadow-lg lg:w-4/5'>
      <div className='text-md relative border border-gray-500 bg-white p-2.5 text-start font-creato-medium leading-5 text-black shadow-lg'>
        <button
          onClick={fn ? fn : handleNavigate}
          className='absolute left-3'
          aria-label='Go Back'
        >
          <img
            src={atras}
            alt='Return'
            className='h-5'
          />
        </button>
        <div className='ml-12'>
          <div className='flex items-center space-x-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#b88626'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='h-5 w-5'
            >
              <polyline points='20 6 9 17 4 12' />
            </svg>
            <span>
              <span className='font-creato-bold'>{t('MSG_534')}:</span> {prompt}
            </span>
          </div>
          {noPrompt && (
            <div className='mt-2 flex items-center space-x-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#b88626'
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='h-5 w-5'
              >
                <line
                  x1='18'
                  y1='6'
                  x2='6'
                  y2='18'
                />
                <line
                  x1='6'
                  y1='6'
                  x2='18'
                  y2='18'
                />
              </svg>
              <span>
                <span className='font-creato-bold'>{t('MSG_535')}:</span> {noPrompt}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
