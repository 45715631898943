import { useEffect } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import t from '../store/languageActions'

type NotificationProps = {
  isOpen: boolean
  onRequestClose: () => void
}

export default function Notification({ isOpen, onRequestClose }: NotificationProps) {
  const navigate = useNavigate()

  function handleGoToPlans() {
    navigate('/user/plans')
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Notification'
      className='relative mx-auto my-8 max-h-[80vh] w-full max-w-2xl overflow-y-auto rounded-lg bg-white p-10 text-black shadow-lg'
      overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center'
    >
      <button
        onClick={onRequestClose}
        className='absolute right-3 top-0 text-2xl text-gray-400 hover:text-gray-200'
      >
        ×
      </button>
      <div className='flex flex-col items-center py-8'>
        <p className='mt-6 font-creato-light text-lg text-red-600'>{t('MSG_364')}</p>
        <p className='mt-4 font-creato-light text-lg'>
          {t('MSG_365')} <span className='font-creato-bold'>{t('MSG_366')}</span> {t('MSG_367')}
        </p>
        <button
          onClick={handleGoToPlans}
          className='mb-5 mt-10 flex w-[270px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800'
        >
          {t('MSG_368')}
        </button>
      </div>
    </Modal>
  )
}
