import { getBaseHeaders, getBaseUrl } from '..'
import { store } from '../../store'
import getUserInfo from './getUserInfo'

const mockProps = {
  correo: 'string',
  password: 'string',
  remember: true,
}

type Props = typeof mockProps

export default async function login(config: Props) {
  try {
    const response = await fetch(
      getBaseUrl('api/Auth/authenticate'),
      getBaseHeaders({
        method: 'post',
        body: JSON.stringify(config),
        headers: { 'Content-Type': 'application/json' },
      }),
    )

    if (!response.ok) {
      const errorData = await response.json()
      if (response.status === 500) {
        throw new Error(errorData.detail || 'Error del servidor')
      } else {
        throw new Error('Error desconocido al autenticar')
      }
    }

    const data = (await response.json()) as {
      token: string
      rememberToken: string | null
      textos: Record<string, string>
    }


    if (data.textos && typeof data.textos === 'object') {
      store.getState().setTranslations(data.textos)
    } else {
      console.warn('No se recibieron textos válidos en la autenticación.')
    }

    store.getState().setUserToken(data.token)
    sessionStorage.setItem('userToken', data.token)

    const userInfo = await getUserInfo()
    store.getState().setUserInfo(userInfo)

    return data
  } catch (error) {
    console.error('Error en login:', error)
    if (error instanceof Error) {
      throw new Error(error.message || 'Ocurrió un error al intentar iniciar sesión')
    } else {
      throw new Error('Ocurrió un error desconocido')
    }
  }
}
