import BannerImageHeader from '@components/BannerImageHeader'
import CardSection from '@components/CardSection'
import Footer from '@components/Footer'
import ImageSection from '@components/ImageSection'
import Navbar from '@components/Navbar'
import StepsSection from '@components/StepsSection'
import ThreeSections from '@components/ThreeeSections'
import { useLoaderData, useLocation } from 'react-router-dom'

import './App.css'
import { useWebsocketContext } from '@components/Context'
import { useEffect } from 'react'
import { trackPageView } from './analytics'
import PricingPlans from '@components/plans/PricingPlan'
import PremiumPlans from '@components/plans/PremiumPlans'
import { ScrollProgressBar } from '@components/ScrollProgressBar'
import { FreeTrialModal } from '@components/FreeTrialModal'

function RouteChangeTracker() {
  const location = useLocation()

  useEffect(() => {
    trackPageView(location.pathname + location.search)
  }, [location])

  return null
}

function App() {
  const { portadaImage, carouselImageArr } = useLoaderData() as {
    portadaImage: string[]
    carouselImageArr: string[]
  }
  const { creditosActuales } = useWebsocketContext()
  const userToken = sessionStorage.getItem('userToken')

  return (
    <>
      <RouteChangeTracker />
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome
        creditosActuales={creditosActuales}
      />
      {!userToken && <FreeTrialModal />}
      <BannerImageHeader img={portadaImage} />
      <CardSection img={carouselImageArr} />
      <ImageSection />
      <ThreeSections />
      <StepsSection />
      <PricingPlans />
      <PremiumPlans />
      <Footer />
    </>
  )
}

export default App
