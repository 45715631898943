import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import { useWebsocketContext } from '../Context'
import Footer from '../Footer'
import ImageGeneratefinish from '../ImageGeneratefinish'
import Navbar from '../Navbar'
import t from '../../store/languageActions'
import Notification from '../Notification'
import { ScrollProgressBar } from '@components/ScrollProgressBar'
import AlertTextPrompt from './AlertTextPrompt'

export default function PersonalizationGenerate() {
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const navigate = useNavigate()
  const location = useLocation()

  const { imageFilename, imageMaskFilename, prompt, noPrompt, imageFile, imageWithDrawing } =
    location.state || {}

  const [isGenerating, setIsGenerating] = useState(false)
  const [generatedImageSrc, setGeneratedImageSrc] = useState(
    imageFile ||
      'https://api.for2inspiration.ai/Images/MotorIA/nLjjDu/f4ca0cd7-1ca6-46d4-9082-3dacd1792a59-0.png',
  )
  const { creditosActuales, connection } = useWebsocketContext()
  const isFirstRender = useRef(true)
  const isInvokingRef = useRef(false)
  const [showNotification, setShowNotification] = useState(false)
  const [idTrabUrlia, setIdTrabUrlia] = useState<number | null>(null)

  useLayoutEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])

  async function invokeAskToImage() {
    if (connection) {
      try {
        const payload = {
          idRespuestasIA: [],
          idGrupoPreguntasIA: null,
          tipoTrabajo: 7,
          imageFilename,
          imageMaskFilename,
          prompt,
          noPrompt,
          // tituloProyecto: null,
          // descripcionProyecto: null,
          textoDelFront: null,
        }

        console.log('Payload enviado para Personalización:', payload)
        const result = await connection.invoke('AskToImage', payload)
        console.log('AskToImage invocation successful', result)

        if (creditosActuales === 0) {
          setShowNotification(true)
          return false
        }

        if (result.incidencia) {
          setShowNotification(true)
          setIsGenerating(false)
          return false
        }
        return true
      } catch (error) {
        console.log('Error invoking AskToImage', error)
        setIsGenerating(false)
        return false
      }
    }
    return false
  }

  useEffect(() => {
    const handleNotification = (data: { urls: string[]; idTrabUrlia: number }) => {
      console.log('NotificacionTrabajosIA', data)
      if (data.urls.length > 0) {
        setGeneratedImageSrc(data.urls[0])
        setIdTrabUrlia(data.idTrabUrlia)
        setIsGenerating(false)
      }
    }

    if (connection) {
      connection.on('NotificacionTrabajosIA', handleNotification)
    }

    return () => {
      if (connection) {
        connection.off('NotificacionTrabajosIA', handleNotification)
      }
    }
  }, [connection])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      if (!isInvokingRef.current) {
        isInvokingRef.current = true
        invokeAskToImage().then((success) => {
          if (success) {
            setIsGenerating(true)
          }
          isInvokingRef.current = false
        })
      }
    }
  }, [])

  async function handleSaveClick(formData: { title: string; description: string }) {
    if (connection) {
      try {
        await connection.send('GuardarDatosUltTrabajo', {
          tituloProyecto: formData.title,
          descripcionProyecto: formData.description,
        })
        console.log('Datos guardados exitosamente')
      } catch (error) {
        console.log('Error guardando los datos', error)
      }
    }
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageGeneratefinish
        textTop={t('MSG_514')}
        imgSrc={generatedImageSrc}
        isGenerating={isGenerating}
        handleGenerateClick={() => {
          if (!isInvokingRef.current) {
            isInvokingRef.current = true
            invokeAskToImage().then((success) => {
              if (success) {
                setIsGenerating(true)
              }
              isInvokingRef.current = false
            })
          }
        }}
        handleSaveClick={handleSaveClick}
        img={imageFile || ''}
        idTrabUrlia={idTrabUrlia}
        showComparingButton={!!imageFile}
      />
      <AlertTextPrompt
        prompt={prompt}
        noPrompt={noPrompt}
        navigateTo='/personalization'
        state={{
          prompt,
          noPrompt,
          imageFile,
          imageWithDrawing,
        }}
      />
      <div className='mt-4 flex justify-end pl-4'>
        <button
          className='mr-[10%] h-9 w-3/5 rounded-lg bg-main text-white lg:ml-4 lg:mt-0 lg:w-1/5'
          onClick={() => navigate('/user')}
        >
          {t('MSG_163')}
        </button>
      </div>
      {showNotification && (
        <Notification
          isOpen={showNotification}
          onRequestClose={() => setShowNotification(false)}
        />
      )}
      <Footer />
    </div>
  )
}
