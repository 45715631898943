export default function getSelectionsFromText(text: string, nextText?: string) {
  const selections: { [key: string]: string } = {}
  const newTexto = text.replace(nextText || '', '')
  const parts = newTexto.split(',').map((part) => part.trim())
  parts.forEach((part) => {
    const [key, value] = part.split(':').map((p) => p.trim())
    if (key && value) {
      selections[key] = value
    }
  })
  const furtherText = nextText?.split(',').map((part) => part.trim())
  furtherText?.forEach((part) => {
    const [key, value] = part.split(':').map((p) => p.trim())

    if (key && value) {
      selections[key] = value
    }
  })
  return selections
}
