import t from '../../store/languageActions'
import above from '@assets/Exteriores/arriba.jpg'
import below from '@assets/Exteriores/Abajo.jpg'
import { motion } from 'framer-motion'
import { useState, useEffect } from 'react'
export default function ImagenExteriorsSection() {
  const [showFirstImage, setShowFirstImage] = useState(true)
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstImage((prev) => !prev)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div className='my-4 w-full space-y-4 bg-black text-left text-white sm:my-8 sm:p-4 lg:mt-4'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_472')}
        </h1>
      </div>
      <div className='relative w-full overflow-hidden'>
        {/* Contenedor de las imágenes con altura dinámica en porcentaje */}
        <div className='relative flex h-[30vh] w-full items-center justify-center sm:h-[50vh] lg:h-[65vh]'>
          <motion.img
            src={above}
            alt='First Image'
            className={`absolute h-full max-w-[160vh] object-contain ${showFirstImage ? 'z-10' : 'z-0'}`}
            initial={{ opacity: 1 }}
            animate={{ opacity: showFirstImage ? 1 : 0 }}
            transition={{ duration: 2 }}
          />
          <motion.img
            src={below}
            alt='Second Image'
            className={`absolute h-full max-w-[160vh] object-contain ${!showFirstImage ? 'z-10' : 'z-0'}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: showFirstImage ? 0 : 1 }}
            transition={{ duration: 2 }}
          />
        </div>
      </div>

      {/* Ajuste de margen para texto inferior */}
      <div className='my-4 w-full space-y-4 bg-black text-left text-white sm:my-8 sm:p-4 lg:mt-12'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_473')}
        </h1>
      </div>
    </>
  )
}
