import login from '@proxies/user/login'
export default async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
  if (!('elements' in e.target)) return
  const elements = e.target.elements as Record<string, HTMLInputElement>
  if (!('email' in elements) || !('password' in elements)) return
  const { email, password } = elements
  if (!email || !password) return
  try {
    await login({ correo: email.value, password: password.value, remember: true })
  } catch (e: unknown) {
    console.error('Error', e)
    return false
  }
  return true
}
