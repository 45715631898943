import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../store'
import { useWebsocketContext } from './Context'
import CreativeArea from './CreativeArea'
import Footer from './Footer'
import Navbar from './Navbar'
import ProjectsSection from './ProjectsSection'
import { ScrollProgressBar } from './ScrollProgressBar'

export default function LoggedUser() {
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const navigate = useNavigate()
  const { creditosActuales } = useWebsocketContext()

  useEffect(() => {
    if (!token) {
      navigate('/')
    }
  }, [token, navigate])

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <CreativeArea />
      <ProjectsSection />
      <Footer />
    </div>
  )
}
