import * as signalR from '@microsoft/signalr'
import { createContext, useContext, useEffect, useState, type ReactNode } from 'react'
import { store } from '../store'
type WebsocketContextType = {
  connection: signalR.HubConnection | null
  creditosActuales: number
  planDescripcion: string
  tipoPlan: number
  idTrabajosIa: number | null
  isPaymentAccepted: boolean | null
  setIsPaymentAccepted: React.Dispatch<React.SetStateAction<boolean | null>> // Export setIsPaymentAccepted
  validarPromocion: (promocion: string) => Promise<string>
  isSubscriptionCancelled: boolean | null
  setIsSubscriptionCancelled: React.Dispatch<React.SetStateAction<boolean | null>>
}

export const WebsocketContext = createContext<WebsocketContextType | undefined>(undefined)
type PropChildren = {
  children: ReactNode
}

export default function WebsocketProvider({ children }: PropChildren) {
  const token = store((s) => s.userToken)
  // console.log('que token tenemos para la conexión f2i', token)
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null)
  const [creditosActuales, setCreditosActuales] = useState(0)
  const [planDescripcion, setPlanDescripcion] = useState('')
  const [tipoPlan, setTipoPlan] = useState(0)
  const [idTrabajosIa, setIdTrabajosIa] = useState<number | null>(null)
  const [isPaymentAccepted, setIsPaymentAccepted] = useState<boolean | null>(null)
  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState<boolean | null>(null)

  const validarPromocion = async (promocion: string): Promise<string> => {
    if (!connection) {
      throw new Error('No hay conexión a SignalR.')
    }

    try {
      const result = await connection.invoke<string>('ValidarPromocion', promocion)
      // console.log('Promoción válida:', result)
      return result
    } catch (error) {
      console.error('Error validando la promoción:', error)
      throw error
    }
  }

  useEffect(() => {
    if (token) {
      // console.log('Creating SignalR connection with token:', token)
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl('https://api.for2inspiration.ai/hub/buda', {
          headers: {
            Authorization: `buda ${token}`,
          },
          transport: signalR.HttpTransportType.LongPolling,
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build()
      setConnection(newConnection)
    }
  }, [token])
  useEffect(() => {
    if (connection) {
      const startConnection = async () => {
        try {
          await connection.start()
          console.log('SignalR Connected.')
        } catch (err) {
          console.log('Error establishing connection: ', err)
          setTimeout(startConnection, 5000)
        }
      }
      connection.onclose(async () => {
        await startConnection()
      })

      connection.on('NotificacionCreditosActuales', (message: any) => {
        // console.log('NotificacionCreditosActuales:', message)
        setCreditosActuales(message ?? 0)
      })

      connection.on('NotificacionPlanActual', (plan: any) => {
        console.log('NotificacionPlanActual:', plan)
        if (plan) {
          setPlanDescripcion(plan.descripcion)
          setTipoPlan(plan.idPlanCreditos)
          console.log(
            `Plan actual: Id = ${plan.idPlanCreditos} - Descripcion = ${plan.descripcion} - Creditos = ${plan.creditos} - PrecioEur = ${plan.precioEur}`,
          )
        }
      })

      connection.on('NotificacionTrabajosIA', (data: { idTrabajosIa: number; urls: string[] }) => {
        // console.log('NotificacionTrabajosIA:', data)
        if (data && Array.isArray(data.urls)) {
          setIdTrabajosIa(data.idTrabajosIa)
          data.urls.forEach((url) => console.log(`Imagen generada ${url}`))
        } else {
          console.error('Error: El formato de datos no es el esperado', data)
        }
      })

      connection.on('NotificacionPayment', (isAccepted: boolean) => {
        // console.log('NotificacionPayment:', isAccepted)
        setIsPaymentAccepted(isAccepted)
      })

      connection.on('notificacionsuscripcioncancelada', (data: boolean) => {
        console.log(
          'NotificacionSuscripcionCancelada Info del usuario en connection SignalR:',
          data,
        )
        setIsSubscriptionCancelled(data)
      })
      startConnection()

      return () => {
        connection.stop()
      }
    }
  }, [connection])
  return (
    <WebsocketContext.Provider
      value={{
        connection,
        creditosActuales,
        planDescripcion,
        tipoPlan,
        idTrabajosIa,
        isPaymentAccepted,
        setIsPaymentAccepted,
        isSubscriptionCancelled,
        setIsSubscriptionCancelled,
        validarPromocion,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  )
}
export const useWebsocketContext = () => {
  const context = useContext(WebsocketContext)
  if (context === undefined) {
    throw new Error('useWebsocketContext must be used within a WebsocketProvider')
  }
  return context
}
