import t from '../store/languageActions'

export default function ThreeSections() {
  return (
    <div className='w-full'>
      <div className='border border-gray-300'>
        <div className='flex flex-col divide-y divide-gray-300 lg:flex-row lg:divide-x lg:divide-y-0'>
          <div className='flex-1 p-14'>
            <h2 className='text-xl font-bold text-gray-900'>{t('MSG_17')}</h2>
            <p className='mt-4 text-gray-700'>{t('MSG_62')}</p>
          </div>
          <div className='flex-1 p-14'>
            <h2 className='text-xl font-bold text-gray-900'>{t('MSG_19')}</h2>
            <p className='mt-4 text-gray-700'>{t('MSG_20')}</p>
          </div>
          <div className='flex-1 bg-main p-14'>
            <h2 className='text-xl font-bold text-white'>{t('MSG_21')}</h2>
            <p className='mt-4 text-white'>{t('MSG_22')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
