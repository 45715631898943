import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import t from '../../store/languageActions'
import AlertInfo from '../AlertInfo'
import { useWebsocketContext } from '../Context'
import Footer from '../Footer'
import ImageOptions from '../ImageOptions'
import Navbar from '../Navbar'
import SelectionStyle from '../SelectionStyle'
import getSelectionsFromText from '../utils/getSelectionsFromText'
import estrella from '@assets/estrella.png'
import type { LocationState } from '@components/inspiration/InspirationStyles2Page'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function RenderingStyles2Page() {
  const { creditosActuales } = useWebsocketContext()
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as LocationState
  const {
    texto = '',
    respuestas = [],
    _concatenatedSelections = '',
    concatIds = [],
    img,
    imageFile,
  } = state || {}

  const preguntasEspeciales = respuestas.filter((p) => p.especial) || []
  const [selections, setSelections] = useState<{ [key: string]: string }>(() =>
    getSelectionsFromText(texto, _concatenatedSelections),
  )
  const [ids, setIds] = useState<number[]>(concatIds)

  useLayoutEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])

  useEffect(() => {
    const initialSelections = getSelectionsFromText(texto, _concatenatedSelections)
    setSelections(initialSelections)
  }, [texto, _concatenatedSelections])

  function handleSelect(title: string, selectedItemTitle: string, id: number) {
    setSelections((prevSelections) => {
      const newSelections = { ...prevSelections }
      newSelections[title] = selectedItemTitle

      if (selectedItemTitle === 'IA') {
        delete newSelections[title]
      }

      return newSelections
    })
    setIds((prev) => {
      if (!prev.includes(id)) {
        return [...prev, id]
      }
      return prev
    })
  }

  const concatenatedSelections = Object.entries(selections)
    .map(([key, value]) => (value === 'IA' ? '' : `${key}: ${value}`))
    .filter(Boolean)
    .join(', ')

  function handleButtonClick() {
    navigate('/rendering/styles2/generate', {
      state: {
        concatenatedSelections,
        texto,
        respuestas,
        concatIds: ids,
        img,
        imageFile,
      },
    })
    window.scrollTo(0, 0)
  }

  function handleBackClick() {
    if (!Object.keys(selections).length) {
      window.scrollTo(0, 0)
      return navigate('/rendering/styles', {
        state: {
          texto: texto.replace(
            _concatenatedSelections ? new RegExp(`[, ]{0,1} ${_concatenatedSelections}`) : '',
            '',
          ),
          respuestas,
          concatIds: ids,
          img,
          imageFile,
        },
      })
    }
    setSelections((prev) => {
      const latest = Object.entries(prev).at(-1)!
      const newObj = { ...prev }
      delete newObj[latest[0]]
      return newObj
    })
    setIds((prev) => prev.slice(0, -1))
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageOptions
        title={t('MSG_456')}
        buttonText={t('MSG_37')}
        imgSrc={img}
        onButtonClick={handleButtonClick}
        setImageFile={() => {}}
      />
      <AlertInfo
        message={`${texto.replace(_concatenatedSelections ? new RegExp(`[, ]{0,1} ${_concatenatedSelections}`) : '', '')} ${concatenatedSelections ? `, ${concatenatedSelections}` : ''}`}
        navigateTo='/rendering/styles'
        state={{ respuestas, texto, img, imageFile }}
        fn={handleBackClick}
      />
      <h1 className='my-8 text-center text-2xl text-main'>{t('MSG_110')}</h1>
      <h2 className='-mt-8 text-center font-chopin'></h2>
      <p className='text-center font-chopin'> {t('MSG_139')}</p>
      <h2 className='text-center font-chopin'>
        {t('MSG_113')}
        <span className='font-creato-medium font-bold'> {t('MSG_114')}</span> {t('MSG_115')}
      </h2>
      {preguntasEspeciales.map((pregunta) => (
        <SelectionStyle
          key={pregunta.idPreguntasIa}
          title={pregunta.texto}
          items={pregunta.respuestas.map((respuesta) => ({
            id: respuesta.idRespuestasIa,
            title: respuesta.texto,
            imgSrc: respuesta.imagenUrl1 || 'https://via.placeholder.com/300x200',
            acceso: pregunta.acceso,
          }))}
          onSelect={handleSelect}
          selections={selections}
        />
      ))}
      <div className='container mx-auto flex flex-1 justify-end py-4'>
        <button
          className='flex w-[280px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800'
          onClick={handleButtonClick}
        >
          {t('MSG_37')}
          <div className='ml-4 flex h-6 w-10 items-center rounded-lg border-[1.5px] border-white bg-main'>
            <img
              src={estrella}
              alt='Star image'
              className='ml-1 h-4 w-4'
            />
            <p className='ml-1 text-white'>1</p>
          </div>
        </button>
      </div>
      <Footer />
    </div>
  )
}
