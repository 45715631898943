import React from 'react'
import Modal from 'react-modal'
import logoTwitter from '@assets/twitter.png'
import facebook from '@assets/facebook.png'
import linkedind from '@assets/linkedin.png'
import whatsapp from '@assets/whatsapp.png'
import instagram from '@assets/instagram.png'
import t from '../store/languageActions'
import tiktok from '@assets/tiktok.png'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share'

Modal.setAppElement('#root')

type ShareModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  imgSrc: string
}

const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onRequestClose, imgSrc }) => {
  const shareUrl =
    imgSrc || 'https://i0.wp.com/accounter.co/cdn/2017/05/errores_emprender.jpg?fit=640%2C640&ssl=1'

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Share Modal'
      className='relative mx-auto my-8 max-h-[60vh] w-full max-w-md overflow-y-auto rounded-md bg-white px-16 py-12 text-black'
      overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center'
    >
      <div className='flex flex-col'>
        <h2 className='mb-6 font-creato-bold text-xl font-bold'> {t('MSG_423')} </h2>
        <div className='flex space-x-4'>
          <FacebookShareButton url={shareUrl}>
            <img
              src={facebook}
              alt='Logo Facebook'
              style={{ width: 36, height: 36, borderRadius: '50%' }}
            />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl}>
            <img
              src={logoTwitter}
              alt='Logo Twitter'
              style={{ width: 36, height: 36, borderRadius: '50%' }}
            />
          </TwitterShareButton>
          <LinkedinShareButton url={shareUrl}>
            <img
              src={linkedind}
              alt='Logo LinkedIn'
              style={{ width: 36, height: 36, borderRadius: '50%' }}
            />
          </LinkedinShareButton>
          <WhatsappShareButton url={shareUrl}>
            <img
              src={whatsapp}
              alt='Logo WhatsApp'
              style={{ width: 36, height: 36, borderRadius: '50%' }}
            />
          </WhatsappShareButton>
          <button
            onClick={() => window.open(`https://www.instagram.com/?url=${shareUrl}`, '_blank')}
            style={{ border: 'none', background: 'none' }}
          >
            <img
              src={instagram}
              alt='Logo Instagram'
              style={{ width: 36, height: 36, borderRadius: '50%' }}
            />
          </button>
          <button
            onClick={() => window.open(`https://www.tiktok.com/upload?url=${shareUrl}`, '_blank')}
            style={{ border: 'none', background: 'none' }}
          >
            <img
              src={tiktok}
              alt='Logo TikTok'
              style={{ width: 36, height: 36, borderRadius: '50%' }}
            />
          </button>
        </div>
        <div className='mt-8 flex justify-end'>
          <button
            className='transform rounded-md bg-main px-4 py-2 text-white shadow-sm transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-main hover:text-white focus:scale-105 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 active:scale-95 active:bg-main active:text-white'
            onClick={onRequestClose}
          >
            {t('MSG_369')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ShareModal
