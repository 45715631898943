import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { FaPhotoVideo, FaFilm, FaImage, FaPlayCircle } from 'react-icons/fa'
import Videos, { videos } from './Videos'
import Images, { images } from './Images'
import AnimatedImages, {
  imageGroups,
  imageGroups2,
  imageGroups3,
  imageGroups4,
  imageGroups5,
  imageGroups6,
} from './AnimatedImages'
import Navbar from '@components/Navbar'
import Footer from '@components/Footer'
import { ScrollProgressBar } from '@components/ScrollProgressBar'
import { shuffleArray } from '@components/utils/shufleArray'
import { store } from '../../store'
import { useWebsocketContext } from '@components/Context'

type AnimationType = 'fade' | 'zoomBlur' | 'zoom' | 'morph'

type SelectedItemType = {
  type: string
  src?: string
  animationType?: AnimationType
}

export default function GalleryPage() {
  const { creditosActuales } = useWebsocketContext()
  const [activeTab, setActiveTab] = useState('todos')
  const [selectedItem, setSelectedItem] = useState<SelectedItemType | null>(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [shuffledContent, setShuffledContent] = useState<any[]>([])

  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  const tabs = [
    { label: t('MSG_491'), value: 'todos', icon: <FaPhotoVideo /> },
    { label: t('MSG_492'), value: 'videos', icon: <FaFilm /> },
    { label: t('MSG_490'), value: 'imagenes', icon: <FaImage /> },
    { label: t('MSG_493'), value: 'animaciones', icon: <FaPlayCircle /> },
  ]

  useEffect(() => {
    if (selectedItem) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [selectedItem])

  useEffect(() => {
    const combinedContent = [
      ...videos.map((video) => ({ type: 'video', content: video })),
      ...images.map((image) => ({ type: 'image', content: image })),
      ...imageGroups.map((group, index) => ({
        type: 'animated',
        content: group,
        index,
        animationType: 'fade',
      })),
      ...imageGroups2.map((group, index) => ({
        type: 'animated',
        content: group,
        index,
        animationType: 'zoomBlur',
      })),
      ...imageGroups3.map((group, index) => ({
        type: 'animated',
        content: group,
        index,
        animationType: 'zoom',
      })),
      ...imageGroups4.map((group, index) => ({
        type: 'animated',
        content: group,
        index,
        animationType: 'fade',
      })),
      ...imageGroups5.map((group, index) => ({
        type: 'animated',
        content: group,
        index,
        animationType: 'morph',
      })),
      ...imageGroups6.map((group, index) => ({
        type: 'animated',
        content: group,
        index,
        animationType: 'zoomBlur',
      })),
    ]

    const shuffled = shuffleArray(combinedContent)
    setShuffledContent(shuffled)
  }, [])

  function closeModal() {
    setSelectedItem(null)
    setSelectedGroup(null)
  }

  function openModal(item: any) {
    setSelectedItem(item)
  }

  function openAnimatedModal(group: any, animationType: AnimationType, index: number) {
    setSelectedGroup(group)
    setSelectedItem({ type: 'animated', animationType })
    setCurrentImageIndex(index)
  }

  return (
    <>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <div className='h-20 w-full bg-black'></div>
      <div className='my-6 flex justify-center space-x-8'>
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`flex flex-col items-center justify-center space-y-1 font-creato-light text-lg ${
              activeTab === tab.value
                ? 'border-b-2 border-main font-creato-medium text-main'
                : 'text-black'
            }`}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.icon}
            <span>{tab.label}</span>
          </button>
        ))}
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className='grid grid-cols-1 gap-2 px-4 sm:grid-cols-2 lg:grid-cols-3 lg:px-52'
      >
        {activeTab === 'todos' && (
          <>
            {shuffledContent.map((item, index) => {
              if (item.type === 'video') {
                return (
                  <div
                    key={index}
                    className='relative h-[450px] w-full cursor-pointer bg-gray-200'
                    onClick={() => openModal({ type: 'video', src: item.content.src })}
                  >
                    <div className='absolute inset-0 z-10 cursor-pointer bg-transparent'></div>
                    <iframe
                      className='h-full w-full'
                      loading='lazy'
                      src={!selectedItem ? item.content.src : item.content.src.split('?')[0]}
                      title={`YouTube video ${item.content.id}`}
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    ></iframe>
                  </div>
                )
              } else if (item.type === 'image') {
                return (
                  <div
                    key={index}
                    className='h-[450px] w-full cursor-pointer bg-gray-200'
                    onClick={() => openModal({ type: 'image', src: item.content.src })}
                  >
                    <img
                      src={item.content.src}
                      loading='lazy'
                      alt={`gallery-photo-${item.content.id}`}
                      className='h-full w-full object-cover'
                    />
                  </div>
                )
              } else if (item.type === 'animated') {
                return (
                  <div
                    key={index}
                    className='relative h-[450px] w-full cursor-pointer bg-gray-200'
                    onClick={() => openAnimatedModal(item.content, item.animationType, index)}
                  >
                    {!selectedItem && (
                      <AnimatedImages
                        onClick={() => openAnimatedModal(item.content, item.animationType, index)}
                        group={item.content}
                        isModal={false}
                        animationType={item.animationType}
                      />
                    )}
                  </div>
                )
              }
            })}
          </>
        )}

        {activeTab === 'videos' && <Videos onClick={openModal} />}
        {activeTab === 'imagenes' && <Images onClick={openModal} />}
        {activeTab === 'animaciones' && (
          <>
            {shuffledContent
              .filter((item) => item.type === 'animated')
              .map((item, index) => (
                <div
                  key={index}
                  className='relative h-[450px] w-full cursor-pointer bg-gray-200'
                  onClick={() => openAnimatedModal(item.content, item.animationType, index)}
                >
                  {!selectedItem && (
                    <AnimatedImages
                      onClick={() => openAnimatedModal(item.content, item.animationType, index)}
                      group={item.content}
                      isModal={false}
                      animationType={item.animationType}
                    />
                  )}
                </div>
              ))}
          </>
        )}
      </motion.div>

      {/* Modal */}
      {selectedItem && (
        <div
          className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75'
          onClick={closeModal}
        >
          <div
            className='relative mx-auto flex max-h-[90vh] max-w-[90vw] items-center justify-center bg-black p-4'
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className='absolute right-3 top-1 z-10 text-4xl text-gray-400 hover:text-gray-200'
              onClick={closeModal}
            >
              &times;
            </button>

            {selectedItem.type === 'image' && (
              <div className='flex max-h-[90vh] max-w-[90vw] items-center justify-center p-14'>
                <img
                  src={selectedItem.src}
                  alt='Selected'
                  className='max-h-[100vh] max-w-full object-contain px-6p'
                />
              </div>
            )}

            {selectedItem.type === 'video' && (
              <iframe
                src={selectedItem.src}
                title='Selected Video'
                className='h-[77vh] w-[80vw]'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            )}

            {selectedItem.type === 'animated' && selectedGroup && (
              <AnimatedImages
                isModal={true}
                onClick={() => {}}
                group={selectedGroup}
                animationType={selectedItem?.animationType ?? 'fade'}
                currentIndex={currentImageIndex}
              />
            )}
          </div>
        </div>
      )}
      <button
        className='fixed bottom-10 right-10 rounded-full bg-black p-3 text-black shadow-lg transition-colors hover:bg-main'
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        {/* SVG de flecha hacia arriba con color negro */}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='white'
          width='24px'
          height='24px'
        >
          <path d='M12 2L6 8h4v10h4V8h4l-6-6z' />
        </svg>
      </button>

      <Footer />
    </>
  )
}
