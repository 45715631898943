import { Link, useNavigate } from 'react-router-dom'
import logo from '../assets/logo.png'
import handleRegister from './actions/handleRegister'
import { useState } from 'react'
import { store } from '../store'
import TermsModal from './TermsModal'
import SuccessModal from './SuccessModal'

export default function Register() {
  const redirect = useNavigate()
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  const [isChecked, setIsChecked] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev)
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const openSuccessModal = () => {
    setSuccessModalIsOpen(true)
  }

  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isChecked) {
      setErrorMessage('')
      const success = await handleRegister(e)
      if (success) {
        openSuccessModal()
      }
    } else {
      setErrorMessage(t('MSG_296'))
    }
  }

  return (
    <div className='flex min-h-screen flex-col items-center justify-center bg-black px-4'>
      <div className='mb-4 flex justify-center'>
        <img
          src={logo}
          onClick={() => redirect('/')}
          alt='Logo For2Inspiration'
          className='h-16 cursor-pointer'
        />
      </div>
      <div className='relative h-[600px] w-full max-w-sm space-y-6 rounded-md border border-transparent bg-gray-950 p-8 text-white shadow-md'>
        <div
          className='absolute inset-0 animate-border-snake rounded-md bg-gradient-to-r from-yellow-700 via-gray-600 to-gray-700 p-0.5'
          style={{
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            WebkitMaskComposite: 'xor',
            backgroundSize: '200% 200%',
          }}
        ></div>
        <button
          onClick={() => redirect('/')}
          className='absolute -top-4 right-3 text-2xl text-gray-400 hover:text-gray-200'
        >
          &times;
        </button>
        <h2 className='text-left text-3xl font-bold text-gray-200'>{t('MSG_81')} </h2>
        <form
          className='relative z-10 space-y-6'
          onSubmit={handleSubmit}
        >
          <div className='-mb-2 -mt-4 h-6 text-center text-main'>
            {errorMessage && <div>{errorMessage}</div>}
          </div>
          <div>
            <label
              htmlFor='name'
              className='block text-left text-sm font-medium text-gray-300'
            >
              {t('MSG_78')}
            </label>
            <input
              id='name'
              name='name'
              type='text'
              autoComplete='name'
              required
              className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
            />
          </div>
          <div>
            <label
              htmlFor='email'
              className='block text-left text-sm font-medium text-gray-300'
            >
              {t('MSG_79')}
            </label>
            <input
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              required
              className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
            />
          </div>
          <div>
            <label
              htmlFor='password'
              className='block text-left text-sm font-medium text-gray-300'
            >
              {t('MSG_80')}
            </label>
            <input
              id='password'
              name='password'
              type='password'
              autoComplete='current-password'
              required
              className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
            />
          </div>
          <div>
            <button
              type='submit'
              className='w-full transform rounded-md bg-white px-4 py-2 text-main shadow-sm transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-main hover:text-white focus:scale-105 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 active:scale-95 active:bg-main active:text-white'
            >
              {t('MSG_81')}
            </button>
          </div>
          <div className='text-sm'>
            <Link
              to='/login'
              className='mt-2 block text-left text-gray-400 hover:text-main'
            >
              {t('MSG_221')}
              <span className='font-creato-bold'> {t('MSG_222')}</span>
            </Link>
          </div>

          <div className='flex items-start text-sm'>
            <input
              id='default-checkbox'
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
              className='-mt-3.5 h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-yellow-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-yellow-600'
            />
            <span className='-mt-4 ml-2'>
              {t('MSG_223')}{' '}
              <span
                className='cursor-pointer text-main hover:underline'
                onClick={openModal}
              >
                {t('MSG_224')}
              </span>
            </span>
          </div>
        </form>
      </div>
      <TermsModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
      <SuccessModal
        isOpen={successModalIsOpen}
        onRequestClose={closeSuccessModal}
      />
    </div>
  )
}
