import { useState, useRef, useEffect } from 'react'

type CustomLanguageSelectProps = {
  idiomas: Array<{ idIdiomas: number; idioma1: string }>
  formData: { language?: string }
  setFormData: React.Dispatch<React.SetStateAction<{ language?: string }>>
  t: (key: string) => string
}

export default function CustomLanguageSelect({
  idiomas,
  formData,
  setFormData,
  t,
}: CustomLanguageSelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(formData.language || '')
  const selectRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (formData.language) {
      setSelectedLanguage(formData.language)
    } else {
      setSelectedLanguage('1')
    }
  }, [formData.language])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectRef])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleLanguageSelect = (idIdioma: number) => {
    setSelectedLanguage(idIdioma.toString())
    setFormData((prevData) => ({
      ...prevData,
      language: idIdioma.toString(),
    }))
    setIsOpen(false)
  }

  return (
    <div
      className='relative'
      ref={selectRef}
    >
      <label
        htmlFor='language'
        className='block text-left text-sm font-medium text-gray-300'
      >
        {t('MSG_434')}
      </label>
      <button
        type='button'
        onClick={toggleMenu}
        className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 text-white placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
      >
        {selectedLanguage
          ? idiomas.find((idioma) => idioma.idIdiomas === parseInt(selectedLanguage))?.idioma1 ||
            t('MSG_435')
          : t('MSG_435')}
      </button>

      {isOpen && (
        <div className='absolute z-10 -mt-10 w-full rounded-md bg-gray-700 shadow-lg'>
          <ul className='py-1'>
            {idiomas.map((idioma) => (
              <li
                key={idioma.idIdiomas}
                onClick={() => handleLanguageSelect(idioma.idIdiomas)}
                className='block cursor-pointer px-4 py-2 text-sm text-white hover:bg-gray-500'
              >
                {idioma.idioma1}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
