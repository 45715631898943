import { useEffect, useState } from 'react'
import logo from '@assets/logo.png'
import estrella from '@assets/estrella.png'
import Modal from 'react-modal'
import { cancelSuscription } from '@proxies/user/cancelSuscription'
import { store } from '../../store'
import { useWebsocketContext } from '@components/Context'
export default function PlanActive() {
  const texto = store((s) => s.translations)
  function t(t: string) {
    return texto[t] ?? t
  }
  const { creditosActuales, tipoPlan, validarPromocion, isSubscriptionCancelled } =
    useWebsocketContext()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState<string | null>(null)
  const [promoCode, setPromoCode] = useState('')

  // function handleResetPromo() {
  //   setPromoCode('')
  //   setMessage(null)
  // }

  function getPlanText(tipoPlan: number): string {
    switch (tipoPlan) {
      case 1:
        return t('MSG_39')
      case 2:
        return t('MSG_42')
      case 3:
        return t('MSG_43')
      case 4:
        return t('MSG_164')
      default:
        return ' '
    }
  }
  function getDescriptionText(tipoPlan: number): string {
    switch (tipoPlan) {
      case 1:
        return t('MSG_72')
      case 2:
        return t('MSG_73')
      case 3:
        return t('MSG_74')
      case 4:
        return t('MSG_47')
      case 5:
        return t('MSG_46')
      default:
        return ' '
    }
  }
  async function handleValidatePromo() {
    try {
      const result = await validarPromocion(promoCode)
      setMessage(result)
    } catch (error) {
      console.error('Error al validar la promoción:', error)
      setMessage(t('MSG_454'))
    }
  }
  function handleCancelSubscription() {
    setIsModalOpen(true)
  }

  async function handleConfirmCancel() {
    console.log('Botón clickeado')
    setMessage(null)
    try {
      const result = await cancelSuscription(password)
      console.log('Cancelación exitosa:', result)
      setMessage('Cancelado plan correctamente')
    } catch (error: any) {
      setMessage(error.message)
    }
  }
  function handleCloseModal() {
    setIsModalOpen(false)
    setPassword('')
    setMessage(null)
  }
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isModalOpen])
  return (
    <div className='flex h-[595px] flex-col items-center justify-center bg-black py-16 text-white'>
      <div className='mb-8 w-full max-w-5xl text-start'>
        <h1 className='mt-14 font-montserrat-bold text-4xl font-bold text-white'>{t('MSG_100')}</h1>
      </div>
      <div
        className={`relative flex ${tipoPlan === 4 || tipoPlan === 5 ? 'h-80' : 'h-72'} w-72 flex-col items-center rounded-md border border-transparent bg-white p-4 text-black shadow-lg`}
      >
        <div
          className='absolute inset-0 animate-border-snake rounded-md bg-gradient-to-r from-yellow-400 via-gray-500 to-yellow-600 p-0.5'
          style={{
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            WebkitMaskComposite: 'xor',
            backgroundSize: '200% 200%',
          }}
        ></div>
        <h3 className='-mt-1 mb-3 font-creato-bold text-xl'>{t('MSG_151')} </h3>
        <p className='text-md -mt-1 font-creato-bold text-main'>{getDescriptionText(tipoPlan)} </p>
        <p className='text-md'>{getPlanText(tipoPlan)} </p>
        <p className='mb-4 mt-1 text-sm text-gray-600'>{t('MSG_154')} </p>
        <button className='flex transform items-center justify-center rounded-md bg-main px-4 py-2 text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'>
          <img
            src={estrella}
            alt='Star image'
            className='mr-2 h-6 w-6'
          />
          <p className='text-white'>{creditosActuales}</p>
        </button>
        {/* Botón de suscripción si el tipo de plan es 4 o 5 */}
        {(tipoPlan === 4 || tipoPlan === 5) && (
          <button
            onClick={handleCancelSubscription}
            className='mt-3 flex transform rounded-md bg-main px-4 py-2 text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
          >
            <p className='text-white'>{t('MSG_421')} </p>
          </button>
        )}
        {isSubscriptionCancelled && (
          <div className='mb-4 mt-1 text-sm text-gray-600'>{t('MSG_446')}</div>
        )}

        {/* Input para el código promocional cuando no hay plan */}
        {((getPlanText(tipoPlan) === ' ' && getDescriptionText(tipoPlan) === ' ') ||
          tipoPlan === null ||
          tipoPlan === undefined ||
          creditosActuales === 0) && (
          <div className='z-50 mt-3 flex w-full items-center'>
            <input
              type='text'
              placeholder={t('MSG_451')}
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className='w-2/3 rounded-md border border-gray-300 p-2 text-sm focus:border-main focus:outline-none'
            />
            <button
              onClick={handleValidatePromo}
              className='z-50 ml-1 w-1/3 rounded-md border border-main bg-white px-2 py-2 text-sm text-main shadow-sm transition-transform duration-100 ease-in-out hover:scale-105 hover:bg-main hover:text-white focus:border-main focus:bg-main focus:text-white active:scale-95'
            >
              {t('MSG_452')}
            </button>
          </div>
        )}

        {/*Validación promo */}
        {/* {message && (
          <div className='z-50 mt-1.5 flex items-center'>
            <span className='text-sm text-gray-600'>{message} hola</span>
            <button
              onClick={handleResetPromo}
              className='ml-2 text-lg text-gray-600 hover:text-gray-700'
              title='Limpiar'
            >
              &times;
            </button>
          </div>
        )} */}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel='Confirmar Cancelación'
        className='relative mx-auto my-8 h-auto w-[95%] max-w-md overflow-y-auto rounded-md border border-gray-700 bg-gray-950 p-8 text-white shadow-md'
        overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center'
        style={{
          content: {
            minHeight: '280px',
            maxWidth: '380px',
          },
        }}
      >
        <button
          onClick={handleCloseModal}
          className='absolute right-3 top-1 text-2xl text-gray-400 hover:text-gray-200'
        >
          &times;
        </button>
        <img
          src={logo}
          onClick={handleCloseModal}
          alt='Logo For2Inspiration'
          className='-mt-2 h-12 cursor-pointer'
        />
        <div className='relative -mt-2 h-8 w-full'>
          {message && (
            <div className='absolute inset-0 flex items-center justify-center'>
              <p className='text-main'>{message}</p>
            </div>
          )}
        </div>
        <div className='mt-1'>
          <label
            htmlFor='password'
            className='block text-left text-sm font-medium text-gray-300'
          >
            {t('MSG_443')}
          </label>
          <input
            id='password'
            name='password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='mt-2 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
          />
        </div>
        <button
          onClick={handleConfirmCancel}
          className='mt-4 w-full transform rounded-md bg-main px-4 py-2 text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
        >
          {t('MSG_421')}
        </button>
      </Modal>
    </div>
  )
}
