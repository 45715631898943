import { useNavigate } from 'react-router-dom'
import logo from '../assets/logo.png'
import { useState, useEffect, useRef } from 'react'
import { store } from '../store'
import { getUserAct } from '@proxies/user/getUserAct'
import { modifyUserAct } from '@proxies/user/modifyUserAct'
import SaveModalModifyData from './SaveModalModifyData'
import CustomLanguageSelect from './CustomLanguageSelect'

type UserInfo = {
  name?: string
  correo?: string
  surname?: string
  company?: string
  cif?: string
  address?: string
  city?: string
  postalCode?: string
  country?: string
  phone?: string
  language?: string
  password?: string
  confirmPassword?: string
}

export default function SectionModifyData() {
  const redirect = useNavigate()
  const texto = store((s) => s.translations)
  const idiomas = store((s) => s.idiomas)

  function t(t: string) {
    return texto[t] ?? t
  }

  const [formData, setFormData] = useState<UserInfo>({
    name: '',
    correo: '',
    surname: '',
    company: '',
    cif: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
    phone: '',
    language: '',
  })

  const [isChecked, setIsChecked] = useState(false)
  const [message, setMessage] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const inputRefs = {
    correo: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    surname: useRef<HTMLInputElement>(null),
    company: useRef<HTMLInputElement>(null),
    cif: useRef<HTMLInputElement>(null),
    address: useRef<HTMLInputElement>(null),
    city: useRef<HTMLInputElement>(null),
    postalCode: useRef<HTMLInputElement>(null),
    country: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
    confirmPassword: useRef<HTMLInputElement>(null),
    language: useRef<HTMLSelectElement>(null),
    wantInvoice: useRef<HTMLInputElement>(null),
  }

  const fetchUserData = async () => {
    try {
      const data = await getUserAct()

      const idiomaSeleccionado = idiomas.find((idioma) => idioma.idIdiomas === data.idIdiomas)
      const idiomaId = idiomaSeleccionado ? idiomaSeleccionado.idIdiomas.toString() : ''

      setFormData({
        name: data.nombre || '',
        correo: data.correo || '',
        surname: data.apellidos || '',
        company: data.empresa || '',
        cif: data.cif || '',
        address: data.direccion || '',
        city: data.ciudad || '',
        postalCode: data.codigoPostal || '',
        country: data.pais || '',
        phone: data.telefono || '',
        language: idiomaId,
      })

      setIsChecked(data.requiereFactura ?? false)
    } catch (error) {
      console.error('Error al recuperar los datos del usuario:', error)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
    currentField: keyof typeof inputRefs,
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      const nextField = inputRefs[currentField]

      if (nextField?.current) {
        nextField.current.focus()
      }
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const selectedLanguage = formData.language ? parseInt(formData.language, 10) : 1

    const dataToSend = {
      nombre: formData.name,
      apellidos: formData.surname,
      direccion: formData.address,
      codigoPostal: formData.postalCode,
      telefono: formData.phone,
      idIdiomas: selectedLanguage,
      empresa: formData.company,
      cif: formData.cif,
      ciudad: formData.city,
      pais: formData.country,
      requiereFactura: isChecked,
      passwordNuevo: formData.password,
      passwordConfirmar: formData.confirmPassword,
    }

    try {
      const result = await modifyUserAct(dataToSend)
      console.log('Resultado de la API:', result)

      store.getState().setUserInfo({
        name: formData.name || '',
        email: formData.correo || '',
      })

      setMessage(t('MSG_449'))
      setIsModalOpen(true)

      setTimeout(() => {
        setIsModalOpen(false)
        redirect('/user')
      }, 3000)
    } catch (error: any) {
      setMessage(error.message || t('MSG_450'))
      setIsModalOpen(true)

      setTimeout(() => {
        setIsModalOpen(false)
        redirect('/user/modify-data')
      }, 3000)
    }
  }

  return (
    <div className='flex min-h-screen flex-col items-center justify-center bg-black'>
      <SaveModalModifyData
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        message={message}
      />

      <div className='mb-6 mt-24 flex justify-center'>
        <img
          src={logo}
          onClick={() => redirect('/')}
          alt='Logo For2Inspiration'
          className='h-16 cursor-pointer'
        />
      </div>

      <div className='relative mb-16 w-full max-w-lg rounded-md border border-transparent bg-gray-950 p-8 text-white shadow-md md:max-w-2xl lg:max-w-4xl'>
        <div
          className='absolute inset-0 animate-border-snake rounded-md bg-gradient-to-r from-yellow-700 via-gray-600 to-gray-700 p-0.5'
          style={{
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            WebkitMaskComposite: 'xor',
            backgroundSize: '200% 200%',
          }}
        ></div>
        <button
          onClick={() => redirect('/user')}
          className='absolute right-3 top-1.5 text-2xl text-gray-400 hover:text-gray-200'
        >
          &times;
        </button>

        <h2 className='text-left text-3xl font-bold text-gray-200'>{t('MSG_444')}</h2>

        <form
          className='relative z-10 space-y-6'
          onSubmit={handleSubmit}
        >
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
            {/* Email field */}
            <div className='md:col-span-2'>
              <label
                htmlFor='correo'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_79')}
              </label>
              <input
                id='correo'
                name='correo'
                type='email'
                value={formData.correo || ''}
                readOnly
                autoComplete='email'
                className='mt-1 w-full cursor-not-allowed rounded-md border border-main bg-gray-500 px-3 py-1 text-lg text-main placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main'
                onKeyDown={(e) => handleKeyDown(e, 'name')}
              />
            </div>

            {/* Name field */}
            <div>
              <label
                htmlFor='name'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_78')}
              </label>
              <input
                id='name'
                name='name'
                type='text'
                value={formData.name || ''}
                onChange={handleChange}
                autoComplete='name'
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'surname')}
              />
            </div>

            {/* Surname field */}
            <div>
              <label
                htmlFor='surname'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_425')}
              </label>
              <input
                id='surname'
                name='surname'
                type='text'
                value={formData.surname || ''}
                onChange={handleChange}
                autoComplete='family-name'
                ref={inputRefs.surname}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'company')}
              />
            </div>

            {/* Company field */}
            <div>
              <label
                htmlFor='company'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_426')}
              </label>
              <input
                id='company'
                name='company'
                type='text'
                value={formData.company || ''}
                onChange={handleChange}
                autoComplete='organization'
                ref={inputRefs.company}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'cif')}
              />
            </div>

            {/* CIF field */}
            <div>
              <label
                htmlFor='cif'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_427')}
              </label>
              <input
                id='cif'
                name='cif'
                type='text'
                value={formData.cif || ''}
                onChange={handleChange}
                autoComplete='off'
                ref={inputRefs.cif}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'address')}
              />
            </div>

            {/* Address field */}
            <div className='md:col-span-2'>
              <label
                htmlFor='address'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_428')}
              </label>
              <input
                id='address'
                name='address'
                type='text'
                value={formData.address || ''}
                onChange={handleChange}
                autoComplete='street-address'
                ref={inputRefs.address}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'city')}
              />
            </div>

            {/* City field */}
            <div>
              <label
                htmlFor='city'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_429')}
              </label>
              <input
                id='city'
                name='city'
                type='text'
                value={formData.city || ''}
                onChange={handleChange}
                autoComplete='address-level2'
                ref={inputRefs.city}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'postalCode')}
              />
            </div>

            {/* Postal Code field */}
            <div>
              <label
                htmlFor='postalCode'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_430')}
              </label>
              <input
                id='postalCode'
                name='postalCode'
                type='text'
                value={formData.postalCode || ''}
                onChange={handleChange}
                autoComplete='postal-code'
                ref={inputRefs.postalCode}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'country')}
              />
            </div>

            {/* Country field */}
            <div>
              <label
                htmlFor='country'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_431')}
              </label>
              <input
                id='country'
                name='country'
                type='text'
                value={formData.country || ''}
                onChange={handleChange}
                autoComplete='country-name'
                ref={inputRefs.country}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'phone')}
              />
            </div>

            {/* Phone field */}
            <div>
              <label
                htmlFor='phone'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_432')}
              </label>
              <input
                id='phone'
                name='phone'
                type='tel'
                value={formData.phone || ''}
                onChange={handleChange}
                autoComplete='tel'
                ref={inputRefs.phone}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'password')}
              />
            </div>

            {/* Password field */}
            <div>
              <label
                htmlFor='password'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_80')}
              </label>
              <input
                id='password'
                name='password'
                type='password'
                value={formData.password || ''}
                onChange={handleChange}
                autoComplete='new-password'
                ref={inputRefs.password}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'confirmPassword')}
              />
            </div>

            {/* Confirm Password field */}
            <div>
              <label
                htmlFor='confirmPassword'
                className='block text-left text-sm font-medium text-gray-300'
              >
                {t('MSG_433')}
              </label>
              <input
                id='confirmPassword'
                name='confirmPassword'
                type='password'
                value={formData.confirmPassword || ''}
                onChange={handleChange}
                autoComplete='new-password'
                ref={inputRefs.confirmPassword}
                className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
                onKeyDown={(e) => handleKeyDown(e, 'language')}
              />
            </div>

            {/* Language select */}
            <CustomLanguageSelect
              idiomas={idiomas}
              formData={formData}
              setFormData={setFormData}
              t={t}
            />
            {/* Checkbox */}
            <div className='flex items-center md:col-span-2'>
              <input
                id='wantInvoice'
                name='wantInvoice'
                type='checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
                className='h-4 w-4 rounded border-gray-600 bg-gray-500 text-main focus:ring-main'
                ref={inputRefs.wantInvoice}
                onKeyDown={(e) => handleKeyDown(e, 'wantInvoice')}
              />
              <label
                htmlFor='wantInvoice'
                className='ml-2 block text-sm font-medium text-gray-300'
              >
                {t('MSG_442')}
              </label>
            </div>

            {/* Submit button */}
            <div className='md:col-span-2'>
              <button
                type='submit'
                className='w-full transform rounded-md bg-white px-4 py-2 text-main shadow-sm transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-main hover:text-white focus:scale-105 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 active:scale-95 active:bg-main active:text-white'
              >
                {t('MSG_444')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
