import buscar from '@assets/buscar.png'
import favorito from '@assets/favorito.png'
import favoritoRojo from '@assets/favoritorojo.png'
import type { HistoryReturn } from '@proxies/user/getUserHistory'
import getUserHistory from '@proxies/user/getUserHistory'
import { useEffect, useState, useRef, useCallback } from 'react'
import ProjectCard from './ProjectCard'
import { store } from '../store'
import { useWebsocketContext } from './Context'

export default function ProjectsSection() {
  const { creditosActuales } = useWebsocketContext()
  const [isFavorited, setIsFavorited] = useState(false)
  const [projects, setProjects] = useState<HistoryReturn[]>([])
  const [visibleProjects, setVisibleProjects] = useState<HistoryReturn[]>([])
  const [search, setSearch] = useState('')
  const [batchSize] = useState(10)
  const [batchIndex, setBatchIndex] = useState(1)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const observer = useRef<IntersectionObserver | null>(null)

  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  const sortByDate = (projects: HistoryReturn[]) => {
    return projects.sort(
      (a, b) => new Date(b.fechaSolicitud).getTime() - new Date(a.fechaSolicitud).getTime(),
    )
  }

  useEffect(() => {
    const controller = new AbortController()

    async function fetchInitialProjects() {
      setLoading(true)
      setBatchIndex(1)
      setHasMore(true)

      try {
        const allProjects = await getUserHistory(false, '', controller.signal)
        const favoriteProjects = await getUserHistory(true, '', controller.signal)
        const combinedProjects = [...allProjects, ...favoriteProjects]
        const uniqueProjects = Array.from(new Set(combinedProjects.map((p) => p.idTrabUrlia))).map(
          (id) => combinedProjects.find((p) => p.idTrabUrlia === id)!,
        )

        const sortedProjects = sortByDate(uniqueProjects)
        setProjects(sortedProjects)
        setVisibleProjects(sortedProjects.slice(0, batchSize))
        setHasMore(sortedProjects.length > batchSize)
      } catch (error) {
        if (error instanceof Error && error.name !== 'AbortError') {
          console.error('Error fetching projects:', error)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchInitialProjects()

    return () => {
      controller.abort()
    }
  }, [batchSize])

  function toggleFavorito() {
    setIsFavorited((prevIsFavorited) => !prevIsFavorited)
  }

  useEffect(() => {
    const controller = new AbortController()

    async function fetchProjects() {
      setLoading(true)
      setBatchIndex(1)
      setHasMore(true)

      try {
        let projectsData = []
        if (isFavorited) {
          const favoriteProjects = await getUserHistory(true, search, controller.signal)
          projectsData = sortByDate(favoriteProjects)
        } else {
          const allProjects = await getUserHistory(false, search, controller.signal)
          const favoriteProjects = await getUserHistory(true, search, controller.signal)
          const combinedProjects = [...allProjects, ...favoriteProjects]
          const uniqueProjects = Array.from(
            new Set(combinedProjects.map((p) => p.idTrabUrlia)),
          ).map((id) => combinedProjects.find((p) => p.idTrabUrlia === id)!)

          projectsData = sortByDate(uniqueProjects)
        }

        const sanitizedProjects = projectsData.map((p) => ({
          ...p,
          favorito: p.favorito ?? false,
        }))

        setProjects(sanitizedProjects)
        setVisibleProjects(sanitizedProjects.slice(0, batchSize))
        setHasMore(sanitizedProjects.length > batchSize)
      } catch (error) {
        if (error instanceof Error && error.name !== 'AbortError') {
          console.error('Error fetching projects:', error)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchProjects()

    return () => {
      controller.abort()
    }
  }, [isFavorited, search, batchSize, creditosActuales])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const newProjects = await getUserHistory(false, search)
        const favoriteProjects = await getUserHistory(true, search)
        const combinedProjects = [...newProjects, ...favoriteProjects]
        const uniqueProjects = Array.from(new Set(combinedProjects.map((p) => p.idTrabUrlia))).map(
          (id) => combinedProjects.find((p) => p.idTrabUrlia === id)!,
        )

        if (uniqueProjects.length !== projects.length) {
          const sortedProjects = sortByDate(uniqueProjects)
          setProjects(sortedProjects)
          setVisibleProjects(sortedProjects.slice(0, batchSize))
          setHasMore(sortedProjects.length > batchSize)
        }
      } catch (error) {
        console.error('Error checking new projects:', error)
      }
    }, 30000) // Intervalo ajustado a 30 segundos para minimizar actualizaciones constantes

    return () => clearInterval(intervalId)
  }, [search, batchSize, projects])

  const loadMoreProjects = () => {
    if (!loading && hasMore) {
      setLoading(true)
      const nextBatchIndex = batchIndex + 1
      const start = batchSize * batchIndex
      const end = batchSize * nextBatchIndex

      const moreProjects = projects.slice(start, end)

      if (moreProjects.length > 0) {
        setVisibleProjects((prevProjects) => [...prevProjects, ...moreProjects])
        setBatchIndex(nextBatchIndex)
      }

      if (end >= projects.length) {
        setHasMore(false)
      }
      setLoading(false)
    }
  }

  const lastProjectRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || !hasMore) return

      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) {
          loadMoreProjects()
        }
      })

      if (node) observer.current.observe(node)
    },
    [loading, hasMore, batchIndex, projects],
  )

  const handleDeleteProject = (id: number) => {
    const updatedProjects = projects.filter((project) => project.idTrabUrlia !== id)
    setProjects(updatedProjects)
    setVisibleProjects(updatedProjects.slice(0, batchSize * batchIndex))
  }
  return (
    <div className='pt-14'>
      <div className='mb-9 text-center'>
        <div className='flex items-center'>
          <div className='relative flex-grow'>
            <div className='w-full border-t-[3px] border-black'></div>
            <div className='absolute top-1.5 w-full border-t border-main'></div>
          </div>
          <h2 className='mx-4 font-chopin text-3xl text-main'>{t('MSG_93')}</h2>
          <div className='relative flex-grow'>
            <div className='w-full border-t-[3px] border-black'></div>
            <div className='absolute top-1.5 w-full border-t border-main'></div>
          </div>
        </div>
        <p className='text-md font-chopin text-black'>
          {t('MSG_96')} {projects.length}
        </p>
      </div>
      <div className='container mx-auto px-4'>
        <div className='relative mb-4 flex w-[48.5%]'>
          <input
            type='text'
            className='w-full rounded-lg border px-4 py-1 pl-12'
            placeholder=''
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            src={buscar}
            alt='Image magnifying glass search'
            loading='lazy'
            className='absolute left-3 top-1/2 h-6 w-6 -translate-y-1/2 transform'
          />
          <button
            className='text-black hover:text-gray-600'
            onClick={toggleFavorito}
          >
            <img
              src={isFavorited ? favoritoRojo : favorito}
              alt='Heart image for favorites'
              className='ml-2 h-5 w-6'
            />
          </button>
        </div>

        <div className='-mx-4 mb-32 flex flex-wrap'>
          {visibleProjects.map((project, index) => {
            if (index === visibleProjects.length - 1) {
              return (
                <div
                  ref={lastProjectRef}
                  key={project.idTrabUrlia}
                  className='mb-8 w-full px-4 font-chopin md:w-1/2'
                >
                  <ProjectCard
                    index={index}
                    id={project.idTrabUrlia}
                    title={project.tituloProyecto || ''}
                    date={project.fechaSolicitud}
                    description={project.descripcionProyecto || ''}
                    imgSrc={project.imagenUrl}
                    favourite={project.favorito ?? false}
                    tipo={project.tipoTrabajo}
                    onDelete={handleDeleteProject}
                    textoDelFront={project.textoDelFront ?? ''}
                    inputImagenUrl={project.inputImagenUrl || ''}
                  />
                </div>
              )
            } else {
              return (
                <div
                  key={project.idTrabUrlia}
                  className='mb-8 w-full px-4 font-chopin md:w-1/2'
                >
                  <ProjectCard
                    index={index}
                    id={project.idTrabUrlia}
                    title={project.tituloProyecto || ''}
                    date={project.fechaSolicitud}
                    description={project.descripcionProyecto || ''}
                    imgSrc={project.imagenUrl}
                    favourite={project.favorito ?? false}
                    tipo={project.tipoTrabajo}
                    onDelete={handleDeleteProject}
                    textoDelFront={project.textoDelFront ?? ''}
                    inputImagenUrl={project.inputImagenUrl || ''}
                  />
                </div>
              )
            }
          })}
        </div>
        {loading && <p className='text-md font-chopin text-black'> {t('MSG_448')}</p>}
      </div>
    </div>
  )
}
