import t from '../../store/languageActions'
import imgCarousel1 from '@assets/Personalization/carrousel/image-2024-10-02T121827.814.png'
import imgCarousel2 from '@assets/Personalization/carrousel/image-2024-10-02T121914.455.png'
import imgCarousel3 from '@assets/Personalization/carrousel/image-2024-10-02T122256.287.png'
import imgCarousel4 from '@assets/Personalization/carrousel/image-2024-10-02T123941.209.png'
import imgCarousel5 from '@assets/Personalization/carrousel/image-2024-10-17T151400.398.png'
import imgCarousel6 from '@assets/Personalization/carrousel/image-2024-10-17T151417.043.png'
import imgCarousel7 from '@assets/Personalization/carrousel/image-2024-10-17T151702.899.png'
import imgCarousel8 from '@assets/Personalization/carrousel/image-2024-10-17T151704.543.png'
import imgCarousel9 from '@assets/Personalization/carrousel/image-2024-10-17T151928.769.png'
import imgCarousel10 from '@assets/Personalization/carrousel/image-2024-10-17T152144.557.png'
import imgCarousel11 from '@assets/Personalization/carrousel/image-2024-10-17T152335.378.png'
import imgCarousel12 from '@assets/Personalization/carrousel/image-2024-10-17T152421.193.png'
import imgCarousel13 from '@assets/Personalization/carrousel/image-2024-10-17T152537.691.png'
import imgCarousel14 from '@assets/Personalization/carrousel/image-2024-10-17T152801.243.png'
import imgCarousel15 from '@assets/Personalization/carrousel/image-2024-10-17T152957.240.png'
import imgCarousel16 from '@assets/Personalization/carrousel/image-2024-10-17T153003.669.png'
import imgCarousel17 from '@assets/Personalization/carrousel/image-2024-10-17T153109.645.png'
import imgCarousel18 from '@assets/Personalization/carrousel/image-2024-10-17T153152.330.png'
import imgCarousel19 from '@assets/Personalization/carrousel/image-2024-10-17T153327.358.png'
import imgCarousel20 from '@assets/Personalization/carrousel/image-2024-10-17T153338.636.png'
import imgCarousel21 from '@assets/Personalization/carrousel/image-2024-10-17T153453.598.png'
import imgCarousel22 from '@assets/Personalization/carrousel/image-2024-10-17T153534.257.png'
import imgCarousel23 from '@assets/Personalization/carrousel/image-2024-10-17T153634.331.png'
import imgCarousel24 from '@assets/Personalization/carrousel/image-2024-10-17T153721.793.png'
import imgCarousel25 from '@assets/Personalization/carrousel/image-2024-10-17T153940.530.png'
import imgCarousel26 from '@assets/Personalization/carrousel/image-2024-10-17T153959.940.png'
import imgCarousel27 from '@assets/Personalization/carrousel/image-2024-10-17T154848.449.png'
import imgCarousel28 from '@assets/Personalization/carrousel/image-2024-10-17T155047.348.png'
import imgCarousel29 from '@assets/Personalization/carrousel/image-2024-10-17T155153.198.png'
import imgCarousel30 from '@assets/Personalization/carrousel/image-2024-10-17T155233.159.png'
import imgCarousel31 from '@assets/Personalization/carrousel/image-2024-10-17T155346.027.png'
import imgCarousel32 from '@assets/Personalization/carrousel/image-2024-10-17T155436.132.png'
import imgCarousel33 from '@assets/Personalization/carrousel/image-2024-10-17T155631.965.png'
import imgCarousel34 from '@assets/Personalization/carrousel/image-2024-10-17T155821.345.png'
import imgCarousel35 from '@assets/Personalization/carrousel/image-2024-10-17T160502.043.png'
import imgCarousel36 from '@assets/Personalization/carrousel/image-2024-10-17T161010.816.png'
import imgCarousel37 from '@assets/Personalization/carrousel/image-2024-10-17T163450.811.png'
import imgCarousel38 from '@assets/Personalization/carrousel/image-2024-10-17T164511.759.png'

const images = [
  imgCarousel1,
  imgCarousel2,
  imgCarousel3,
  imgCarousel4,
  imgCarousel5,
  imgCarousel6,
  imgCarousel7,
  imgCarousel8,
  imgCarousel9,
  imgCarousel10,
  imgCarousel11,
  imgCarousel12,
  imgCarousel13,
  imgCarousel14,
  imgCarousel15,
  imgCarousel16,
  imgCarousel17,
  imgCarousel18,
  imgCarousel19,
  imgCarousel20,
  imgCarousel21,
  imgCarousel22,
  imgCarousel23,
  imgCarousel24,
  imgCarousel25,
  imgCarousel26,
  imgCarousel27,
  imgCarousel28,
  imgCarousel29,
  imgCarousel30,
  imgCarousel31,
  imgCarousel32,
  imgCarousel33,
  imgCarousel34,
  imgCarousel35,
  imgCarousel36,
  imgCarousel37,
  imgCarousel38,
]

const repeatedImages = Array(7).fill(images).flat()

export default function CarouselPersonalizationInfo() {
  return (
    <div className='h-auto w-full overflow-hidden bg-black'>
      <div className='w-full space-y-4 p-2 text-left text-white sm:top-6 sm:p-6'>
        <p className='px-1 font-creato-bold text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_516')}
        </p>
      </div>
      <div className='-mt-4 w-full space-y-4 p-2 text-left text-white sm:top-6 sm:p-6'>
        <p className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_517')}
        </p>
      </div>
      <div className='animate-slide-left-personalization mt-8 flex'>
        {repeatedImages.map((el, index) => (
          <div
            key={index}
            className='h-1/2 w-2/3 flex-shrink-0 border-4 border-solid border-black md:w-1/2'
          >
            <img
              className='h-full w-full object-cover'
              src={el}
              alt={`gallery-photo-${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
