import instragramIcon from '@assets/instagramFooter.png'
import logo from '@assets/f2i_IA.png'
import tiktokIcon from '@assets/tik-tokFooter.png'
import youtubeIcon from '@assets/youtubeFooter.png'
import t from '../store/languageActions'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import TermsModal from './TermsModal'
import PrivacyPolicyModal from './PrivacyPolicyModal'
import FrequentQuestions from './FrequentQuestions'
export default function Footer() {
  const navigate = useNavigate()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false)
  const [questionslIsOpen, setQuestionsModalIsOpen] = useState(false)
  function openModal() {
    setModalIsOpen(true)
  }
  function closeModal() {
    setModalIsOpen(false)
  }
  function openPrivacyModal() {
    setPrivacyModalIsOpen(true)
  }
  function closePrivacyModal() {
    setPrivacyModalIsOpen(false)
  }
  function openModalQuestions() {
    setQuestionsModalIsOpen(true)
  }
  function closeModalQuestions() {
    setQuestionsModalIsOpen(false)
  }
  return (
    <footer className='mt-28 bg-white py-8 font-creato-medium text-black'>
      <div className='mb-2 w-full border-b border-gray-300'>
        <div className='container mx-auto px-4'>
          <div className='mb-0.5 flex items-center justify-start lg:justify-between'>
            <img
              src={logo}
              alt='Logo of For2Inspiration'
              className='h-7 w-80'
              loading='lazy'
            />
          </div>
        </div>
      </div>
      <div className='container mx-auto px-4'>
        <div className='flex flex-col items-start justify-between lg:flex-row lg:items-start'>
          <div className='w-full text-left lg:mb-0 lg:w-1/5'>
            <p className='mt-8 text-lg font-bold text-main'>{t('MSG_48')}</p>
            <p className='mt-2 font-creato-light text-sm leading-snug tracking-tight text-main'>
              {t('MSG_49')}
            </p>
            <div className='mt-6 flex justify-center space-x-4'>
              <a
                href='https://www.instagram.com/for2inspiration/'
                aria-label='Instagram'
                className='text-black hover:text-gray-700'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={instragramIcon}
                  alt='Logo Instagram'
                  className='h-8 w-8'
                  loading='lazy'
                />
              </a>
              <a
                href='https://www.tiktok.com/@for2inspiration '
                aria-label='TikTok'
                className='text-black hover:text-gray-700'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={tiktokIcon}
                  alt='Logo TikTok'
                  className='h-8 w-8'
                  loading='lazy'
                />
              </a>
              <a
                href='https://www.youtube.com/@for2inspiration '
                aria-label='YouTube'
                className='text-black hover:text-gray-700'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={youtubeIcon}
                  alt='Logo YouTube'
                  className='h-8 w-8'
                  loading='lazy'
                />
              </a>
            </div>
          </div>
          <div className='w-full lg:ml-24 lg:w-4/5'>
            <div className='mt-10 flex flex-wrap justify-between lg:flex-nowrap'>
              <div className='mb-8 w-full lg:mb-0 lg:w-auto'>
                <h3 className='mb-2 text-start font-bold'>{t('MSG_50')}</h3>
                <ul>
                  <li className='-mt-1 text-start'>
                    <button
                      onClick={() => {
                        navigate('/about')
                        window.scrollTo(0, 0)
                      }}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_54')}
                    </button>
                  </li>
                  <li className='mt-2 text-start'>
                    <button
                      onClick={openModalQuestions}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_386')}
                    </button>
                  </li>
                </ul>
              </div>
              <div className='mb-8 w-full lg:mb-0 lg:w-auto'>
                <h3 className='mb-2 text-start font-bold'>{t('MSG_51')}</h3>
                <ul className='text-start'>
                  <li className='-mt-1'>
                    <button
                      onClick={() => {
                        navigate('/inspiration/info')
                        window.scrollTo(0, 0)
                      }}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_28')}
                    </button>
                  </li>
                  <li className='mt-2'>
                    <button
                      onClick={() => {
                        navigate('/decoration/info')
                        window.scrollTo(0, 0)
                      }}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_30')}
                    </button>
                  </li>
                  <li className='mt-2'>
                    <button
                      onClick={() => {
                        navigate('/redecoration/info')
                        window.scrollTo(0, 0)
                      }}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_32')}
                    </button>
                  </li>
                  <li className='mt-2'>
                    <button
                      onClick={() => {
                        navigate('/rendering/info')
                        window.scrollTo(0, 0)
                      }}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_455')}
                    </button>
                  </li>
                  <li className='mt-2'>
                    <button
                      onClick={() => {
                        navigate('/exteriors/info')
                        window.scrollTo(0, 0)
                      }}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_475')}
                    </button>
                  </li>
                  <li className='mt-2'>
                    <button
                      onClick={() => {
                        navigate('/planner/info')
                        window.scrollTo(0, 0)
                      }}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_494')}
                    </button>
                  </li>
                  <li className='mt-2'>
                    <button
                      onClick={() => {
                        navigate('/personalization/info')
                        window.scrollTo(0, 0)
                      }}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_515')}
                    </button>
                  </li>
                </ul>
              </div>
              <div className='mb-8 w-full lg:mb-0 lg:w-auto'>
                <h3 className='mb-2 text-start font-bold'>{t('MSG_52')}</h3>
                <ul className='text-start'>
                  <li className='-mt-1'>
                    <button
                      onClick={openModal}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_55')}
                    </button>
                  </li>
                  <li className='mt-2'>
                    <button
                      onClick={openPrivacyModal}
                      className='text-gray-700 hover:text-black'
                    >
                      {t('MSG_56')}
                    </button>
                  </li>
                </ul>
              </div>
              <div className='w-full lg:w-auto'>
                <h3 className='mb-2 text-start font-bold'>{t('MSG_53')}</h3>
                <p className='-mt-1 text-start'>
                  <a
                    href='mailto:info@for2inspiration.ai'
                    className='text-gray-700 hover:text-black'
                  >
                    info@for2inspiration.ai
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-8 text-start'>
          <p className='font-creato-light text-xs text-gray-700'>{t('MSG_77')}</p>
        </div>
      </div>
      <TermsModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
      <PrivacyPolicyModal
        isOpen={privacyModalIsOpen}
        onRequestClose={closePrivacyModal}
      />
      <FrequentQuestions
        isOpen={questionslIsOpen}
        onRequestClose={closeModalQuestions}
      />
    </footer>
  )
}
