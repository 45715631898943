import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import estrella from '@assets/estrella.png'
import { store } from '../../store'
import { useWebsocketContext } from '@components/Context'
import getSelectionsFromText from '@components/utils/getSelectionsFromText'
import Navbar from '@components/Navbar'
import ImageOptions from '@components/ImageOptions'
import t from '../../store/languageActions'
import AlertInfo from '@components/AlertInfo'
import SelectionStyle from '@components/SelectionStyle'
import Footer from '@components/Footer'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export type Respuesta = {
  idRespuestasIa: number
  imagenUrl1: string
  imagenUrl2: string
  orden: number
  texto: string
  acceso: boolean
}

export type Pregunta = {
  idPreguntasIa: number
  orden: number
  imagenUrl1: string
  imagenUrl2: string
  texto: string
  especial: boolean
  acceso: boolean
  respuestas: Respuesta[]
}

export type LocationState = {
  texto: string
  respuestas: Pregunta[]
  _concatenatedSelections?: string
  concatenatedSelections: string
  concatIds: number[]
  img: string
  triggerGenerate: boolean
  imageFile: string
  isChecked?: boolean
  blackImageFile?: string
  imageWithDrawing?: string
  blackAndWhiteImage?: string
}

export default function InspirationStyles2Page() {
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as LocationState
  const { texto = '', respuestas = [], _concatenatedSelections = '', concatIds } = state || {}
  const { creditosActuales } = useWebsocketContext()

  const preguntasEspeciales = respuestas.filter((p) => p.especial) || []
  const [selections, setSelections] = useState<{ [key: string]: string }>(() =>
    getSelectionsFromText(texto, _concatenatedSelections),
  )
  const [ids, setIds] = useState(concatIds)

  useLayoutEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])

  useEffect(() => {
    const initialSelections = getSelectionsFromText(texto, _concatenatedSelections)
    setSelections(initialSelections)
  }, [texto])

  function handleSelect(title: string, selectedItemTitle: string, id: number) {
    setSelections((prevSelections) => {
      const newSelections = { ...prevSelections }

      newSelections[title] = selectedItemTitle

      if (selectedItemTitle === 'IA') {
        delete newSelections[title]
      }

      return newSelections
    })
    setIds((prev) => [...prev, id])
  }

  const concatenatedSelections = Object.entries(selections)
    .map(([key, value]) => (value === 'IA' ? '' : `${key}: ${value}`))
    .filter(Boolean)
    .filter(() => {
      if (!_concatenatedSelections) {
        return true
      }
      return _concatenatedSelections.split(',').filter(Boolean)
    })
    .join(', ')

  function handleButtonClick() {
    navigate('/inspiration/styles2/generate', {
      state: {
        concatenatedSelections,
        texto: texto,
        respuestas,
        concatIds: ids,
        triggerGenerate: true,
      },
    })
    window.scrollTo(0, 0)
  }

  function handleBackCLick() {
    if (!Object.keys(selections).length) {
      window.scrollTo(0, 0)
      return navigate('/inspiration/styles', {
        state: {
          texto: texto.replace(
            _concatenatedSelections ? new RegExp(`[, ]{0,1} ${_concatenatedSelections}`) : '',
            '',
          ),
          respuestas,
          concatIds,
        },
      })
    }
    setSelections((prev) => {
      const latest = Object.entries(prev).at(-1)!
      const newObj = { ...prev }
      delete newObj[latest[0]]
      return newObj
    })
    setIds((prev) => prev.slice(0, -1))
  }
  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageOptions
        title={t('MSG_67')}
        subtitle={t('MSG_109')}
        buttonText={t('MSG_37')}
        imgSrc='https://api.for2inspiration.ai/Images/MotorIA/nLjjDu/f4ca0cd7-1ca6-46d4-9082-3dacd1792a59-0.png'
        onButtonClick={handleButtonClick}
      />
      <AlertInfo
        message={`${texto.replace(_concatenatedSelections ? new RegExp(`[, ]{0,1} ${_concatenatedSelections}`) : '', '')} ${concatenatedSelections ? `, ${concatenatedSelections}` : ''}`}
        navigateTo='/inspiration/styles'
        state={{ respuestas, texto }}
        fn={handleBackCLick}
      />
      <h1 className='my-8 text-center text-2xl text-main'>{t('MSG_110')}</h1>
      <h2 className='-mt-8 text-center font-chopin'></h2>
      <p className='text-center font-chopin'> {t('MSG_139')}</p>
      <h2 className='text-center font-chopin'>
        {t('MSG_113')}
        <span className='font-creato-medium font-bold'> {t('MSG_114')}</span> {t('MSG_115')}
      </h2>
      {preguntasEspeciales.map((pregunta) => (
        <SelectionStyle
          key={pregunta.idPreguntasIa}
          title={pregunta.texto}
          items={pregunta.respuestas.map((respuesta) => ({
            id: respuesta.idRespuestasIa,
            title: respuesta.texto,
            imgSrc: respuesta.imagenUrl1 || 'https://via.placeholder.com/300x200',
            acceso: pregunta.acceso,
          }))}
          onSelect={handleSelect}
          selections={selections}
        />
      ))}
      <div className='container mx-auto flex flex-1 justify-end py-4'>
        <button
          className='flex w-[280px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800'
          onClick={handleButtonClick}
        >
          {t('MSG_37')}
          <div className='ml-4 flex h-6 w-10 items-center rounded-lg border-[1.5px] border-white bg-main'>
            <img
              src={estrella}
              alt='Star image'
              className='ml-1 h-4 w-4'
            />
            <p className='ml-1 text-white'>1</p>
          </div>
        </button>
      </div>
      <Footer />
    </div>
  )
}
