import Modal from 'react-modal'
import { useEffect } from 'react'
import t from '../store/languageActions'

type DeleteModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: () => void
}

Modal.setAppElement('#root')

export default function DeleteModal({ isOpen, onRequestClose, onConfirm }: DeleteModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Confirmar Eliminación'
      className='relative mx-auto my-8 max-h-[60vh] w-full max-w-md overflow-y-auto rounded-md bg-white px-16 py-20 text-black'
      overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center'
    >
      <div className='flex flex-col items-center'>
        <p className='font-creato-bold text-lg'>{t('MSG_303')}</p>
        <div className='mt-4 flex w-full justify-center space-x-4'>
          <button
            className='w-16 transform rounded-md bg-main px-4 py-2 text-white shadow-sm transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-main hover:text-white focus:scale-105 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 active:scale-95 active:bg-main active:text-white'
            onClick={onConfirm}
          >
            {t('MSG_304')}
          </button>
          <button
            className='w-16 transform rounded-md bg-main px-4 py-2 text-white shadow-sm transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-main hover:text-white focus:scale-105 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 active:scale-95 active:bg-main active:text-white'
            onClick={onRequestClose}
          >
            {t('MSG_305')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
