import t from '../store/languageActions'

type YouTubeIconProps = {
  navigate: string
}

export function YouTubeIcon({ navigate }: YouTubeIconProps) {
  const handleClick = () => {
    window.open(navigate, '_blank')
  }

  return (
    <div
      className='flex transform cursor-pointer flex-col items-center transition-transform duration-200 ease-in-out hover:scale-105'
      onClick={handleClick}
    >
      <div className='relative flex h-[42px] w-16 flex-col items-center justify-center rounded-lg bg-black'>
        <div
          className='absolute inset-0 animate-border-snake rounded-lg bg-gradient-to-r from-yellow-700 via-gray-400 to-gray-700 p-0.5'
          style={{
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
            backgroundSize: '200% 200%',
          }}
        ></div>
        <svg
          className='relative z-10 h-6 w-6 text-white'
          viewBox='0 0 24 24'
          fill='currentColor'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M8 5v14l11-7L8 5z' />
        </svg>
        <p className='-mt-0.5 font-creato-bold text-xs text-white'>{t('MSG_550')}</p>
      </div>
    </div>
  )
}
