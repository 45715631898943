import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import t from '../../store/languageActions'
import image1 from '@assets/Exteriores/Exterior1 izq.jpg'
import image2 from '@assets/Exteriores/Exterior1 der.jpg'
import image3 from '@assets/Exteriores/Exterior2 izq.jpg'
import image4 from '@assets/Exteriores/Exterior2 der.jpg'
import image5 from '@assets/Exteriores/Exterior3 izq.jpg'
import image6 from '@assets/Exteriores/Exterior3 der.jpg'
import CustomHandle from '@components/CustonHandle'

const ComparingExteriorsInfo: React.FC = () => {
  return (
    <div className='relative mt-4 w-full overflow-hidden'>
      {/* Primer Comparador */}
      <div className='relative w-full'>
        <div
          className='relative'
          style={{ overflow: 'hidden', width: '80%', margin: '0 auto', maxWidth: '990px' }}
        >
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={image2}
                alt='stay image before generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={image1}
                alt='stay image after generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            handle={<CustomHandle />}
          />
        </div>
      </div>

      {/* Segundo Comparador */}
      <div className='relative mt-4 w-full'>
        <div
          className='relative'
          style={{ overflow: 'hidden', width: '80%', margin: '0 auto', maxWidth: '990px' }}
        >
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={image3}
                alt='stay image before generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={image4}
                alt='stay image after generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            handle={<CustomHandle />}
          />
        </div>
      </div>
      {/* Tercero Comparador */}
      <div className='relative mt-4 w-full'>
        <div
          className='relative'
          style={{ overflow: 'hidden', width: '80%', margin: '0 auto', maxWidth: '990px' }}
        >
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={image6}
                alt='stay image before generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={image5}
                alt='stay image after generate IA'
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
              />
            }
            handle={<CustomHandle />}
          />
        </div>
      </div>
      <div className='my-10 w-full space-y-4 bg-black p-2 text-left text-white sm:p-6'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_474')}
        </h1>
      </div>
    </div>
  )
}

export default ComparingExteriorsInfo
