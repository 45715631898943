import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../../store'
import { useWebsocketContext } from '@components/Context'
import PricingCard from '@components/PricingCard'
import { motion } from 'framer-motion'

const monthlyPlans = [
  {
    planName: 'MSG_164',
    planName2: 'MSG_177',
    price: 'MSG_165',
    credits: 'MSG_166',
    month: 'MSG_220',
    description: [
      'MSG_385',
      'MSG_169',
      'MSG_170',
      'MSG_171',
      'MSG_172',
      'MSG_173',
      'MSG_174',
      'MSG_379',
      'MSG_380',
      'MSG_381',
      'MSG_382',
      'MSG_28',
      'MSG_30',
      'MSG_32',
      'MSG_455',
      'MSG_475',
      'MSG_494',
      'MSG_543',
      'MSG_544',
      'MSG_545',
      'MSG_546',
    ],
    popupTitle: 'MSG_180',
    buttonStyle: 'bg-black text-white',
    periodo: 'M1',
    lang: 'ES',
  },
  {
    planName: 'MSG_164',
    planName2: 'MSG_181',
    price: 'MSG_178',
    credits: 'MSG_179',
    month: 'MSG_220',
    description: [
      'MSG_385',
      'MSG_169',
      'MSG_170',
      'MSG_171',
      'MSG_172',
      'MSG_173',
      'MSG_174',
      'MSG_379',
      'MSG_380',
      'MSG_381',
      'MSG_382',
      'MSG_28',
      'MSG_30',
      'MSG_32',
      'MSG_455',
      'MSG_475',
      'MSG_494',
      'MSG_543',
      'MSG_544',
      'MSG_545',
      'MSG_546',
    ],
    popupTitle: 'MSG_424',
    buttonStyle: 'bg-black text-white',
    periodo: 'M2',
    lang: 'ES',
  },
]

const yearlyPlans = [
  {
    planName: 'MSG_476',
    planName2: 'MSG_177',
    price: 'MSG_477',
    credits: 'MSG_478',
    month: 'MSG_479',
    description: [
      'MSG_385',
      'MSG_169',
      'MSG_170',
      'MSG_171',
      'MSG_172',
      'MSG_173',
      'MSG_174',
      'MSG_379',
      'MSG_380',
      'MSG_381',
      'MSG_382',
      'MSG_543',
      'MSG_544',
      'MSG_545',
      'MSG_546',
      'MSG_28',
      'MSG_30',
      'MSG_32',
      'MSG_455',
      'MSG_475',
      'MSG_494',
    ],
    popupTitle: 'MSG_180',
    buttonStyle: 'bg-black text-white',
    periodo: 'A1',
    lang: 'ES',
  },
  {
    planName: 'MSG_476',
    planName2: 'MSG_181',
    price: 'MSG_480',
    credits: 'MSG_481',
    month: 'MSG_479',
    description: [
      'MSG_385',
      'MSG_169',
      'MSG_170',
      'MSG_171',
      'MSG_172',
      'MSG_173',
      'MSG_174',
      'MSG_379',
      'MSG_380',
      'MSG_381',
      'MSG_382',
      'MSG_543',
      'MSG_544',
      'MSG_545',
      'MSG_546',
      'MSG_28',
      'MSG_30',
      'MSG_32',
      'MSG_455',
      'MSG_475',
      'MSG_494',
    ],
    popupTitle: 'MSG_424',
    buttonStyle: 'bg-black text-white',
    periodo: 'A2',
    lang: 'ES',
  },
]

export default function SectionPlanPremium() {
  const navigate = useNavigate()
  const token = store((s) => s.userToken)
  const { isPaymentAccepted, setIsPaymentAccepted } = useWebsocketContext()
  const [planType, setPlanType] = useState<'monthly' | 'yearly'>('monthly') // Estado inicial para mensual
  const [paymentError, setPaymentError] = useState(false)
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  useEffect(() => {
    if (isPaymentAccepted === false) {
      setPaymentError(true)
      console.error('Payment was not accepted')
    } else if (isPaymentAccepted === true) {
      setPaymentError(false)
    }
  }, [isPaymentAccepted])

  useEffect(() => {
    if (paymentError) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [paymentError])

  const closeModal = () => {
    setPaymentError(false)
    setIsPaymentAccepted(null)
  }

  const handleRedirect = (periodo: string, lang: string) => {
    if (!token) {
      console.error('Token is not available')
      navigate('/login')
      return
    }

    const baseUrl = 'https://api.for2inspiration.ai/u/redsys'
    const url = `${baseUrl}?token=${encodeURIComponent(token)}&periodo=${encodeURIComponent(periodo)}&lang=${encodeURIComponent(lang)}`
    window.open(url, '_blank')
  }

  const plans = planType === 'monthly' ? monthlyPlans : yearlyPlans
  return (
    <div className='bg-black px-4 py-16'>
      <div className='mb-10 flex items-center justify-center'>
        <h1 className='text-center text-2xl font-bold text-white'>{t('MSG_489')}</h1>
      </div>
      <div className='mb-12 flex flex-col items-center justify-center space-y-4 md:flex-row md:space-x-4 md:space-y-0'>
        <h1 className='text-center text-2xl font-bold text-white md:mb-0 md:mr-44'>
          {t('MSG_44')}
        </h1>
        {/* Toggle */}
        <div className='relative flex w-[250px] items-center rounded-md bg-gray-200 p-1'>
          <motion.div
            className='absolute bottom-1 left-1 top-1 h-10 w-28 rounded-md bg-main'
            initial={false}
            animate={{ x: planType === 'monthly' ? 0 : 122 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          />
          <button
            onClick={() => setPlanType('monthly')}
            className={`z-10 w-1/2 py-2 text-center ${planType === 'monthly' ? 'text-white' : 'text-black'}`}
          >
            {t('MSG_482')}
          </button>
          <button
            onClick={() => setPlanType('yearly')}
            className={`z-10 w-1/2 py-2 text-center ${planType === 'yearly' ? 'text-white' : 'text-black'}`}
          >
            {t('MSG_483')}
          </button>
        </div>
      </div>
      <div className='flex flex-col items-center space-y-8 md:flex-row md:justify-center md:space-x-8 md:space-y-0'>
        {plans.map((plan, index) => (
          <div
            key={`${plan.planName}-${index}`}
            className='w-full max-w-[330px] md:w-1/3'
          >
            <PricingCard
              planName={t(plan.planName)}
              planName2={t(plan.planName2)}
              price={t(plan.price)}
              credits={t(plan.credits)}
              month={t(plan.month)}
              description={plan.description.map(t)}
              popupTitle={t(plan.popupTitle)}
              buttonStyle={plan.buttonStyle}
              handleRedirect={() => handleRedirect(plan.periodo, plan.lang)}
            />
          </div>
        ))}
      </div>
      {paymentError && (
        <div className='fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='rounded-md bg-white p-6 shadow-md'>
            <h2 className='mb-4 font-creato-bold text-xl font-bold'>{t('MSG_370')}</h2>
            <p className='mb-4 font-creato-medium'>{t('MSG_371')}</p>
            <button
              onClick={closeModal}
              className='rounded-md bg-main px-4 py-1.5 text-white'
            >
              {t('MSG_372')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
