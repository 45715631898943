import { getBaseHeaders, getBaseUrl } from '..'

export default async function deleteImage(id: number) {
  const url = getBaseUrl(`api/TrabajosIA/borrarTrababoUrl/${id}`)

  const response = await fetch(url, getBaseHeaders())

  if (!response.ok) {
    const errorMessage = await response.text()
    console.error('Error response:', errorMessage)
    throw new Error('Failed to delete image')
  }
}
