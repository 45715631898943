import { useWebsocketContext } from '@components/Context'
import Navbar from '@components/Navbar'
import { useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PlanActive from './PlanActive'
import SectionPlans from './SectionPlans'
import SectionPlanPremium from './SectionPlanPremium'
import Footer from '@components/Footer'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function PagePlansActive() {
  const isUserLogged = sessionStorage.getItem('userToken')
  const { creditosActuales } = useWebsocketContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const redirect = useNavigate()
  useLayoutEffect(() => {
    if (!isUserLogged) redirect('/')
  }, [isUserLogged])
  return (
    <div className='font-creato-medium'>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome
        creditosActuales={creditosActuales}
      />
      <PlanActive />
      <SectionPlans />
      <SectionPlanPremium />
      <Footer />
    </div>
  )
}
