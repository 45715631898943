import Modal from 'react-modal'
import { useEffect } from 'react'
import t from '../store/languageActions'

type PrivacyPolicyModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}

Modal.setAppElement('#root')

export default function PrivacyPolicyModal({ isOpen, onRequestClose }: PrivacyPolicyModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Políticas de Privacidad'
      className='relative mx-auto my-8 max-h-[80vh] w-full max-w-4xl overflow-y-auto rounded-md bg-white p-8 text-black'
      overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-20'
    >
      <button
        onClick={onRequestClose}
        className='absolute right-2 top-2 text-xl font-bold'
      >
        &times;
      </button>
      <h2 className='mb-4 font-creato-bold text-2xl'>{t('MSG_307')}</h2>
      <p className='font-creato-medium'>{t('MSG_308')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_309')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_310')}</h3>
      <p className='font-creato-medium'>{t('MSG_311')}</p>
      <h4 className='mt-2 font-creato-bold'>{t('MSG_312')}</h4>
      <p className='font-creato-medium'>{t('MSG_313')}</p>
      <h5 className='mt-2 font-creato-bold'>{t('MSG_314')}</h5>
      <p className='font-creato-medium'>{t('MSG_315')}</p>
      <h5 className='mt-2 font-creato-bold'>{t('MSG_316')}</h5>
      <p className='font-creato-medium'>{t('MSG_317')}</p>
      <h5 className='mt-2 font-creato-bold'>{t('MSG_318')}</h5>
      <p className='font-creato-medium'>{t('MSG_319')}</p>
      <h5 className='mt-2 font-creato-bold'>{t('MSG_320')}</h5>
      <p className='font-creato-medium'>{t('MSG_321')}</p>
      <h5 className='mt-2 font-creato-bold'>{t('MSG_322')}</h5>
      <p className='font-creato-medium'>{t('MSG_323')}</p>
      <h5 className='mt-2 font-creato-bold'>{t('MSG_324')}</h5>
      <p className='font-creato-medium'>{t('MSG_325')}</p>
      <h4 className='mt-2 font-creato-bold'>{t('MSG_326')}</h4>
      <p className='font-creato-medium'>{t('MSG_327')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_328')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_329')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_330')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_331')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_332')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_333')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_334')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_335')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_336')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_337')}</h3>
      <p className='font-creato-medium'>{t('MSG_338')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_339')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_340')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_341')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_342')}</p>
      <p className='mt-2 font-creato-medium'>{t('MSG_343')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_344')}</h3>
      <p className='font-creato-medium'>{t('MSG_345')}</p>
      <h4 className='mt-2 font-creato-bold'>{t('MSG_346')}</h4>
      <p className='font-creato-medium'>{t('MSG_347')}</p>
      <h4 className='mt-2 font-creato-bold'>{t('MSG_348')}</h4>
      <p className='font-creato-medium'>{t('MSG_349')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_350')}</h3>
      <p className='font-creato-medium'>{t('MSG_351')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_352')}</h3>
      <p className='font-creato-medium'>{t('MSG_353')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_354')}</h3>
      <p className='font-creato-medium'>{t('MSG_355')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_356')}</h3>
      <p className='font-creato-medium'>{t('MSG_357')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_358')}</h3>
      <p className='font-creato-medium'>{t('MSG_359')}</p>
      <h3 className='mt-4 font-creato-bold'>{t('MSG_360')}</h3>
      <p className='font-creato-medium'>
        {t('MSG_361')}{' '}
        <a
          href='mailto:info@for2inspiration.ai'
          className='text-main hover:underline'
        >
          info@for2inspiration.ai
        </a>
      </p>
    </Modal>
  )
}
