import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../../store'
import { useWebsocketContext } from '@components/Context'
import PricingCard from '@components/PricingCard'

const plans = [
  {
    planName: 'MSG_39',
    price: 'MSG_182',
    credits: 'MSG_183',
    description: [
      'MSG_168',
      'MSG_169',
      'MSG_171',
      'MSG_172',
      'MSG_173',
      'MSG_174',
      'MSG_175',
      'MSG_376',
      'MSG_377',
      'MSG_378',
      'MSG_28',
      'MSG_30',
      'MSG_32',
      'MSG_455',
      'MSG_475',
    ],
    popupTitle: 'MSG_186',
    periodo: 'B1',
    lang: 'ES',
  },
  {
    planName: 'MSG_187',
    price: 'MSG_188',
    credits: 'MSG_189',
    description: [
      'MSG_168',
      'MSG_169',
      'MSG_171',
      'MSG_172',
      'MSG_173',
      'MSG_174',
      'MSG_175',
      'MSG_376',
      'MSG_377',
      'MSG_378',
      'MSG_28',
      'MSG_30',
      'MSG_32',
      'MSG_455',
      'MSG_475',
    ],
    popupTitle: 'MSG_190',
    periodo: 'B2',
    lang: 'ES',
  },
  {
    planName: 'MSG_191',
    price: 'MSG_192',
    credits: 'MSG_193',
    description: [
      'MSG_168',
      'MSG_169',
      'MSG_171',
      'MSG_172',
      'MSG_173',
      'MSG_174',
      'MSG_175',
      'MSG_376',
      'MSG_377',
      'MSG_378',
      'MSG_28',
      'MSG_30',
      'MSG_32',
      'MSG_455',
      'MSG_475',
    ],
    popupTitle: 'MSG_194',
    periodo: 'B3',
    lang: 'ES',
  },
]

export default function SectionPlans() {
  const navigate = useNavigate()
  const token = store((s) => s.userToken)
  const { isPaymentAccepted, setIsPaymentAccepted } = useWebsocketContext()
  const [paymentError, setPaymentError] = useState(false)
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  useEffect(() => {
    if (isPaymentAccepted === false) {
      setPaymentError(true)
      console.error('Payment was not accepted')
    } else if (isPaymentAccepted === true) {
      setPaymentError(false)
    }
  }, [isPaymentAccepted])

  useEffect(() => {
    if (paymentError) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [paymentError])

  const closeModal = () => {
    setPaymentError(false)
    setIsPaymentAccepted(null)
  }

  const handleRedirect = (periodo: string, lang: string) => {
    if (!token) {
      console.error('Token is not available')
      navigate('/login')
      return
    }

    const baseUrl = 'https://api.for2inspiration.ai/u/redsys'
    const url = `${baseUrl}?token=${encodeURIComponent(token)}&periodo=${encodeURIComponent(periodo)}&lang=${encodeURIComponent(lang)}`
    window.open(url, '_blank')
  }

  return (
    <div className='px-4 py-16'>
      <h1 className='mb-12 text-center text-2xl font-bold'>{t('MSG_162')} </h1>
      <div className='flex flex-col items-center space-y-8 md:flex-row md:justify-center md:space-x-8 md:space-y-0'>
        {plans.map((plan, index) => (
          <div
            key={index}
            className='w-full max-w-[330px] md:w-1/3'
          >
            <PricingCard
              planName={t(plan.planName)}
              price={t(plan.price)}
              credits={t(plan.credits)}
              description={plan.description.map(t)}
              popupTitle={t(plan.popupTitle)}
              handleRedirect={() => handleRedirect(plan.periodo, plan.lang)}
            />
          </div>
        ))}
      </div>
      {paymentError && (
        <div className='fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='rounded-md bg-white p-6 shadow-md'>
            <h2 className='mb-4 font-creato-bold text-xl font-bold'> {t('MSG_370')}</h2>
            <p className='mb-4 font-creato-medium'>{t('MSG_371')}</p>
            <button
              onClick={closeModal}
              className='rounded-md bg-main px-4 py-1.5 text-white'
            >
              {t('MSG_372')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
