import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../store'
import t from '../store/languageActions'
import shuffle from '../utils/shuffle'
import allTypeImages from '@proxies/images/allTypeImages'

export default function ImageSection() {
  const [carouselImages, setCarouselImages] = useState<string[]>([])
  const [currImage, setCurrImage] = useState<string | null>(null)
  const increment = useRef(0)
  const navigate = useNavigate()
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')

  useEffect(() => {
    const fetchCarouselImages = async () => {
      const interiorImage = (await allTypeImages(() => {}, 'InteriorDesign')) as string[]
      const shuffledImages = shuffle(interiorImage) as string[]
      setCarouselImages(shuffledImages)
      setCurrImage(shuffledImages[0] || null)
    }

    fetchCarouselImages()
  }, [])

  useEffect(() => {
    if (carouselImages.length > 0) {
      const timeout = setInterval(() => {
        const nextImage = carouselImages[increment.current % carouselImages.length] || null // Controlar el caso undefined
        setCurrImage(nextImage)
        increment.current += 1
      }, 3000)
      return () => {
        clearInterval(timeout)
      }
    }
  }, [carouselImages])

  const handleButtonClick = () => {
    if (token) {
      window.scrollTo(0, 0)
      navigate('/user')
    } else {
      navigate('/register')
    }
  }

  return (
    <div className='bg-black text-white'>
      <div className='flex h-auto w-full flex-col lg:flex-row'>
        <div className='flex w-full items-center justify-center p-4 lg:w-3/5 lg:p-8'>
          {currImage && (
            <img
              className='h-96 w-full object-cover object-center lg:h-full'
              src={currImage}
              alt='App screenshot'
            />
          )}
        </div>
        <div className='flex w-full items-center justify-center p-8 lg:w-2/5 lg:py-12 lg:pr-8'>
          <div className='max-w-lg text-left'>
            <h2 className='text-xl tracking-tight text-main sm:text-2xl'>{t('MSG_23')}</h2>
            <p className='text-xl tracking-tight text-white sm:text-2xl'>{t('MSG_24')}</p>
            <p className='mt-4 text-base leading-7 text-gray-300'>{t('MSG_25')}</p>
            <div className='mt-8 flex justify-end'>
              <button
                className='transform rounded-md border border-white bg-black px-3 py-2 text-sm font-semibold text-white transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-main focus:scale-105 active:scale-95'
                onClick={handleButtonClick}
              >
                {t('MSG_65')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
