import { Link, useNavigate } from 'react-router-dom'
import logo from '../assets/logo.png'
import handleLogin from './actions/handleLogin'
import { useState } from 'react'
import { store } from '../store'
import { getBaseUrl, getBaseHeaders } from '@proxies/index'
import Modal from 'react-modal'

Modal.setAppElement('#root')

export default function Login() {
  const router = useNavigate()
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  async function recuperarContrasena() {
    const encodedEmail = encodeURIComponent(email)
    const url = getBaseUrl(`api/Auth/recuperaContrasenya?correo=${encodedEmail}`)

    try {
      const response = await fetch(
        url,
        getBaseHeaders({
          method: 'get',
          headers: { Accept: 'text/plain' },
        }),
      )
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.text()
      setModalMessage(data)
      setIsSuccess(true)
      setModalOpen(true)
    } catch (error) {
      console.error('Error:', error)
      setModalMessage('Error al recuperar la contraseña.')
      setIsSuccess(false)
      setModalOpen(true)
    }
  }

  const handleClose = () => {
    setModalOpen(false)
    if (isSuccess) {
      router('/login')
    }
  }

  return (
    <div className='flex min-h-screen flex-col items-center justify-center bg-black px-4'>
      <div className='mb-4 flex justify-center'>
        <img
          src={logo}
          onClick={() => router('/')}
          alt='Logo For2Inspiration'
          className='h-16 cursor-pointer'
        />
      </div>
      <div className='relative h-[500px] w-full max-w-sm space-y-6 rounded-md border border-transparent bg-gray-950 p-8 text-white shadow-md'>
        <div
          className='absolute inset-0 animate-border-snake rounded-md bg-gradient-to-r from-yellow-700 via-gray-600 to-gray-700 p-0.5'
          style={{
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            WebkitMaskComposite: 'xor',
            backgroundSize: '200% 200%',
          }}
        ></div>
        <button
          onClick={() => router('/')}
          className='absolute -top-3 right-3 text-2xl text-gray-400 hover:text-gray-200'
        >
          &times;
        </button>
        <h2 className='text-left text-3xl font-bold text-gray-200'>{t('MSG_195')}</h2>
        <form
          className='relative z-10 space-y-5'
          onSubmit={async (e) => {
            e.preventDefault()
            const didSucceed = await handleLogin(e)
            if (didSucceed) {
              router('/user')
            } else {
              setErrorMessage(t('MSG_196'))
            }
          }}
        >
          <div className='-mt-6 h-6 text-center text-main'>
            {errorMessage && <div>{errorMessage}</div>}
          </div>
          <div>
            <label
              htmlFor='email'
              className='block text-left text-sm font-medium text-gray-300'
            >
              {t('MSG_79')}
            </label>
            <input
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
            />
          </div>
          <div>
            <label
              htmlFor='password'
              className='block text-left text-sm font-medium text-gray-300'
            >
              {t('MSG_80')}
            </label>
            <input
              id='password'
              name='password'
              type='password'
              autoComplete='current-password'
              required
              className='mt-1 w-full rounded-md border border-gray-600 bg-gray-500 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-main focus:outline-none focus:ring-main sm:text-sm'
            />
          </div>
          <div>
            <button
              type='submit'
              className='mt-4 w-full transform rounded-md bg-white px-4 py-2 text-main shadow-sm transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-main hover:text-white focus:scale-105 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 active:scale-95 active:bg-main active:text-white'
            >
              {t('MSG_104')}
            </button>
          </div>
          <div className='text-sm'>
            <Link
              to='/register'
              className='block text-left text-gray-400 hover:text-main'
            >
              {t('MSG_82')}
              <span className='ml-2 font-bold'>{t('MSG_83')}</span>
            </Link>
            <a
              href='#'
              onClick={recuperarContrasena}
              className='mt-1 block text-left text-gray-400 hover:text-main'
            >
              {t('MSG_84')} <span className='font-bold'>{t('MSG_85')}</span>
            </a>
          </div>
        </form>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={handleClose}
        contentLabel='Recuperación de Contraseña'
        className='relative z-50 mx-auto h-auto w-[95%] max-w-lg overflow-y-auto rounded-md bg-white px-8 py-10 text-black shadow-xl'
        overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50'
      >
        <button
          className='absolute right-3 top-2 text-2xl text-gray-400 hover:text-gray-600'
          onClick={handleClose}
        >
          &times;
        </button>
        <div className='flex flex-col items-center'>
          <img
            src={logo}
            alt='Logo For2Inspiration'
            className='-mt-2 h-12 cursor-pointer'
            onClick={handleClose}
          />
          <p className='text-md mt-4 text-center font-creato-medium'>{modalMessage}</p>
          {isSuccess && (
            <p className='mt-4 text-center font-creato-medium'>
              {t('MSG_300')}{' '}
              <button
                onClick={handleClose}
                className='font-creato-bold text-main underline'
              >
                {t('MSG_301')}
              </button>
            </p>
          )}
        </div>
      </Modal>
    </div>
  )
}
