import { getBaseHeaders, getBaseUrl } from '..'
export const mockBody = {
  nombre: '',
  apellidos: '',
  direccion: '',
  cPostal: '',
  telefono: '',
  idIdioma: 0,
  empresa: '',
  cif: '',
  ciudad: '',
  pais: '',
  correo: '',
  correoConfirmar: '',
  password: '',
  passwordConfirmar: '',
  confirmarTerminos: true,
}
type Props = typeof mockBody

export default async function register(config: Props) {
  const info = await fetch(
    getBaseUrl('api/Auth/register'),
    getBaseHeaders({
      method: 'post',
      body: config,
      headers: { 'Content-Type': 'application/json' },
    }),
  )

  const data = info.json()
  console.log('New register:', { data })
  return info as any as { message: string }
}
