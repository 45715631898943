/** @type {import('tailwindcss').Config} */
export const config = {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  safelist: [
    'text-biggest',
    'text-big-xxl',
    'text-big-xl',
    'text-big-high',
    'text-big',
    'text-big-low',
    'text-medium-high',
    'text-medium',
    'text-medium-low',
    'text-small-high',
    'text-small',
    'text-small-low',
    'text-smallest',
  ],
  theme: {
    extend: {
      fontFamily: {
        calibri: ['Calibri', 'sans-serif'],
        chopin: ['Chopin'],
        'montserrat-medium': ['Montserrat-Medium'],
        'montserrat-bold': ['Montserrat-Bold'],
        'creato-medium': ['creato-medium'],
        'creato-bold': ['creato-bold'],
        'creato-light': ['creato-light'],
      },
      colors: {
        main: '#b88626',
      },
      fontSize: {
        biggest: '13.75rem', // 220 px
        'big-xxl': '12.5rem', // 200 px
        'big-xl': '7.5rem', // 120px
        'big-high': '6.25rem', // 100px
        big: '5.625rem', //  90px
        'big-low': '5rem', // 80px
        'medium-high': '4.5rem', // 72px
        medium: '5.3125rem', //
        'medium-low': '3.75rem', // 60 px
        'small-high': '3rem', // 48 px
        small: '2.5rem', // 40px
        'small-low': '2.25rem', // 36 px
        smallest: '1.875rem', // 30px
      },
      keyframes: {
        'slide-left-infinite': {
          '0%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(-10000%)' },
        },
        spin: {
          '0%': { transform: 'rotate(0deg)' },
          '100%': { transform: 'rotate(360deg)' },
        },
        'border-snake': {
          '0%': {
            'background-position': '0% 50%',
          },
          '100%': {
            'background-position': '200% 50%',
          },
        },
      },
      animation: {
        'slide-left-infinite': 'slide-left-infinite 2000s linear infinite',
        spin: 'spin 2s linear infinite',
        'border-snake': 'border-snake 6s linear infinite',
      },
      padding: {
        '6p': '5.5%',
      },
    },
  },
  plugins: [],
}
export default config
