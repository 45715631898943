import { getBaseHeaders, getBaseUrl } from '..'

export default async function getUserInfo() {
  const info = await fetch(
    getBaseUrl('api/Auth/getUserAuthenticated'),
    getBaseHeaders({ method: 'get', headers: { 'Content-Type': 'application/json' } }),
  )
  const data = (await info.json()) as { name: string; email: string }
  return data
}
