import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import { useWebsocketContext } from '../Context'
import Footer from '../Footer'
import Navbar from '../Navbar'
import { ScrollProgressBar } from '@components/ScrollProgressBar'
import IncidentModal from '@components/IncidentModal'
import subir from '@assets/subir-imagen.png'
import InfoIcon from '@assets/infoIcon.png'
import estrella from '@assets/estrella.png'
import uploadImage from '@proxies/ia/uploadImage'
import ModalInfo from './ModalInfo'
import isIOS from '@components/utils/isIOS'

export default function PersonalizationPage() {
  const { creditosActuales } = useWebsocketContext()
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const texto = store((s) => s.translations)
  const [scaleFactor, setScaleFactor] = useState(1)
  const [, setImageDimensions] = useState({ width: 0, height: 0 })
  function t(t: string) {
    return texto[t] ?? t
  }
  const navigate = useNavigate()
  const location = useLocation()
  const { prompt, noPrompt, imageFile, imageWithDrawing } = location.state || {}

  const [uploadedImage, setUploadedImage] = useState<string | null>(imageFile || null)
  const [drawingDataUrl] = useState<string | null>(imageWithDrawing || null)
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [, setImageFile] = useState<string | null>(null)
  const [promptText, setPromptText] = useState<string>(prompt || '')
  const [noPromptText, setNoPromptText] = useState<string>(noPrompt || '')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [isDrawing, setIsDrawing] = useState(false)
  const [penSize, setPenSize] = useState(70)
  const [history, setHistory] = useState<string[]>([])
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const drawingCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number } | null>(null)
  const [hasMultipleCameras, setHasMultipleCameras] = useState(false)
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment')
  const [, setImageSrc] = useState('')
  const [, setBlackImageURL] = useState<string | null>(null)

  const canvasWidth = 800
  const canvasHeight = 500

  const lockScrollOnCanvas = (e: TouchEvent) => {
    e.preventDefault()
  }

  useEffect(() => {
    const canvas = drawingCanvasRef.current
    if (canvas) {
      canvas.addEventListener('touchmove', lockScrollOnCanvas, { passive: false })
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('touchmove', lockScrollOnCanvas)
      }
    }
  }, [])

  const openCamera = async () => {
    setIsCameraOpen(true)
    setUploadedImage(null)

    if (navigator.mediaDevices?.enumerateDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevices = devices.filter((device) => device.kind === 'videoinput')
      setHasMultipleCameras(videoDevices.length > 1)
    }

    if (navigator.mediaDevices?.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: facingMode },
        })
        if (videoRef.current) videoRef.current.srcObject = stream
      } catch (error) {
        console.error('Error al acceder a la cámara:', error)
      }
    }
  }

  async function captureAndUploadPhoto() {
    if (videoRef.current) {
      const canvas = document.createElement('canvas')
      canvas.width = videoRef.current.videoWidth
      canvas.height = videoRef.current.videoHeight
      const context = canvas.getContext('2d')

      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)

        const blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'image/jpeg', 0.8),
        )

        if (blob) {
          const file = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' })
          const fileUrl = URL.createObjectURL(file)

          setUploadedImage(fileUrl)
          if (setImageSrc) {
            setImageSrc(fileUrl)
          }

          setLoading(true)

          try {
            const fileName = await uploadImage(file)
            if (setImageFile) {
              setImageFile(fileName)
            }
          } catch (error) {
            console.error('Error al cargar la imagen:', error)
            openModal(t('MSG_533'))
          } finally {
            setLoading(false)
          }
        }
      }
    }
  }

  const closeCamera = async () => {
    if (isIOS()) {
      await captureAndUploadPhoto()
    }
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream
      const tracks = stream.getTracks()
      tracks.forEach((track) => track.stop())
    }
    setIsCameraOpen(false)
  }

  const toggleCamera = () => {
    setFacingMode((prev) => (prev === 'user' ? 'environment' : 'user'))
    closeCamera()
    openCamera()
  }

  const capturePhoto = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas')
      canvas.width = videoRef.current.videoWidth
      canvas.height = videoRef.current.videoHeight
      const context = canvas.getContext('2d')
      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)
        canvas.toBlob(
          async (blob) => {
            if (blob) {
              const file = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' })
              const fileUrl = URL.createObjectURL(file)

              setUploadedImage(fileUrl)
              if (setImageSrc) {
                setImageSrc(fileUrl)
              }

              const fileName = await uploadImage(file)
              if (setImageFile) {
                setImageFile(fileName)
              }
            }
          },
          'image/jpeg',
          0.8,
        )
        closeCamera()
      }
    }
  }

  useLayoutEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (!allowedTypes.includes(file.type)) {
        openModal(t('MSG_487'))
        return
      }
      setLoading(true)
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          const resizedImage = await resizeImage(e.target?.result as string, 1440, 808)
          setUploadedImage(resizedImage)

          const img = new Image()
          img.src = resizedImage
        }
        reader.readAsDataURL(file)

        const fileName = await uploadImage(file)
        setImageSrc(URL.createObjectURL(file))
        setImageFile(fileName)
      } catch (error) {
        console.error('Error uploading image:', error)
        openModal(t('MSG_533'))
      } finally {
        setLoading(false)
      }
    }
  }
  async function resizeImage(
    imageSrc: string,
    maxWidth: number,
    maxHeight: number,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = imageSrc

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height)
        const width = img.width * scaleFactor
        const height = img.height * scaleFactor

        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx?.drawImage(img, 0, 0, width, height)

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const resizedImageURL = URL.createObjectURL(blob)

              const compressedImage = new Image()
              compressedImage.src = resizedImageURL
              compressedImage.onload = () => {
                console.log(
                  `Dimensiones comprimidas: ${compressedImage.width}x${compressedImage.height}`,
                  `Tamaño: ${(blob.size / 1024).toFixed(2)} KB`,
                )
                resolve(resizedImageURL)
              }
            } else {
              reject(new Error('Error al redimensionar la imagen'))
            }
          },
          'image/jpeg',
          0.8,
        )
      }

      img.onerror = (error) => {
        console.error('Error al cargar la imagen en resizeImage:', error)
        reject(error)
      }
    })
  }

  const openModal = (message: string) => {
    setModalMessage(message)
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  const [isModalOpenInfo, setIsModalOpenInfo] = useState(false)

  const openModalInfo = () => setIsModalOpenInfo(true)
  const closeModalInfo = () => setIsModalOpenInfo(false)

  useEffect(() => {
    if (uploadedImage) {
      const canvas = canvasRef.current
      const drawingCanvas = drawingCanvasRef.current

      if (canvas && drawingCanvas) {
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.src = uploadedImage

        img.onload = () => {
          const scale = Math.min(canvasWidth / img.width, canvasHeight / img.height)
          const adjustedWidth = img.width * scale
          const adjustedHeight = img.height * scale

          setScaleFactor(scale)
          setImageDimensions({ width: adjustedWidth, height: adjustedHeight })

          drawingCanvas.width = adjustedWidth
          drawingCanvas.height = adjustedHeight
          canvas.width = adjustedWidth
          canvas.height = adjustedHeight

          if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(img, 0, 0, adjustedWidth, adjustedHeight)
          }
        }
      }
    }
  }, [uploadedImage])

  useEffect(() => {
    if (uploadedImage) {
      const canvas = canvasRef.current
      const drawingCanvas = drawingCanvasRef.current

      if (canvas && drawingCanvas) {
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.src = uploadedImage

        img.onload = () => {
          const scaleFactor = Math.min(canvasWidth / img.width, canvasHeight / img.height)
          const imageWidth = img.width * scaleFactor
          const imageHeight = img.height * scaleFactor
          drawingCanvas.width = imageWidth
          drawingCanvas.height = imageHeight
          canvas.width = imageWidth
          canvas.height = imageHeight

          ctx?.clearRect(0, 0, canvas.width, canvas.height)
          ctx?.drawImage(img, 0, 0, imageWidth, imageHeight)

          if (drawingDataUrl) {
            const overlayCtx = drawingCanvas.getContext('2d')
            const overlayImage = new Image()
            overlayImage.src = drawingDataUrl
            overlayImage.onload = () => {
              overlayCtx?.drawImage(overlayImage, 0, 0, imageWidth, imageHeight)
            }
          }
        }
      }
    }
  }, [uploadedImage, drawingDataUrl])
  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent)
  const lockScroll = () => {
    document.body.style.overflow = 'hidden'
  }

  const unlockScroll = () => {
    document.body.style.overflow = 'auto'
  }

  const handleMouseLeave = () => {
    setCursorPosition(null)
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isMobileDevice) return
    const rect = drawingCanvasRef.current?.getBoundingClientRect()
    if (rect) {
      setCursorPosition({
        x: (e.clientX - rect.left) / scaleFactor,
        y: (e.clientY - rect.top) / scaleFactor,
      })
    }
  }

  const draw = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDrawing || !drawingCanvasRef.current) return
    const ctx = drawingCanvasRef.current.getContext('2d')
    const canvas = drawingCanvasRef.current
    const rect = canvas.getBoundingClientRect()
    if (ctx) {
      const scaleX = canvas.width / rect.width
      const scaleY = canvas.height / rect.height
      const { offsetX, offsetY } =
        'touches' in e
          ? getTouchPosition(e)
          : {
              offsetX: (e.nativeEvent.clientX - rect.left) * scaleX,
              offsetY: (e.nativeEvent.clientY - rect.top) * scaleY,
            }

      ctx.lineTo(offsetX, offsetY)
      ctx.stroke()
      ctx.beginPath()
      ctx.moveTo(offsetX, offsetY)
    }
  }

  const startDrawing = (e: React.MouseEvent | React.TouchEvent) => {
    if (!drawingCanvasRef.current) return

    const ctx = drawingCanvasRef.current.getContext('2d')
    const canvas = drawingCanvasRef.current
    const rect = canvas.getBoundingClientRect()
    if (ctx) {
      const scaleX = canvas.width / rect.width
      const scaleY = canvas.height / rect.height
      const { offsetX, offsetY } =
        'touches' in e
          ? getTouchPosition(e)
          : {
              offsetX: (e.nativeEvent.clientX - rect.left) * scaleX,
              offsetY: (e.nativeEvent.clientY - rect.top) * scaleY,
            }

      ctx.globalCompositeOperation = 'xor'
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.6)'
      ctx.lineWidth = penSize
      ctx.lineCap = 'round'
      ctx.beginPath()
      ctx.moveTo(offsetX, offsetY)
      setIsDrawing(true)

      lockScroll()
    }
  }

  const stopDrawing = () => {
    if (isDrawing) {
      setIsDrawing(false)
      const canvas = drawingCanvasRef.current
      if (canvas) {
        const canvasDataUrl = canvas.toDataURL()
        setHistory((prevHistory) => [...prevHistory, canvasDataUrl])
      }
      unlockScroll()
    }
  }

  const getTouchPosition = (e: React.TouchEvent) => {
    const rect = drawingCanvasRef.current?.getBoundingClientRect()
    const touch = e.touches[0]
    if (rect && touch) {
      const scaleX = drawingCanvasRef.current!.width / rect.width
      const scaleY = drawingCanvasRef.current!.height / rect.height
      return {
        offsetX: (touch.clientX - rect.left) * scaleX,
        offsetY: (touch.clientY - rect.top) * scaleY,
      }
    }
    return { offsetX: 0, offsetY: 0 }
  }

  const undoLastAction = () => {
    if (history.length > 0) {
      const canvas = drawingCanvasRef.current
      const ctx = canvas?.getContext('2d')
      const newHistory = [...history]
      newHistory.pop()

      if (canvas && ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        const lastState = newHistory[newHistory.length - 1]
        if (lastState) {
          const img = new Image()
          img.src = lastState
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          }
        }
        setHistory(newHistory)
      }
    }
  }

  const clearCanvas = () => {
    const drawingCanvas = drawingCanvasRef.current
    const canvas = canvasRef.current
    if (canvas && uploadedImage) {
      const ctx = canvas.getContext('2d')
      const img = new Image()
      img.src = uploadedImage

      img.onload = () => {
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          const scaleFactor = Math.min(canvasWidth / img.width, canvasHeight / img.height)
          const imageWidth = img.width * scaleFactor
          const imageHeight = img.height * scaleFactor
          const xOffset = (canvas.width - imageWidth) / 2
          const yOffset = (canvas.height - imageHeight) / 2
          ctx.drawImage(img, xOffset, yOffset, imageWidth, imageHeight)
        }
      }
    }

    if (drawingCanvas) {
      const ctx = drawingCanvas.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height)
      }
    }
  }

  const generateBlackImage = async (): Promise<string> => {
    const drawingCanvas = drawingCanvasRef.current

    if (!drawingCanvas || !uploadedImage) {
      return ''
    }

    const img = new Image()
    img.src = uploadedImage

    return new Promise((resolve, reject) => {
      img.onload = async () => {
        const blackCanvas = document.createElement('canvas')
        blackCanvas.width = img.width
        blackCanvas.height = img.height
        const ctx = blackCanvas.getContext('2d')

        if (ctx) {
          ctx.fillStyle = '#000000'
          ctx.fillRect(0, 0, blackCanvas.width, blackCanvas.height)

          ctx.drawImage(drawingCanvas, 0, 0, img.width, img.height)

          const imageData = ctx.getImageData(0, 0, blackCanvas.width, blackCanvas.height)
          const data = imageData.data

          for (let i = 0; i < data.length; i += 4) {
            if (data[i] !== 0 || data[i + 1] !== 0 || data[i + 2] !== 0) {
              data[i] = 255
              data[i + 1] = 255
              data[i + 2] = 255
            }
          }

          ctx.putImageData(imageData, 0, 0)

          blackCanvas.toBlob(
            (blob) => {
              if (blob) {
                const blackImageURL = URL.createObjectURL(blob)
                setBlackImageURL(blackImageURL)
                resolve(blackImageURL)
              } else {
                reject('Error creando blob para la imagen en blanco y negro')
              }
            },
            'image/jpeg',
            0.6,
          )
        } else {
          reject('Error accediendo al contexto del canvas')
        }
      }

      img.onerror = () => reject('Error al cargar la imagen original')
    })
  }
  const getDrawingDataUrl = (): string | null => {
    const drawingCanvas = drawingCanvasRef.current
    return drawingCanvas ? drawingCanvas.toDataURL('image/png') : null
  }
  const handleGenerateDesign = async () => {
    if (!promptText) {
      openModal(t('MSG_536'))
      return
    }
    const resizedUploadedImage = uploadedImage ? await resizeImage(uploadedImage, 1440, 808) : ''
    const drawingCanvasDataUrl = getDrawingDataUrl()
    let imageFile = ''
    if (resizedUploadedImage) {
      const originalBlob = await fetch(resizedUploadedImage).then((res) => res.blob())
      const originalFile = new File([originalBlob], 'imagen-resized-original.png', {
        type: 'image/png',
      })
      imageFile = await uploadImage(originalFile)
    }

    const blackImageURL = await generateBlackImage()
    const resizedBlackImage = blackImageURL ? await resizeImage(blackImageURL, 1440, 808) : ''

    let blackImageFile = ''
    if (resizedBlackImage) {
      const blackBlob = await fetch(resizedBlackImage).then((res) => res.blob())
      const blackFile = new File([blackBlob], 'black-drawing-resized.png', { type: 'image/png' })
      blackImageFile = await uploadImage(blackFile)
    }

    navigate('/personalization/generate', {
      state: {
        imageFilename: imageFile || '',
        imageMaskFilename: blackImageFile || '',
        prompt: promptText || '',
        noPrompt: noPromptText || '',
        imageFile: resizedUploadedImage || '',
        imageWithDrawing: drawingCanvasDataUrl,
      },
    })
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <div className='relative flex flex-col items-center justify-center bg-white text-black'>
        <div className='absolute inset-0 top-0 z-0 h-[500px] w-full bg-black'></div>
        <h1 className='z-20 ml-6 mt-32 self-start font-montserrat-bold text-4xl text-white'>
          {t('MSG_514')}
        </h1>
        <div className='relative z-10 mt-4 flex w-full max-w-3xl flex-col items-center border border-main bg-white text-center'>
          <div className='relative flex w-full items-center justify-center'>
            {!uploadedImage ? (
              <div className='flex h-96 w-full flex-col items-center justify-center'>
                {!isCameraOpen ? (
                  <>
                    <img
                      src={subir}
                      alt='Subir imagen'
                      className='mb-2 h-24'
                    />
                    {loading ? (
                      <p className='text-md font-medium'>{t('MSG_488')}</p>
                    ) : (
                      <>
                        <p className='text-md font-medium'>{t('MSG_143')}</p>
                        <p className='mb-6 w-3/5 text-sm text-gray-500'>{t('MSG_142')}</p>
                      </>
                    )}
                    <input
                      type='file'
                      className='hidden'
                      id='file-upload'
                      onChange={handleImageUpload}
                    />
                    <label
                      htmlFor='file-upload'
                      className='mt-2 w-72 cursor-pointer rounded-lg bg-main py-1.5 text-white'
                    >
                      {t('MSG_144')}
                    </label>
                    {!isIOS() && (
                      <button
                        onClick={openCamera}
                        className='mt-4 w-72 cursor-pointer rounded-lg bg-main py-1.5 text-white'
                      >
                        {t('MSG_551')}
                      </button>
                    )}
                  </>
                ) : (
                  <div className='relative mt-1'>
                    <video
                      ref={videoRef}
                      autoPlay
                      className='video-preview h-80 w-full'
                    />
                    <button
                      onClick={closeCamera}
                      className='absolute left-9 top-4 z-20 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-main text-white sm:left-4 sm:top-4 md:left-auto md:right-1 md:top-1'
                    >
                      ✕
                    </button>
                    {hasMultipleCameras && (
                      <button
                        onClick={toggleCamera}
                        className='absolute top-4 z-20 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-main text-white sm:right-4 sm:top-4 md:left-1 md:right-auto md:top-1'
                      >
                        <svg
                          width='21'
                          height='21'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4 3.99999V8.99999H4.582M4.582 8.99999C5.24585 7.35812 6.43568 5.9829 7.96503 5.08985C9.49438 4.1968 11.2768 3.8364 13.033 4.06513C14.7891 4.29386 16.4198 5.09878 17.6694 6.35377C18.919 7.60875 19.7168 9.24285 19.938 11M4.582 8.99999H9M20 20V15H19.419M19.419 15C18.7542 16.6409 17.564 18.015 16.0348 18.9073C14.5056 19.7995 12.7237 20.1595 10.9681 19.9309C9.21246 19.7022 7.5822 18.8979 6.33253 17.6437C5.08287 16.3896 4.28435 14.7564 4.062 13M19.419 15H15'
                            stroke='#FFFFFF'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                      </button>
                    )}
                    <div className='mt-2 flex justify-center'>
                      {!isIOS() && (
                        <button
                          onClick={capturePhoto}
                          className='z-50 w-72 cursor-pointer rounded-lg bg-main py-1.5 text-white'
                        >
                          {t('MSG_552')}
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className='relative flex items-center justify-center'>
                <canvas
                  ref={canvasRef}
                  className='mx-auto max-h-[500px] max-w-full object-contain'
                  width={800}
                  height={500}
                />
                <canvas
                  ref={drawingCanvasRef}
                  onMouseDown={startDrawing}
                  onMouseMove={(e) => {
                    handleMouseMove(e)
                    draw(e)
                  }}
                  onMouseUp={stopDrawing}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={startDrawing}
                  onTouchMove={draw}
                  onTouchEnd={stopDrawing}
                  className='absolute mx-auto max-h-[500px] max-w-full cursor-cell object-contain'
                  width={800}
                  height={500}
                />
                {cursorPosition && (
                  <div
                    style={{
                      position: 'absolute',
                      left: cursorPosition.x * scaleFactor - penSize / 2,
                      top: cursorPosition.y * scaleFactor - penSize / 2,
                      width: penSize,
                      height: penSize,
                      borderRadius: '50%',
                      border: '1px solid rgba(255, 255, 255, 0.5)',
                      background: 'rgba(0, 0, 0, 0.3)',
                      pointerEvents: 'none',
                    }}
                    className='z-50'
                  />
                )}
              </div>
            )}
          </div>
          {uploadedImage && (
            <div className='my-4 flex flex-col items-center'>
              <div className='flex w-full flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0'>
                <input
                  type='file'
                  className='hidden rounded bg-main p-2 text-white'
                  id='file-upload-reload'
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
                <label
                  htmlFor='file-upload-reload'
                  className='flex transform items-center justify-center rounded-md bg-main px-4 py-2 text-center text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
                >
                  {t('MSG_144')}
                </label>
                <button
                  onClick={clearCanvas}
                  className='flex transform items-center justify-center rounded-md bg-main px-4 py-2 text-center text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
                >
                  {t('MSG_510')}
                </button>
                <button
                  onClick={undoLastAction}
                  className='flex transform items-center justify-center rounded-md bg-main px-4 py-2 text-center text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
                >
                  {t('MSG_509')}
                </button>
                {!isIOS() && (
                  <button
                    onClick={openCamera}
                    className='flex transform items-center justify-center rounded-md bg-main px-4 py-2 text-center text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
                  >
                    {t('MSG_551')}
                  </button>
                )}
                <div className='flex items-center sm:ml-auto'>
                  <label className='mr-1'>{t('MSG_511')}</label>
                  <input
                    type='range'
                    min='30'
                    max='100'
                    value={penSize}
                    onChange={(e) => setPenSize(Number(e.target.value))}
                    className='custom-pen-range'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='relative z-10 mt-4 flex w-full max-w-3xl flex-col items-center border border-main bg-white p-6'>
          <div className='w-full'>
            <div className='mb-4'>
              <label className='mb-2 block text-left'>{t('MSG_532')}</label>
              <div className='flex flex-col items-start sm:flex-row'>
                <textarea
                  className='w-full resize-none border p-2'
                  value={promptText}
                  onChange={(e) => setPromptText(e.target.value)}
                />
                <button
                  className='relative ml-0 mt-4 flex items-center rounded-full bg-main shadow-lg sm:ml-4 sm:mt-0'
                  onClick={openModalInfo}
                >
                  <div className='relative z-20 flex h-14 w-14 items-center justify-center rounded-full bg-white'>
                    <div
                      className='absolute inset-0 z-10 animate-border-snake rounded-full bg-gradient-to-r from-main via-gray-300 to-gray-500 p-0.5'
                      style={{
                        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        maskComposite: 'exclude',
                        WebkitMaskComposite: 'xor',
                        backgroundSize: '200% 200%',
                      }}
                    ></div>
                    <img
                      src={InfoIcon}
                      alt='Ícono'
                      className='h-7 w-7'
                    />
                  </div>
                  <div className='relative flex h-14 w-16 items-start justify-start rounded-r-xl bg-main p-1'>
                    <p className='mr-2 text-center font-creato-bold text-xs leading-tight text-white'>
                      {t('MSG_549')}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className='flex w-full flex-col items-center justify-between sm:flex-row'>
            <div className='mr-0 flex-grow sm:mr-16'>
              <label className='mb-2 block text-left'>{t('MSG_531')}</label>
              <input
                type='text'
                className='mt-1 w-full border p-2'
                value={noPromptText}
                onChange={(e) => setNoPromptText(e.target.value)}
              />
            </div>
            <div className='mt-8'>
              <button
                onClick={handleGenerateDesign}
                className='flex w-[280px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800 sm:mt-0'
              >
                {t('MSG_37')}
                <div className='ml-4 flex h-6 w-10 items-center rounded-lg border-[1.5px] border-white bg-main'>
                  <img
                    src={estrella}
                    className='ml-1 h-4 w-4'
                  />
                  <p className='ml-1 text-white'>1</p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <IncidentModal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          message={modalMessage}
          onCloseNavigate={closeModal}
        />
        <ModalInfo
          isOpen={isModalOpenInfo}
          onClose={closeModalInfo}
        />
      </div>
      <Footer />
    </div>
  )
}
