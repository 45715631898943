import { useState } from 'react'
import IncidentModal from './IncidentModal'
import { store } from '../store'

type CardEstanciaProps = {
  imageUrl?: string
  text: string
  isSelected: boolean
  onClick: () => void
  acceso?: boolean
  WithoutImage?: boolean
}

export default function CardGrid({
  imageUrl,
  text,
  isSelected,
  onClick,
  acceso,
  WithoutImage = false,
}: CardEstanciaProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  const handleCardClick = () => {
    if (WithoutImage) {
      setModalMessage(t('MSG_375'))
      setModalIsOpen(true)
      return
    }
    if (acceso) {
      onClick()
    } else {
      setModalMessage(t('MSG_374'))
      setModalIsOpen(true)
    }
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div>
      <div
        onClick={handleCardClick}
        className='relative cursor-pointer overflow-hidden bg-white shadow-lg'
      >
        <div
          className={`border-1 absolute left-4 top-1 z-20 rounded-md border px-3 py-1 text-xs font-bold shadow-lg ${
            isSelected ? 'bg-main text-white' : 'bg-white text-main'
          } flex items-center`}
        >
          {isSelected && <span className='mr-2 text-green-500'>✔</span>}
          {text}
        </div>
        <div className='relative mt-4 h-56 w-full'>
          <img
            src={imageUrl}
            alt={text}
            className={`h-full w-full object-cover ${
              isSelected ? 'border-4 border-main' : ''
            } ${!acceso ? 'cursor-default bg-white opacity-30' : ''}`}
          />
        </div>
      </div>
      <IncidentModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        message={modalMessage}
        onCloseNavigate={closeModal}
      />
    </div>
  )
}
