import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import t from '../../store/languageActions'
import AlertInfo from '../AlertInfo'
import { useWebsocketContext } from '../Context'
import Footer from '../Footer'
import ImageGeneratefinish from '../ImageGeneratefinish'
import Navbar from '../Navbar'
import Notification from '../Notification'
import IncidentModal from '../IncidentModal'
import type { Respuesta } from './DecorationStylesPage'
import { ScrollProgressBar } from '@components/ScrollProgressBar'
export default function DecorationGeneratePage() {
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as {
    concatenatedSelections: string
    texto: string
    respuestas: Respuesta
    imageFile: File
    img: string
    concatIds: number[]
    isChecked?: boolean
  }
  const {
    concatenatedSelections,
    texto,
    respuestas,
    imageFile,
    img,
    concatIds = [],
    isChecked,
  } = state

  const [isGenerating, setIsGenerating] = useState(false)
  const [generatedImageSrc, setGeneratedImageSrc] = useState(img)
  const { creditosActuales, connection } = useWebsocketContext()
  const isFirstRender = useRef(true)
  const isInvokingRef = useRef(false)
  const [showNotification, setShowNotification] = useState(false)
  const [showIncidentModal, setShowIncidentModal] = useState(false)
  const [incidentMessage, setIncidentMessage] = useState('')
  const [idTrabUrlia, setIdTrabUrlia] = useState<number | null>(null)
  useLayoutEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])
  async function invokeAskToImage() {
    if (connection) {
      try {
        const textoDelFront = concatenatedSelections ? `${texto}, ${concatenatedSelections}` : texto

        const tipoTrabajo = isChecked ? 4 : 2

        const payload = {
          idRespuestasIA: concatIds,
          tipoTrabajo: tipoTrabajo,
          textoDelFront: textoDelFront,
          imageFilename: imageFile,
        }
        console.log('Payload enviado al backend:', payload)
        const result = await connection.invoke('AskToImage', payload)
        console.log('AskToImage invocation successful', result)
        if (creditosActuales === 0) {
          setShowNotification(true)
          return false
        }
        if (result.incidencia) {
          setIncidentMessage(result.incidencia)
          setShowIncidentModal(true)
          return false
        }
        return true
      } catch (error) {
        console.log('Error invoking AskToImage', error)
        return false
      }
    }
    return false
  }
  async function handleSaveClick(formData: { title: string; description: string }) {
    if (connection) {
      try {
        await connection.send('GuardarDatosUltTrabajo', {
          tituloProyecto: formData.title,
          descripcionProyecto: formData.description,
        })
        console.log('Data saved successfully')
      } catch (error) {
        console.log('Error saving data', error)
      }
    }
  }
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      if (!isInvokingRef.current) {
        isInvokingRef.current = true
        invokeAskToImage().then((success) => {
          if (success) {
            setIsGenerating(true)
          }
          isInvokingRef.current = false
        })
      }
    }
  }, [])

  useEffect(() => {
    const handleNotification = (data: { urls: string[]; idTrabUrlia: number }) => {
      console.log('NotificacionTrabajosIA', data)
      if (data.urls.length > 0) {
        setGeneratedImageSrc(
          data.urls[0] ||
            'https://api.for2inspiration.ai/Images/MotorIA/nLjjDu/f4ca0cd7-1ca6-46d4-9082-3dacd1792a59-0.png',
        )
        setIsGenerating(false)
        console.log('NotificacionTrabajosIA', data.idTrabUrlia)
        setIdTrabUrlia(data.idTrabUrlia)
      }
    }
    if (connection) {
      connection.on('NotificacionTrabajosIA', handleNotification)
    }
    return () => {
      if (connection) {
        connection.off('NotificacionTrabajosIA', handleNotification)
      }
    }
  }, [connection])
  const message = concatenatedSelections ? `${texto}, ${concatenatedSelections}` : texto
  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageGeneratefinish
        textTop={t('MSG_70')}
        imgSrc={generatedImageSrc}
        isGenerating={isGenerating}
        handleGenerateClick={() => {
          if (!isInvokingRef.current) {
            isInvokingRef.current = true
            invokeAskToImage().then((success) => {
              if (success) {
                setIsGenerating(true)
              }
              isInvokingRef.current = false
            })
          }
        }}
        handleSaveClick={handleSaveClick}
        showComparingButton={true}
        img={img}
        idTrabUrlia={idTrabUrlia}
      />
      <AlertInfo
        message={message}
        navigateTo='/decoration/styles2'
        state={{
          _concatenatedSelections: concatenatedSelections,
          texto: concatenatedSelections ? `${texto}, ${concatenatedSelections}` : texto,
          respuestas,
          concatIds,
          img,
          imageFile,
        }}
        isSpecial={true}
      />
      <div className='-mt-2 flex justify-end pl-4'>
        <button
          className='mr-[10%] h-9 w-3/5 rounded-lg bg-main text-white lg:ml-4 lg:mt-0 lg:w-1/5'
          onClick={() => navigate('/user')}
        >
          {t('MSG_163')}
        </button>
      </div>
      <Footer />
      {showIncidentModal && (
        <IncidentModal
          isOpen={showIncidentModal}
          onRequestClose={() => setShowIncidentModal(false)}
          message={incidentMessage}
          onCloseNavigate={() => navigate('/decoration')}
        />
      )}
      {showNotification && (
        <Notification
          isOpen={showNotification}
          onRequestClose={() => setShowNotification(false)}
        />
      )}
    </div>
  )
}
