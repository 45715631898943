import estrella from '@assets/estrella.png'
import subir from '@assets/subir-imagen.png'
import { useState, useRef, useEffect } from 'react'
import uploadImage from '../proxies/ia/uploadImage'
import { store } from '../store'
import IncidentModal from './IncidentModal'
import isIOS from './utils/isIOS'

type ImageOptionsProps = {
  title: string
  subtitle?: string
  buttonText?: string
  imgSrc: string
  imageWithDrawing?: string
  onButtonClick?: () => void
  isUpload?: boolean
  setImageSrc?: (src: string) => void
  setImageFile?: (id: string) => void
}

export default function ImageOptions({
  title,
  subtitle,
  buttonText,
  imgSrc,
  imageWithDrawing,
  onButtonClick,
  isUpload = false,
  setImageSrc,
  setImageFile,
}: ImageOptionsProps) {
  const texto = store((s) => s.translations)
  function t(t: string) {
    return texto[t] ?? t
  }

  const [uploadedImage, setUploadedImage] = useState<string | null>(imgSrc || null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment')
  const [hasMultipleCameras, setHasMultipleCameras] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  function openModal(message: string) {
    setModalMessage(message)
    setModalOpen(true)
  }

  function closeModal() {
    setModalOpen(false)
  }
  useEffect(() => {
    setUploadedImage(imgSrc)
  }, [imgSrc])
  function resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const img = new Image()
      const reader = new FileReader()
      reader.onload = (e) => {
        img.src = e.target?.result as string
        img.onload = () => {
          const originalWidth = img.width
          const originalHeight = img.height
          let width = originalWidth
          let height = originalHeight
          if (originalWidth > maxWidth || originalHeight > maxHeight) {
            const widthScale = maxWidth / originalWidth
            const heightScale = maxHeight / originalHeight
            const scale = Math.min(widthScale, heightScale)
            width = Math.floor(originalWidth * scale)
            height = Math.floor(originalHeight * scale)
          }

          const canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          if (ctx) {
            ctx.drawImage(img, 0, 0, width, height)

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const resizedFile = new File([blob], file.name, { type: 'image/jpeg' })
                  resolve(resizedFile)
                } else {
                  reject(new Error('Error resizing image'))
                }
              },
              'image/jpeg',
              0.8,
            )
          }
        }
      }
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }

  const openCamera = async () => {
    setIsCameraOpen(true)
    setUploadedImage(null)

    if (navigator.mediaDevices?.enumerateDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevices = devices.filter((device) => device.kind === 'videoinput')
      setHasMultipleCameras(videoDevices.length > 1)
    }
    if (navigator.mediaDevices?.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: facingMode },
        })
        if (videoRef.current) videoRef.current.srcObject = stream
      } catch (error) {
        console.error('Error al acceder a la cámara:', error)
      }
    }
  }

  const captureAndUploadPhoto = async () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas')
      canvas.width = videoRef.current.videoWidth
      canvas.height = videoRef.current.videoHeight
      const context = canvas.getContext('2d')

      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)

        const blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'image/jpeg', 0.8),
        )

        if (blob) {
          const file = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' })
          const fileUrl = URL.createObjectURL(file)

          setUploadedImage(fileUrl)
          if (setImageSrc) {
            setImageSrc(fileUrl)
          }

          setLoading(true)

          try {
            const resizedFile = await resizeImage(file, 1440, 808)
            const img = new Image()
            img.src = URL.createObjectURL(resizedFile)

            const blobImageSrc = URL.createObjectURL(resizedFile)
            setUploadedImage(blobImageSrc)
            if (setImageSrc) {
              setImageSrc(blobImageSrc)
            }
            const fileName = await uploadImage(resizedFile)
            if (setImageFile) {
              setImageFile(fileName)
            }
          } catch (error) {
            console.error('Error al cargar la imagen:', error)
            openModal(t('MSG_533'))
          } finally {
            setLoading(false)
          }
        }
      }
    }
  }

  const closeCamera = async () => {
    if (isIOS()) {
      await captureAndUploadPhoto()
    }

    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream
      const tracks = stream.getTracks()
      tracks.forEach((track) => track.stop())
    }

    setIsCameraOpen(false)
  }

  const toggleCamera = () => {
    setFacingMode((prev) => (prev === 'user' ? 'environment' : 'user'))
    closeCamera()
    openCamera()
  }

  const capturePhoto = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas')
      canvas.width = videoRef.current.videoWidth
      canvas.height = videoRef.current.videoHeight
      const context = canvas.getContext('2d')
      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)
        canvas.toBlob(
          async (blob) => {
            if (blob) {
              const file = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' })
              const fileUrl = URL.createObjectURL(file)
              setUploadedImage(fileUrl)
              if (setImageSrc) {
                setImageSrc(fileUrl)
              }

              const fileName = await uploadImage(file)
              if (setImageFile) {
                setImageFile(fileName)
              }
            }
          },
          'image/jpeg',
          0.8,
        )
        closeCamera()
      }
    }
  }

  async function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      if (isCameraOpen) {
        closeCamera()
      }

      const file = event.target.files[0]
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (!allowedTypes.includes(file.type)) {
        openModal(t('MSG_487'))
        return
      }
      setLoading(true)

      try {
        const resizedFile = await resizeImage(file, 1440, 808)
        const img = new Image()
        img.src = URL.createObjectURL(resizedFile)

        const blobImageSrc = URL.createObjectURL(resizedFile)
        setUploadedImage(blobImageSrc)
        if (setImageSrc) {
          setImageSrc(blobImageSrc)
        }
        const fileName = await uploadImage(resizedFile)
        if (setImageFile) {
          setImageFile(fileName)
        }
      } catch (error) {
        console.error('Error al cargar la imagen:', error)
        openModal(t('MSG_533'))
      } finally {
        setLoading(false)
      }
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  const imageToDisplay = uploadedImage || imgSrc
  return (
    <div className='relative flex flex-col items-center justify-center bg-white text-black'>
      <div className='absolute inset-0 top-0 z-0 h-[500px] w-full bg-black'></div>
      <h1 className='z-20 ml-8 mt-32 self-start font-montserrat-bold text-4xl text-white'>
        {title}
      </h1>
      <div className='relative z-10 mt-4 flex w-full max-w-3xl flex-col items-center border border-main bg-white text-center'>
        <div className='relative w-full'>
          {isUpload && !uploadedImage && !isCameraOpen ? (
            <div className='flex h-96 w-full flex-col items-center justify-center'>
              <img
                src={subir}
                alt='Upload'
                className='mx-2 h-24'
              />
              {loading ? (
                <p className='text-md font-medium'>{t('MSG_488')}</p>
              ) : (
                <>
                  <p className='text-md font-medium'>{t('MSG_143')}</p>
                  <p className='mb-6 w-3/5 text-sm text-gray-500'>{t('MSG_142')}</p>
                </>
              )}
              <input
                type='file'
                className='mt-4 hidden'
                id='file-upload'
                ref={fileInputRef}
                onChange={handleImageUpload}
              />
              <label
                htmlFor='file-upload'
                className='mt-4 w-72 cursor-pointer rounded-lg bg-main py-1.5 text-white'
              >
                {t('MSG_144')}
              </label>
              {!isCameraOpen && !isIOS() && (
                <button
                  onClick={openCamera}
                  className='mt-3 w-72 cursor-pointer rounded-lg bg-main py-1.5 text-white'
                >
                  {t('MSG_551')}
                </button>
              )}
            </div>
          ) : (
            <>
              {!isCameraOpen && (
                <div className='relative w-full'>
                  <img
                    src={imageToDisplay}
                    alt={title}
                    className='h-96 w-full object-contain'
                  />
                  {imageWithDrawing && (
                    <img
                      src={imageWithDrawing}
                      alt='Overlay Drawing'
                      className='absolute left-0 top-0 h-96 w-full object-contain'
                      style={{ pointerEvents: 'none' }}
                    />
                  )}
                </div>
              )}
            </>
          )}
          {isCameraOpen && (
            <div className='relative mt-4'>
              <video
                ref={videoRef}
                autoPlay
                className='video-preview h-96 w-full'
              />
              <button
                onClick={closeCamera}
                className='absolute right-4 top-4 z-20 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-main p-2 text-white md:right-8 md:top-8 md:h-10 md:w-10'
              >
                ✕
              </button>
              {hasMultipleCameras && (
                <button
                  onClick={toggleCamera}
                  className='absolute left-4 top-4 z-20 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-main p-2 text-white md:left-8 md:top-8 md:h-10 md:w-10'
                >
                  <svg
                    width='21'
                    height='21'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 3.99999V8.99999H4.582M4.582 8.99999C5.24585 7.35812 6.43568 5.9829 7.96503 5.08985C9.49438 4.1968 11.2768 3.8364 13.033 4.06513C14.7891 4.29386 16.4198 5.09878 17.6694 6.35377C18.919 7.60875 19.7168 9.24285 19.938 11M4.582 8.99999H9M20 20V15H19.419M19.419 15C18.7542 16.6409 17.564 18.015 16.0348 18.9073C14.5056 19.7995 12.7237 20.1595 10.9681 19.9309C9.21246 19.7022 7.5822 18.8979 6.33253 17.6437C5.08287 16.3896 4.28435 14.7564 4.062 13M19.419 15H15'
                      stroke='#FFFFFF'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </button>
              )}
              <div className='mt-4 flex justify-center'>
                {!isIOS() && (
                  <button
                    onClick={capturePhoto}
                    className='w-72 cursor-pointer rounded-lg bg-main py-1.5 text-white'
                  >
                    {t('MSG_552')}
                  </button>
                )}
              </div>
            </div>
          )}

          {!isUpload && (
            <div className='absolute inset-0 flex flex-col items-center justify-center p-4'>
              <p className='font-chopin text-lg text-white'>{subtitle}</p>
            </div>
          )}
          {isUpload && uploadedImage && (
            <>
              <div className='flex flex-col items-center justify-center p-4'>
                <button
                  className='-mb-6 w-72 cursor-pointer rounded-lg bg-main py-1.5 text-white'
                  onClick={handleButtonClick}
                >
                  {t('MSG_144')}
                </button>
                <input
                  type='file'
                  className='hidden'
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
              </div>
              {!isCameraOpen && !isIOS() && (
                <button
                  onClick={openCamera}
                  className='mt-4 w-72 cursor-pointer rounded-lg bg-main py-1.5 text-white'
                >
                  {t('MSG_551')}
                </button>
              )}
            </>
          )}
        </div>

        <div className='flex flex-1 justify-center py-4'>
          {onButtonClick && buttonText && (
            <button
              className='flex w-[280px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800'
              onClick={onButtonClick}
            >
              {buttonText}
              <div className='ml-4 flex h-6 w-10 items-center rounded-lg border-[1.5px] border-white bg-main'>
                <img
                  src={estrella}
                  alt='Star image'
                  className='ml-1 h-4 w-4'
                />
                <p className='ml-1 text-white'>1</p>
              </div>
            </button>
          )}
        </div>
        <IncidentModal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          message={modalMessage}
          onCloseNavigate={closeModal}
        />
      </div>
    </div>
  )
}
