import Modal from 'react-modal'
import t from '../store/languageActions'
import { useEffect } from 'react'

type IncidentModalProps = {
  isOpen: boolean
  onRequestClose: () => void
  message: string
  onCloseNavigate: () => void
}

export default function IncidentModal({
  isOpen,
  onRequestClose,
  message,
  onCloseNavigate,
}: IncidentModalProps) {
  const handleButtonClick = () => {
    onRequestClose()
    onCloseNavigate()
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Incident Notification'
      className='relative mx-auto my-8 max-h-[80vh] w-full max-w-2xl overflow-y-auto rounded-lg bg-white p-10 text-black shadow-lg'
      overlayClassName='fixed z-20 inset-0 bg-black bg-opacity-75 flex items-center justify-center'
    >
      <button
        onClick={onRequestClose}
        className='absolute right-3 top-0 text-2xl text-gray-400 hover:text-gray-200'
      >
        ×
      </button>
      <div className='flex flex-col items-center py-8'>
        <p className='mt-6 font-creato-light text-lg'>{message}</p>
        <button
          onClick={handleButtonClick}
          className='mb-5 mt-10 flex w-[270px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800'
        >
          {t('MSG_369')}
        </button>
      </div>
    </Modal>
  )
}
