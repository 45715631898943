import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import d1 from '@assets/Gallery/AnimationsGallery/1/a.jpg'
import d2 from '@assets/Gallery/AnimationsGallery/1/b.jpg'
import d3 from '@assets/Gallery/AnimationsGallery/1/c.jpg'
import d4 from '@assets/Gallery/AnimationsGallery/1/d.jpg'
import d5 from '@assets/Gallery/AnimationsGallery/1/e.jpg'
import d6 from '@assets/Gallery/AnimationsGallery/1/f.jpg'
import d7 from '@assets/Gallery/AnimationsGallery/2/g.jpg'
import d8 from '@assets/Gallery/AnimationsGallery/2/h.jpg'
import d9 from '@assets/Gallery/AnimationsGallery/2/i.jpg'
import d10 from '@assets/Gallery/AnimationsGallery/2/j.jpg'
import d11 from '@assets/Gallery/AnimationsGallery/2/k.jpg'
import d12 from '@assets/Gallery/AnimationsGallery/2/l.jpg'
import d13 from '@assets/Gallery/AnimationsGallery/2/m.jpg'
import d14 from '@assets/Gallery/AnimationsGallery/2/n.jpg'
import d15 from '@assets/Gallery/AnimationsGallery/2/o.jpg'
import d16 from '@assets/Gallery/AnimationsGallery/2/p.jpg'
import d17 from '@assets/Gallery/AnimationsGallery/3/aa.jpg'
import d18 from '@assets/Gallery/AnimationsGallery/3/ab.jpg'
import d19 from '@assets/Gallery/AnimationsGallery/3/q.jpg'
import d20 from '@assets/Gallery/AnimationsGallery/3/r.jpg'
import d21 from '@assets/Gallery/AnimationsGallery/3/s.jpg'
import d22 from '@assets/Gallery/AnimationsGallery/3/t.jpg'
import d23 from '@assets/Gallery/AnimationsGallery/3/u.jpg'
import d24 from '@assets/Gallery/AnimationsGallery/3/v.jpg'
import d25 from '@assets/Gallery/AnimationsGallery/3/w.jpg'
import d26 from '@assets/Gallery/AnimationsGallery/3/x.jpg'
import d27 from '@assets/Gallery/AnimationsGallery/3/y.jpg'
import d28 from '@assets/Gallery/AnimationsGallery/3/z.jpg'

import d29 from '@assets/Gallery/AnimationsGallery/4/ac.jpg'
import d30 from '@assets/Gallery/AnimationsGallery/4/ad.jpg'
import d31 from '@assets/Gallery/AnimationsGallery/4/ae.jpg'
import d32 from '@assets/Gallery/AnimationsGallery/4/af.jpg'
import d33 from '@assets/Gallery/AnimationsGallery/4/ag.jpg'
import d34 from '@assets/Gallery/AnimationsGallery/4/ah.jpg'
import d35 from '@assets/Gallery/AnimationsGallery/4/ai.jpg'
import d36 from '@assets/Gallery/AnimationsGallery/4/aj.jpg'

import d37 from '@assets/Gallery/AnimationsGallery/5/ak.jpg'
import d38 from '@assets/Gallery/AnimationsGallery/5/al.jpg'
import d39 from '@assets/Gallery/AnimationsGallery/5/am.jpg'
import d40 from '@assets/Gallery/AnimationsGallery/5/an.jpg'
import d41 from '@assets/Gallery/AnimationsGallery/5/ao.jpg'
import d42 from '@assets/Gallery/AnimationsGallery/5/ap.jpg'
import d43 from '@assets/Gallery/AnimationsGallery/5/aq.jpg'
import d44 from '@assets/Gallery/AnimationsGallery/5/ar.jpg'
import d45 from '@assets/Gallery/AnimationsGallery/5/as.jpg'

import d46 from '@assets/Gallery/AnimationsGallery/6/at.jpg'
import d47 from '@assets/Gallery/AnimationsGallery/6/au.jpg'
import d48 from '@assets/Gallery/AnimationsGallery/6/av.jpg'
import d49 from '@assets/Gallery/AnimationsGallery/6/aw.jpg'
import d50 from '@assets/Gallery/AnimationsGallery/6/ax.jpg'
import d51 from '@assets/Gallery/AnimationsGallery/6/ay.jpg'

type AnimatedImagesProps = {
  onClick: (item: any) => void
  isModal: boolean
  group: string[]
  animationType: 'fade' | 'zoomBlur' | 'zoom' | 'morph'
  currentIndex?: number
}

export const imageGroups6 = [
  [d46, d47],
  [d48, d49],
  [d50, d51],
]

export const imageGroups5 = [
  [d37, d38],
  [d39, d40],
  [d41, d42],
  [d43, d44],
  [d45, d44],
]

export const imageGroups4 = [
  [d29, d30],
  [d31, d32],
  [d33, d34],
  [d35, d36],
]

export const imageGroups3 = [
  [d7, d8, d9, d10],
  [d11, d12, d13, d14],
  [d15, d16, d7, d8],
]

export const imageGroups2 = [
  [d17, d18, d19],
  [d20, d21, d22],
  [d23, d24, d25],
  [d26, d27, d28],
]

export const imageGroups = [
  [d1, d2],
  [d3, d4],
  [d5, d6],
]

export default function AnimatedImages({
  onClick,
  isModal,
  group,
  animationType,
}: AnimatedImagesProps) {
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % group.length)
    }, 3000)
    return () => clearInterval(interval)
  }, [group.length])

  return (
    <div
      className={`relative ${isModal ? 'h-[80vh] w-[90vw]' : 'h-[450px] w-full'} flex cursor-pointer items-center justify-center bg-black`}
    >
      {group.map((image, index) => {
        if (animationType === 'fade') {
          return (
            <motion.div
              key={index}
              className='absolute flex h-full w-full items-center justify-center'
              initial={{ opacity: 0 }}
              animate={{ opacity: index === currentImage ? 1 : 0 }}
              transition={{ duration: 1.5 }}
              onClick={() => onClick?.(image)}
            >
              <img
                src={image}
                alt={`animated-photo-${index}`}
                className={`h-full w-full ${isModal ? 'object-contain md:-ml-24' : 'object-cover'}`}
              />
            </motion.div>
          )
        } else if (animationType === 'zoomBlur') {
          return (
            <motion.div
              key={index}
              className='absolute flex h-full w-full items-center justify-center'
              initial={{ scale: isModal ? 1 : 0.8, opacity: 0, filter: 'blur(10px)' }}
              animate={{
                scale: index === currentImage ? 1 : isModal ? 1 : 0.5,
                opacity: index === currentImage ? 0.8 : 0,
                filter: index === currentImage ? 'blur(0px)' : 'blur(8px)',
              }}
              transition={{ duration: 2 }}
              onClick={() => onClick?.(image)}
            >
              <img
                src={image}
                alt={`animated-photo-${index}`}
                className={`h-full w-full ${isModal ? 'object-contain md:-ml-24' : 'object-cover'}`}
              />
            </motion.div>
          )
        } else if (animationType === 'zoom') {
          return (
            <motion.div
              key={index}
              className='absolute flex h-full w-full items-center justify-center'
              initial={{ scale: isModal ? 1 : 0.8, opacity: 0 }}
              animate={{
                scale: index === currentImage ? 1 : isModal ? 1 : 0.8,
                opacity: index === currentImage ? 0.9 : 0,
              }}
              transition={{ duration: 1.5 }}
              onClick={() => onClick?.(image)}
            >
              <img
                src={image}
                alt={`animated-photo-${index}`}
                className={`h-full w-full ${isModal ? 'object-contain md:-ml-24' : 'object-cover'}`}
              />
            </motion.div>
          )
        } else if (animationType === 'morph') {
          return (
            <motion.div
              key={index}
              className='absolute flex h-full w-full items-center justify-center overflow-hidden'
              initial={{ scale: 1, borderRadius: '0%', backgroundColor: 'transparent' }}
              animate={{
                scale: [1, 0.6, 0.6, 1],
                backgroundColor: [
                  'transparent',
                  'rgba(0,0,0,0.5)',
                  'rgba(0,0,0,0.5)',
                  'transparent',
                ],
                borderRadius: ['0%', '10%', '10%', '0%'],
              }}
              transition={{ duration: 2 }}
              onClick={() => onClick?.(image)}
            >
              <img
                key={index}
                src={group[currentImage]}
                alt={`animated-photo-${index}`}
                className={`h-full w-full ${isModal ? 'object-contain md:-ml-24' : 'object-cover'}`}
              />
            </motion.div>
          )
        }
        return null
      })}
    </div>
  )
}
