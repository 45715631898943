import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import t from '../../store/languageActions'
import AlertInfo from '../AlertInfo'
import CardGrid from '../CardGrid'
import { useWebsocketContext } from '../Context'
import type { Respuesta } from '../decoration/DecorationStylesPage'
import Footer from '../Footer'
import ImageOptions from '../ImageOptions'
import Navbar from '../Navbar'
import getLastWord from '../utils/getLastWord'
import type { LocationState, Pregunta } from '@components/inspiration/InspirationStyles2Page'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function RedecorationStylesPage() {
  const { creditosActuales } = useWebsocketContext()
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedCard, setSelectedCard] = useState<number | null>(null)
  const { texto, respuestas, img, imageFile, concatIds, blackImageFile, imageWithDrawing } =
    location.state as LocationState
  console.log('imageWithDrawing', img)

  const preguntasEspeciales = useMemo(() => {
    return respuestas?.filter((p: Pregunta) => !p.especial) || []
  }, [respuestas])

  useLayoutEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])

  useEffect(() => {
    if (texto) {
      const lastWord = getLastWord(texto)
      const index = preguntasEspeciales[0]?.respuestas.findIndex(
        (respuesta) => respuesta.texto === lastWord,
      )
      if (index !== -1) {
        if (!index) return
        setSelectedCard(index)
      }
    }
  }, [texto, preguntasEspeciales])

  function handleCardClick(index: number) {
    setSelectedCard(index)
    if (
      preguntasEspeciales &&
      preguntasEspeciales[0] &&
      preguntasEspeciales[0].respuestas &&
      preguntasEspeciales[0].respuestas[index]
    ) {
      const first = preguntasEspeciales[0]
      if (!first || !('respuestas' in first)) return
      const currRespuestas = first.respuestas[index]
      if (!currRespuestas) return
      const selectedRespuestaTexto = currRespuestas.texto

      const currentTexts = texto.split(',').map((txt) => txt.trim())
      if (currentTexts.length === 1) {
        currentTexts.push(selectedRespuestaTexto)
        return navigate('/redecoration/styles2', {
          state: {
            respuestas,
            texto: currentTexts.join(', '),
            concatIds: [...concatIds, currRespuestas.idRespuestasIa],
            img,
            imageFile,
            blackImageFile,
            imageWithDrawing,
          },
        })
      }
      window.scrollTo(0, 0)
      return navigate('/redecoration/styles2', {
        state: {
          respuestas,
          texto: currentTexts
            .map((el, i) => (i === currentTexts.length - 1 ? selectedRespuestaTexto : el))
            .join(', '),
          concatIds: [...concatIds, currRespuestas.idRespuestasIa],
          img: img,
          imageFile,
          blackImageFile,
          imageWithDrawing,
        },
      })
    }
  }

  function handleGenerateClick() {
    navigate('/redecoration/styles2/generate', {
      state: { texto, img: img, imageFile: imageFile, blackImageFile: blackImageFile },
    })
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageOptions
        title={t('MSG_68')}
        buttonText={t('MSG_37')}
        imgSrc={img}
        onButtonClick={handleGenerateClick}
        setImageSrc={() => {}}
        // imageWithDrawing={imageWithDrawing}
      />
      <AlertInfo
        message={texto || ''}
        navigateTo='/redecoration'
        state={{
          img,
          imageWithDrawing,
        }}
      />
      <h1 className='my-8 text-center text-2xl font-bold text-main'>{t('MSG_140')}</h1>
      <p className='-mt-7 mb-4 font-light'> {t('MSG_141')}</p>
      <div className='container mx-auto px-4'>
        <div className='-mx-2 flex flex-wrap'>
          {preguntasEspeciales &&
            preguntasEspeciales.length > 0 &&
            preguntasEspeciales[0]?.respuestas.map((respuesta: Respuesta, index: number) => (
              <div
                key={index}
                className='w-full p-2 sm:w-1/2 md:w-1/3 lg:w-1/4'
              >
                <CardGrid
                  imageUrl={respuesta.imagenUrl1}
                  text={respuesta.texto}
                  isSelected={selectedCard === index}
                  onClick={() => handleCardClick(index)}
                  acceso={respuesta.acceso}
                />
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
