import { getBaseHeaders, getBaseUrl } from '..'

const mockResponse = [
  {
    idPreguntasIa: 0,
    orden: 0,
    imagenUrl1: 'string',
    imagenUrl2: 'string',
    texto: 'string',
    respuestas: [
      {
        idRespuestasIa: 0,
        orden: 0,
        imagenUrl1: 'string',
        imagenUrl2: 'string',
        texto: 'string',
      },
    ],
  },
]
export default async function getQuestions(
  //@ts-expect-error
  s = (a: string) => {},
  id: string | number,
) {
  const info = await fetch(getBaseUrl(`api/PreguntasIA/getpreguntas/${id}`), getBaseHeaders())

  const body = await info.json()

  s(body)
  return body as any as typeof mockResponse
}
