import t from '../../store/languageActions'
import image1 from '@assets/Carousel1DecorationPageInfo/dormitorioAirbnb.jpg'
import image2 from '@assets/Carousel1DecorationPageInfo/dormitorioEscandinavo.jpg'
import image3 from '@assets/Carousel1DecorationPageInfo/dormitorioFuturista.jpg'
import image4 from '@assets/Carousel1DecorationPageInfo/dormitorioHampton.jpg'
import image5 from '@assets/Carousel1DecorationPageInfo/dormitorioRosa.jpg'
import image6 from '@assets/Carousel1DecorationPageInfo/dormitorioTropical.jpg'
import image7 from '@assets/Carousel1DecorationPageInfo/dormitorioZen.jpg'
import image8 from '@assets/Carousel1DecorationPageInfo/dormitorioArcdeco.jpg'
import image9 from '@assets/Carousel1DecorationPageInfo/dormitorioBarroco.jpg'
import image10 from '@assets/Carousel1DecorationPageInfo/dormitorioBauhaus.jpg'
import image11 from '@assets/Carousel1DecorationPageInfo/dormitorioBiophilic.jpg'
import image12 from '@assets/Carousel1DecorationPageInfo/dormitorioBohemio.jpg'
import image13 from '@assets/Carousel1DecorationPageInfo/dormitorioClasico.jpg'
import image14 from '@assets/Carousel1DecorationPageInfo/dormitorioContemporaneo.jpg'
import image15 from '@assets/Carousel1DecorationPageInfo/dormitorioCyberpunk.jpg'
import image16 from '@assets/Carousel1DecorationPageInfo/dormitorioDecoart.jpg'
import image17 from '@assets/Carousel1DecorationPageInfo/dormitorioFarmhouse.jpg'
import image18 from '@assets/Carousel1DecorationPageInfo/dormitorioGaming.jpg'
import image19 from '@assets/Carousel1DecorationPageInfo/dormitorioHalloween.jpg'
import image20 from '@assets/Carousel1DecorationPageInfo/dormitorioIndustrial.jpg'
import image21 from '@assets/Carousel1DecorationPageInfo/dormitorioJapones.jpg'
import image22 from '@assets/Carousel1DecorationPageInfo/dormitorioMaximalista.jpg'
import image23 from '@assets/Carousel1DecorationPageInfo/dormitorioMediterraneo.jpg'
import image24 from '@assets/Carousel1DecorationPageInfo/dormitorioMidcentury.jpg'
import image25 from '@assets/Carousel1DecorationPageInfo/dormitorioMinimalista.jpg'
import image26 from '@assets/Carousel1DecorationPageInfo/dormitorioModerno.jpg'
import image27 from '@assets/Carousel1DecorationPageInfo/dormitorioMontana.jpg'
import image28 from '@assets/Carousel1DecorationPageInfo/dormitorioNavidad.jpg'
import image29 from '@assets/Carousel1DecorationPageInfo/dormitorioRustico.jpg'
import image30 from '@assets/Carousel1DecorationPageInfo/dormitorioTribal.jpg'
import image31 from '@assets/Carousel1DecorationPageInfo/dormitorioVintage.jpg'
const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image30,
  image31,
]
export default function CarouselDecorationPage() {
  return (
    <div className='relative w-full overflow-hidden'>
      <div className='absolute left-0 top-2 w-full space-y-4 bg-black p-2 text-left text-white sm:top-6 sm:p-6'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_128')}
        </h1>
        <h2 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_129')}
        </h2>
      </div>
      <div className='mt-60 flex animate-slide-left-infinite'>
        {images.map((el, index) => (
          <div
            key={index}
            className='h-1/2 w-1/2 flex-shrink-0'
          >
            <img
              className='h-full w-full object-cover'
              src={el}
              alt={`Carousel image generate IA image number ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
