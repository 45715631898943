import { getBaseHeaders, getBaseUrl } from '..'

export default async function randomHeadImage(setImage: (data: string) => void) {
  const info = await fetch(getBaseUrl('api/Portada/obtimagenrandom'), getBaseHeaders())
  // console.log('New random image:', { info }) as any as ReadableStream

  const data = await info.text()
  setImage(data)
  return data
}
