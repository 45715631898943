import t from '../../store/languageActions'
import d1 from '@assets/Carousel1RenderingPageInfo/1.jpg'
import d2 from '@assets/Carousel1RenderingPageInfo/2.jpg'
import d3 from '@assets/Carousel1RenderingPageInfo/3.jpg'
import d4 from '@assets/Carousel1RenderingPageInfo/4.jpg'
import d5 from '@assets/Carousel1RenderingPageInfo/5.jpg'
import d6 from '@assets/Carousel1RenderingPageInfo/6.jpg'
import d7 from '@assets/Carousel1RenderingPageInfo/7.jpg'
import d8 from '@assets/Carousel1RenderingPageInfo/8.jpg'
import d9 from '@assets/Carousel1RenderingPageInfo/9.jpg'
import d10 from '@assets/Carousel1RenderingPageInfo/10.jpg'
import d11 from '@assets/Carousel1RenderingPageInfo/11.jpg'
import d12 from '@assets/Carousel1RenderingPageInfo/12.jpg'
import leftImage from '@assets/RenderingPageInfo/Izquierda.jpg'
import rightImage from '@assets/RenderingPageInfo/derecha.jpg'

const images = [d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12]
const repeatedImages = Array(5).fill(images).flat()

export default function CarouselRendering() {
  return (
    <div className='relative w-full overflow-hidden'>
      <div className='absolute left-0 top-2 w-full space-y-4 bg-black p-2 text-left text-white sm:top-6 sm:p-6'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_458')}
        </h1>
      </div>
      <div className='mt-72 flex animate-slide-left-infinite sm:mt-60'>
        {repeatedImages.map((el, index) => (
          <div
            key={index}
            className='h-1/2 w-1/2 flex-shrink-0'
          >
            <img
              className='max-h-[722px] w-full object-cover'
              src={el}
              alt={`gallery-photo-${index}`}
            />
          </div>
        ))}
      </div>

      <div className='my-4 w-full space-y-4 bg-black p-2 text-left text-white sm:p-6'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_459')}
        </h1>
      </div>

      <div className='flex justify-center gap-0 px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64'>
        <div className='max-w-[45%] flex-1'>
          <img
            src={leftImage}
            alt='Drawing of a kitchen with earth colors'
            className='h-auto w-full object-cover'
          />
        </div>
        <div className='max-w-[45%] flex-1'>
          <img
            src={rightImage}
            alt='AI-generated photo of kitchen drawing'
            className='h-auto w-full object-cover'
          />
        </div>
      </div>
      <div className='my-4 w-full space-y-4 bg-black p-2 text-left text-white sm:p-6'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_460')}
        </h1>
      </div>
    </div>
  )
}
