// export const images = Array.from({ length: 272 }, (_, i) => ({
//   id: i + 2,
//   src: `/src/assets/Gallery/ImagesGallery/${i + 2}.jpg`,
//   type: 'image',
// }))

import d2 from '@assets/Gallery/ImagesGallery/2.jpg'
import d3 from '@assets/Gallery/ImagesGallery/3.jpg'
import d4 from '@assets/Gallery/ImagesGallery/4.jpg'
import d5 from '@assets/Gallery/ImagesGallery/5.jpg'
import d6 from '@assets/Gallery/ImagesGallery/6.jpg'
import d7 from '@assets/Gallery/ImagesGallery/7.jpg'
import d8 from '@assets/Gallery/ImagesGallery/8.jpg'
import d9 from '@assets/Gallery/ImagesGallery/9.jpg'
import d10 from '@assets/Gallery/ImagesGallery/10.jpg'
import d11 from '@assets/Gallery/ImagesGallery/11.jpg'
import d12 from '@assets/Gallery/ImagesGallery/12.jpg'
import d13 from '@assets/Gallery/ImagesGallery/13.jpg'
import d14 from '@assets/Gallery/ImagesGallery/14.jpg'
import d15 from '@assets/Gallery/ImagesGallery/15.jpg'
import d16 from '@assets/Gallery/ImagesGallery/16.jpg'
import d17 from '@assets/Gallery/ImagesGallery/17.jpg'
import d18 from '@assets/Gallery/ImagesGallery/18.jpg'
import d19 from '@assets/Gallery/ImagesGallery/19.jpg'
import d20 from '@assets/Gallery/ImagesGallery/20.jpg'
import d21 from '@assets/Gallery/ImagesGallery/21.jpg'
import d22 from '@assets/Gallery/ImagesGallery/22.jpg'
import d23 from '@assets/Gallery/ImagesGallery/23.jpg'
import d24 from '@assets/Gallery/ImagesGallery/24.jpg'
import d25 from '@assets/Gallery/ImagesGallery/25.jpg'
import d26 from '@assets/Gallery/ImagesGallery/26.jpg'
import d27 from '@assets/Gallery/ImagesGallery/27.jpg'
import d28 from '@assets/Gallery/ImagesGallery/28.jpg'
import d29 from '@assets/Gallery/ImagesGallery/29.jpg'
import d30 from '@assets/Gallery/ImagesGallery/30.jpg'
import d31 from '@assets/Gallery/ImagesGallery/31.jpg'
import d32 from '@assets/Gallery/ImagesGallery/32.jpg'
import d33 from '@assets/Gallery/ImagesGallery/33.jpg'
import d34 from '@assets/Gallery/ImagesGallery/34.jpg'
import d35 from '@assets/Gallery/ImagesGallery/35.jpg'
import d36 from '@assets/Gallery/ImagesGallery/36.jpg'
import d37 from '@assets/Gallery/ImagesGallery/37.jpg'
import d38 from '@assets/Gallery/ImagesGallery/38.jpg'
import d39 from '@assets/Gallery/ImagesGallery/39.jpg'
import d40 from '@assets/Gallery/ImagesGallery/40.jpg'
import d41 from '@assets/Gallery/ImagesGallery/41.jpg'
import d42 from '@assets/Gallery/ImagesGallery/42.jpg'
import d43 from '@assets/Gallery/ImagesGallery/43.jpg'
import d44 from '@assets/Gallery/ImagesGallery/44.jpg'
import d45 from '@assets/Gallery/ImagesGallery/45.jpg'
import d46 from '@assets/Gallery/ImagesGallery/46.jpg'
import d47 from '@assets/Gallery/ImagesGallery/47.jpg'
import d48 from '@assets/Gallery/ImagesGallery/48.jpg'
import d49 from '@assets/Gallery/ImagesGallery/49.jpg'
import d50 from '@assets/Gallery/ImagesGallery/50.jpg'
import d51 from '@assets/Gallery/ImagesGallery/51.jpg'
import d52 from '@assets/Gallery/ImagesGallery/52.jpg'
import d53 from '@assets/Gallery/ImagesGallery/53.jpg'
import d54 from '@assets/Gallery/ImagesGallery/54.jpg'
import d55 from '@assets/Gallery/ImagesGallery/55.jpg'
import d56 from '@assets/Gallery/ImagesGallery/56.jpg'
import d57 from '@assets/Gallery/ImagesGallery/57.jpg'
import d58 from '@assets/Gallery/ImagesGallery/58.jpg'
import d59 from '@assets/Gallery/ImagesGallery/59.jpg'
import d60 from '@assets/Gallery/ImagesGallery/60.jpg'
import d61 from '@assets/Gallery/ImagesGallery/61.jpg'
import d62 from '@assets/Gallery/ImagesGallery/62.jpg'
import d63 from '@assets/Gallery/ImagesGallery/63.jpg'
import d64 from '@assets/Gallery/ImagesGallery/64.jpg'
import d65 from '@assets/Gallery/ImagesGallery/65.jpg'
import d66 from '@assets/Gallery/ImagesGallery/66.jpg'
import d67 from '@assets/Gallery/ImagesGallery/67.jpg'
import d68 from '@assets/Gallery/ImagesGallery/68.jpg'
import d69 from '@assets/Gallery/ImagesGallery/69.jpg'
import d70 from '@assets/Gallery/ImagesGallery/70.jpg'
import d71 from '@assets/Gallery/ImagesGallery/71.jpg'
import d72 from '@assets/Gallery/ImagesGallery/72.jpg'
import d73 from '@assets/Gallery/ImagesGallery/73.jpg'
import d74 from '@assets/Gallery/ImagesGallery/74.jpg'
import d75 from '@assets/Gallery/ImagesGallery/75.jpg'
import d76 from '@assets/Gallery/ImagesGallery/76.jpg'
import d77 from '@assets/Gallery/ImagesGallery/77.jpg'
import d78 from '@assets/Gallery/ImagesGallery/78.jpg'
import d79 from '@assets/Gallery/ImagesGallery/79.jpg'
import d80 from '@assets/Gallery/ImagesGallery/80.jpg'
import d81 from '@assets/Gallery/ImagesGallery/81.jpg'
import d82 from '@assets/Gallery/ImagesGallery/82.jpg'
import d83 from '@assets/Gallery/ImagesGallery/83.jpg'
import d84 from '@assets/Gallery/ImagesGallery/84.jpg'
import d85 from '@assets/Gallery/ImagesGallery/85.jpg'
import d86 from '@assets/Gallery/ImagesGallery/86.jpg'
import d87 from '@assets/Gallery/ImagesGallery/87.jpg'
import d88 from '@assets/Gallery/ImagesGallery/88.jpg'
import d89 from '@assets/Gallery/ImagesGallery/89.jpg'
import d90 from '@assets/Gallery/ImagesGallery/90.jpg'
import d91 from '@assets/Gallery/ImagesGallery/91.jpg'
import d92 from '@assets/Gallery/ImagesGallery/92.jpg'
import d93 from '@assets/Gallery/ImagesGallery/93.jpg'
import d94 from '@assets/Gallery/ImagesGallery/94.jpg'
import d95 from '@assets/Gallery/ImagesGallery/95.jpg'
import d96 from '@assets/Gallery/ImagesGallery/96.jpg'
import d97 from '@assets/Gallery/ImagesGallery/97.jpg'
import d98 from '@assets/Gallery/ImagesGallery/98.jpg'
import d99 from '@assets/Gallery/ImagesGallery/99.jpg'
import d100 from '@assets/Gallery/ImagesGallery/100.jpg'
import d101 from '@assets/Gallery/ImagesGallery/101.jpg'
import d102 from '@assets/Gallery/ImagesGallery/102.jpg'
import d103 from '@assets/Gallery/ImagesGallery/103.jpg'
import d104 from '@assets/Gallery/ImagesGallery/104.jpg'
import d105 from '@assets/Gallery/ImagesGallery/105.jpg'
import d106 from '@assets/Gallery/ImagesGallery/106.jpg'
import d107 from '@assets/Gallery/ImagesGallery/107.jpg'
import d108 from '@assets/Gallery/ImagesGallery/108.jpg'
import d109 from '@assets/Gallery/ImagesGallery/109.jpg'
import d110 from '@assets/Gallery/ImagesGallery/110.jpg'
import d111 from '@assets/Gallery/ImagesGallery/111.jpg'
import d112 from '@assets/Gallery/ImagesGallery/112.jpg'
import d113 from '@assets/Gallery/ImagesGallery/113.jpg'
import d114 from '@assets/Gallery/ImagesGallery/114.jpg'
import d115 from '@assets/Gallery/ImagesGallery/115.jpg'
import d116 from '@assets/Gallery/ImagesGallery/116.jpg'
import d117 from '@assets/Gallery/ImagesGallery/117.jpg'
import d118 from '@assets/Gallery/ImagesGallery/118.jpg'
import d119 from '@assets/Gallery/ImagesGallery/119.jpg'
import d120 from '@assets/Gallery/ImagesGallery/120.jpg'
import d121 from '@assets/Gallery/ImagesGallery/121.jpg'
import d122 from '@assets/Gallery/ImagesGallery/122.jpg'
import d123 from '@assets/Gallery/ImagesGallery/123.jpg'
import d124 from '@assets/Gallery/ImagesGallery/124.jpg'
import d125 from '@assets/Gallery/ImagesGallery/125.jpg'
import d126 from '@assets/Gallery/ImagesGallery/126.jpg'
import d127 from '@assets/Gallery/ImagesGallery/127.jpg'
import d128 from '@assets/Gallery/ImagesGallery/128.jpg'
import d129 from '@assets/Gallery/ImagesGallery/129.jpg'
import d130 from '@assets/Gallery/ImagesGallery/130.jpg'
import d131 from '@assets/Gallery/ImagesGallery/131.jpg'
import d132 from '@assets/Gallery/ImagesGallery/132.jpg'
import d133 from '@assets/Gallery/ImagesGallery/133.jpg'
import d134 from '@assets/Gallery/ImagesGallery/134.jpg'
import d135 from '@assets/Gallery/ImagesGallery/135.jpg'
import d136 from '@assets/Gallery/ImagesGallery/136.jpg'
import d137 from '@assets/Gallery/ImagesGallery/137.jpg'
import d138 from '@assets/Gallery/ImagesGallery/138.jpg'
import d139 from '@assets/Gallery/ImagesGallery/139.jpg'
import d140 from '@assets/Gallery/ImagesGallery/140.jpg'
import d141 from '@assets/Gallery/ImagesGallery/141.jpg'
import d142 from '@assets/Gallery/ImagesGallery/142.jpg'
import d143 from '@assets/Gallery/ImagesGallery/143.jpg'
import d144 from '@assets/Gallery/ImagesGallery/144.jpg'
import d145 from '@assets/Gallery/ImagesGallery/145.jpg'
import d146 from '@assets/Gallery/ImagesGallery/146.jpg'
import d147 from '@assets/Gallery/ImagesGallery/147.jpg'
import d148 from '@assets/Gallery/ImagesGallery/148.jpg'
import d149 from '@assets/Gallery/ImagesGallery/149.jpg'
import d150 from '@assets/Gallery/ImagesGallery/150.jpg'
import d151 from '@assets/Gallery/ImagesGallery/151.jpg'
import d152 from '@assets/Gallery/ImagesGallery/152.jpg'
import d153 from '@assets/Gallery/ImagesGallery/153.jpg'
import d154 from '@assets/Gallery/ImagesGallery/154.jpg'
import d155 from '@assets/Gallery/ImagesGallery/155.jpg'
import d156 from '@assets/Gallery/ImagesGallery/156.jpg'
import d157 from '@assets/Gallery/ImagesGallery/157.jpg'
import d158 from '@assets/Gallery/ImagesGallery/158.jpg'
import d159 from '@assets/Gallery/ImagesGallery/159.jpg'
import d160 from '@assets/Gallery/ImagesGallery/160.jpg'
import d161 from '@assets/Gallery/ImagesGallery/161.jpg'
import d162 from '@assets/Gallery/ImagesGallery/162.jpg'
import d163 from '@assets/Gallery/ImagesGallery/163.jpg'
import d164 from '@assets/Gallery/ImagesGallery/164.jpg'
import d165 from '@assets/Gallery/ImagesGallery/165.jpg'
import d166 from '@assets/Gallery/ImagesGallery/166.jpg'
import d167 from '@assets/Gallery/ImagesGallery/167.jpg'
import d168 from '@assets/Gallery/ImagesGallery/168.jpg'
import d169 from '@assets/Gallery/ImagesGallery/169.jpg'
import d170 from '@assets/Gallery/ImagesGallery/170.jpg'
import d171 from '@assets/Gallery/ImagesGallery/171.jpg'
import d172 from '@assets/Gallery/ImagesGallery/172.jpg'
import d173 from '@assets/Gallery/ImagesGallery/173.jpg'
import d174 from '@assets/Gallery/ImagesGallery/174.jpg'
import d175 from '@assets/Gallery/ImagesGallery/175.jpg'
import d176 from '@assets/Gallery/ImagesGallery/176.jpg'
import d177 from '@assets/Gallery/ImagesGallery/177.jpg'
import d178 from '@assets/Gallery/ImagesGallery/178.jpg'
import d179 from '@assets/Gallery/ImagesGallery/179.jpg'
import d180 from '@assets/Gallery/ImagesGallery/180.jpg'
import d181 from '@assets/Gallery/ImagesGallery/181.jpg'
import d182 from '@assets/Gallery/ImagesGallery/182.jpg'
import d183 from '@assets/Gallery/ImagesGallery/183.jpg'
import d184 from '@assets/Gallery/ImagesGallery/184.jpg'
import d185 from '@assets/Gallery/ImagesGallery/185.jpg'
import d186 from '@assets/Gallery/ImagesGallery/186.jpg'
import d187 from '@assets/Gallery/ImagesGallery/187.jpg'
import d188 from '@assets/Gallery/ImagesGallery/188.jpg'
import d189 from '@assets/Gallery/ImagesGallery/189.jpg'
import d190 from '@assets/Gallery/ImagesGallery/190.jpg'
import d191 from '@assets/Gallery/ImagesGallery/191.jpg'
import d192 from '@assets/Gallery/ImagesGallery/192.jpg'
import d193 from '@assets/Gallery/ImagesGallery/193.jpg'
import d194 from '@assets/Gallery/ImagesGallery/194.jpg'
import d195 from '@assets/Gallery/ImagesGallery/195.jpg'
import d196 from '@assets/Gallery/ImagesGallery/196.jpg'
import d197 from '@assets/Gallery/ImagesGallery/197.jpg'
import d198 from '@assets/Gallery/ImagesGallery/198.jpg'
import d199 from '@assets/Gallery/ImagesGallery/199.jpg'
import d200 from '@assets/Gallery/ImagesGallery/200.jpg'
import d201 from '@assets/Gallery/ImagesGallery/201.jpg'
import d202 from '@assets/Gallery/ImagesGallery/202.jpg'
import d203 from '@assets/Gallery/ImagesGallery/203.jpg'
import d204 from '@assets/Gallery/ImagesGallery/204.jpg'
import d205 from '@assets/Gallery/ImagesGallery/205.jpg'
import d206 from '@assets/Gallery/ImagesGallery/206.jpg'
import d207 from '@assets/Gallery/ImagesGallery/207.jpg'
import d208 from '@assets/Gallery/ImagesGallery/208.jpg'
import d209 from '@assets/Gallery/ImagesGallery/209.jpg'
import d210 from '@assets/Gallery/ImagesGallery/210.jpg'
import d211 from '@assets/Gallery/ImagesGallery/211.jpg'
import d212 from '@assets/Gallery/ImagesGallery/212.jpg'
import d213 from '@assets/Gallery/ImagesGallery/213.jpg'
import d214 from '@assets/Gallery/ImagesGallery/214.jpg'
import d215 from '@assets/Gallery/ImagesGallery/215.jpg'
import d216 from '@assets/Gallery/ImagesGallery/216.jpg'
import d217 from '@assets/Gallery/ImagesGallery/217.jpg'
import d218 from '@assets/Gallery/ImagesGallery/218.jpg'
import d219 from '@assets/Gallery/ImagesGallery/219.jpg'
import d220 from '@assets/Gallery/ImagesGallery/220.jpg'
import d221 from '@assets/Gallery/ImagesGallery/221.jpg'
import d222 from '@assets/Gallery/ImagesGallery/222.jpg'
import d223 from '@assets/Gallery/ImagesGallery/223.jpg'
import d224 from '@assets/Gallery/ImagesGallery/224.jpg'
import d225 from '@assets/Gallery/ImagesGallery/225.jpg'
import d226 from '@assets/Gallery/ImagesGallery/226.jpg'
import d227 from '@assets/Gallery/ImagesGallery/227.jpg'
import d228 from '@assets/Gallery/ImagesGallery/228.jpg'
import d229 from '@assets/Gallery/ImagesGallery/229.jpg'
import d230 from '@assets/Gallery/ImagesGallery/230.jpg'
import d231 from '@assets/Gallery/ImagesGallery/231.jpg'
import d232 from '@assets/Gallery/ImagesGallery/232.jpg'
import d233 from '@assets/Gallery/ImagesGallery/233.jpg'
import d234 from '@assets/Gallery/ImagesGallery/234.jpg'
import d235 from '@assets/Gallery/ImagesGallery/235.jpg'
import d236 from '@assets/Gallery/ImagesGallery/236.jpg'
import d237 from '@assets/Gallery/ImagesGallery/237.jpg'
import d238 from '@assets/Gallery/ImagesGallery/238.jpg'
import d239 from '@assets/Gallery/ImagesGallery/239.jpg'
import d240 from '@assets/Gallery/ImagesGallery/240.jpg'
import d241 from '@assets/Gallery/ImagesGallery/241.jpg'
import d242 from '@assets/Gallery/ImagesGallery/242.jpg'
import d243 from '@assets/Gallery/ImagesGallery/243.jpg'
import d244 from '@assets/Gallery/ImagesGallery/244.jpg'
import d245 from '@assets/Gallery/ImagesGallery/245.jpg'
import d246 from '@assets/Gallery/ImagesGallery/246.jpg'
import d247 from '@assets/Gallery/ImagesGallery/247.jpg'
import d248 from '@assets/Gallery/ImagesGallery/248.jpg'
import d249 from '@assets/Gallery/ImagesGallery/249.jpg'
import d250 from '@assets/Gallery/ImagesGallery/250.jpg'
import d251 from '@assets/Gallery/ImagesGallery/251.jpg'
import d252 from '@assets/Gallery/ImagesGallery/252.jpg'
import d253 from '@assets/Gallery/ImagesGallery/253.jpg'
import d254 from '@assets/Gallery/ImagesGallery/254.jpg'
import d255 from '@assets/Gallery/ImagesGallery/255.jpg'
import d256 from '@assets/Gallery/ImagesGallery/256.jpg'
import d257 from '@assets/Gallery/ImagesGallery/257.jpg'
import d258 from '@assets/Gallery/ImagesGallery/258.jpg'
import d259 from '@assets/Gallery/ImagesGallery/259.jpg'
import d260 from '@assets/Gallery/ImagesGallery/260.jpg'
import d261 from '@assets/Gallery/ImagesGallery/261.jpg'
import d262 from '@assets/Gallery/ImagesGallery/262.jpg'
import d263 from '@assets/Gallery/ImagesGallery/263.jpg'
import d264 from '@assets/Gallery/ImagesGallery/264.jpg'
import d265 from '@assets/Gallery/ImagesGallery/265.jpg'
import d266 from '@assets/Gallery/ImagesGallery/266.jpg'
import d267 from '@assets/Gallery/ImagesGallery/267.jpg'
import d268 from '@assets/Gallery/ImagesGallery/268.jpg'
import d269 from '@assets/Gallery/ImagesGallery/269.jpg'
import d270 from '@assets/Gallery/ImagesGallery/270.jpg'
import d271 from '@assets/Gallery/ImagesGallery/271.jpg'
import d272 from '@assets/Gallery/ImagesGallery/272.jpg'
import d273 from '@assets/Gallery/ImagesGallery/273.jpg'

export const images = [
  { id: 2, src: d2, type: 'image' },
  { id: 3, src: d3, type: 'image' },
  { id: 4, src: d4, type: 'image' },
  { id: 5, src: d5, type: 'image' },
  { id: 6, src: d6, type: 'image' },
  { id: 7, src: d7, type: 'image' },
  { id: 8, src: d8, type: 'image' },
  { id: 9, src: d9, type: 'image' },
  { id: 10, src: d10, type: 'image' },
  { id: 11, src: d11, type: 'image' },
  { id: 12, src: d12, type: 'image' },
  { id: 13, src: d13, type: 'image' },
  { id: 14, src: d14, type: 'image' },
  { id: 15, src: d15, type: 'image' },
  { id: 16, src: d16, type: 'image' },
  { id: 17, src: d17, type: 'image' },
  { id: 18, src: d18, type: 'image' },
  { id: 19, src: d19, type: 'image' },
  { id: 20, src: d20, type: 'image' },
  { id: 21, src: d21, type: 'image' },
  { id: 22, src: d22, type: 'image' },
  { id: 23, src: d23, type: 'image' },
  { id: 24, src: d24, type: 'image' },
  { id: 25, src: d25, type: 'image' },
  { id: 26, src: d26, type: 'image' },
  { id: 27, src: d27, type: 'image' },
  { id: 28, src: d28, type: 'image' },
  { id: 29, src: d29, type: 'image' },
  { id: 30, src: d30, type: 'image' },
  { id: 31, src: d31, type: 'image' },
  { id: 32, src: d32, type: 'image' },
  { id: 33, src: d33, type: 'image' },
  { id: 34, src: d34, type: 'image' },
  { id: 35, src: d35, type: 'image' },
  { id: 36, src: d36, type: 'image' },
  { id: 37, src: d37, type: 'image' },
  { id: 38, src: d38, type: 'image' },
  { id: 39, src: d39, type: 'image' },
  { id: 40, src: d40, type: 'image' },
  { id: 41, src: d41, type: 'image' },
  { id: 42, src: d42, type: 'image' },
  { id: 43, src: d43, type: 'image' },
  { id: 44, src: d44, type: 'image' },
  { id: 45, src: d45, type: 'image' },
  { id: 46, src: d46, type: 'image' },
  { id: 47, src: d47, type: 'image' },
  { id: 48, src: d48, type: 'image' },
  { id: 49, src: d49, type: 'image' },
  { id: 50, src: d50, type: 'image' },
  { id: 51, src: d51, type: 'image' },
  { id: 52, src: d52, type: 'image' },
  { id: 53, src: d53, type: 'image' },
  { id: 54, src: d54, type: 'image' },
  { id: 55, src: d55, type: 'image' },
  { id: 56, src: d56, type: 'image' },
  { id: 57, src: d57, type: 'image' },
  { id: 58, src: d58, type: 'image' },
  { id: 59, src: d59, type: 'image' },
  { id: 60, src: d60, type: 'image' },
  { id: 61, src: d61, type: 'image' },
  { id: 62, src: d62, type: 'image' },
  { id: 63, src: d63, type: 'image' },
  { id: 64, src: d64, type: 'image' },
  { id: 65, src: d65, type: 'image' },
  { id: 66, src: d66, type: 'image' },
  { id: 67, src: d67, type: 'image' },
  { id: 68, src: d68, type: 'image' },
  { id: 69, src: d69, type: 'image' },
  { id: 70, src: d70, type: 'image' },
  { id: 71, src: d71, type: 'image' },
  { id: 72, src: d72, type: 'image' },
  { id: 73, src: d73, type: 'image' },
  { id: 74, src: d74, type: 'image' },
  { id: 75, src: d75, type: 'image' },
  { id: 76, src: d76, type: 'image' },
  { id: 77, src: d77, type: 'image' },
  { id: 78, src: d78, type: 'image' },
  { id: 79, src: d79, type: 'image' },
  { id: 80, src: d80, type: 'image' },
  { id: 81, src: d81, type: 'image' },
  { id: 82, src: d82, type: 'image' },
  { id: 83, src: d83, type: 'image' },
  { id: 84, src: d84, type: 'image' },
  { id: 85, src: d85, type: 'image' },
  { id: 86, src: d86, type: 'image' },
  { id: 87, src: d87, type: 'image' },
  { id: 88, src: d88, type: 'image' },
  { id: 89, src: d89, type: 'image' },
  { id: 90, src: d90, type: 'image' },
  { id: 91, src: d91, type: 'image' },
  { id: 92, src: d92, type: 'image' },
  { id: 93, src: d93, type: 'image' },
  { id: 94, src: d94, type: 'image' },
  { id: 95, src: d95, type: 'image' },
  { id: 96, src: d96, type: 'image' },
  { id: 97, src: d97, type: 'image' },
  { id: 98, src: d98, type: 'image' },
  { id: 99, src: d99, type: 'image' },
  { id: 100, src: d100, type: 'image' },
  { id: 101, src: d101, type: 'image' },
  { id: 102, src: d102, type: 'image' },
  { id: 103, src: d103, type: 'image' },
  { id: 104, src: d104, type: 'image' },
  { id: 105, src: d105, type: 'image' },
  { id: 106, src: d106, type: 'image' },
  { id: 107, src: d107, type: 'image' },
  { id: 108, src: d108, type: 'image' },
  { id: 109, src: d109, type: 'image' },
  { id: 110, src: d110, type: 'image' },
  { id: 111, src: d111, type: 'image' },
  { id: 112, src: d112, type: 'image' },
  { id: 113, src: d113, type: 'image' },
  { id: 114, src: d114, type: 'image' },
  { id: 115, src: d115, type: 'image' },
  { id: 116, src: d116, type: 'image' },
  { id: 117, src: d117, type: 'image' },
  { id: 118, src: d118, type: 'image' },
  { id: 119, src: d119, type: 'image' },
  { id: 120, src: d120, type: 'image' },
  { id: 121, src: d121, type: 'image' },
  { id: 122, src: d122, type: 'image' },
  { id: 123, src: d123, type: 'image' },
  { id: 124, src: d124, type: 'image' },
  { id: 125, src: d125, type: 'image' },
  { id: 126, src: d126, type: 'image' },
  { id: 127, src: d127, type: 'image' },
  { id: 128, src: d128, type: 'image' },
  { id: 129, src: d129, type: 'image' },
  { id: 130, src: d130, type: 'image' },
  { id: 131, src: d131, type: 'image' },
  { id: 132, src: d132, type: 'image' },
  { id: 133, src: d133, type: 'image' },
  { id: 134, src: d134, type: 'image' },
  { id: 135, src: d135, type: 'image' },
  { id: 136, src: d136, type: 'image' },
  { id: 137, src: d137, type: 'image' },
  { id: 138, src: d138, type: 'image' },
  { id: 139, src: d139, type: 'image' },
  { id: 140, src: d140, type: 'image' },
  { id: 141, src: d141, type: 'image' },
  { id: 142, src: d142, type: 'image' },
  { id: 143, src: d143, type: 'image' },
  { id: 144, src: d144, type: 'image' },
  { id: 145, src: d145, type: 'image' },
  { id: 146, src: d146, type: 'image' },
  { id: 147, src: d147, type: 'image' },
  { id: 148, src: d148, type: 'image' },
  { id: 149, src: d149, type: 'image' },
  { id: 150, src: d150, type: 'image' },
  { id: 151, src: d151, type: 'image' },
  { id: 152, src: d152, type: 'image' },
  { id: 153, src: d153, type: 'image' },
  { id: 154, src: d154, type: 'image' },
  { id: 155, src: d155, type: 'image' },
  { id: 156, src: d156, type: 'image' },
  { id: 157, src: d157, type: 'image' },
  { id: 158, src: d158, type: 'image' },
  { id: 159, src: d159, type: 'image' },
  { id: 160, src: d160, type: 'image' },
  { id: 161, src: d161, type: 'image' },
  { id: 162, src: d162, type: 'image' },
  { id: 163, src: d163, type: 'image' },
  { id: 164, src: d164, type: 'image' },
  { id: 165, src: d165, type: 'image' },
  { id: 166, src: d166, type: 'image' },
  { id: 167, src: d167, type: 'image' },
  { id: 168, src: d168, type: 'image' },
  { id: 169, src: d169, type: 'image' },
  { id: 170, src: d170, type: 'image' },
  { id: 171, src: d171, type: 'image' },
  { id: 172, src: d172, type: 'image' },
  { id: 173, src: d173, type: 'image' },
  { id: 174, src: d174, type: 'image' },
  { id: 175, src: d175, type: 'image' },
  { id: 176, src: d176, type: 'image' },
  { id: 177, src: d177, type: 'image' },
  { id: 178, src: d178, type: 'image' },
  { id: 179, src: d179, type: 'image' },
  { id: 180, src: d180, type: 'image' },
  { id: 181, src: d181, type: 'image' },
  { id: 182, src: d182, type: 'image' },
  { id: 183, src: d183, type: 'image' },
  { id: 184, src: d184, type: 'image' },
  { id: 185, src: d185, type: 'image' },
  { id: 186, src: d186, type: 'image' },
  { id: 187, src: d187, type: 'image' },
  { id: 188, src: d188, type: 'image' },
  { id: 189, src: d189, type: 'image' },
  { id: 190, src: d190, type: 'image' },
  { id: 191, src: d191, type: 'image' },
  { id: 192, src: d192, type: 'image' },
  { id: 193, src: d193, type: 'image' },
  { id: 194, src: d194, type: 'image' },
  { id: 195, src: d195, type: 'image' },
  { id: 196, src: d196, type: 'image' },
  { id: 197, src: d197, type: 'image' },
  { id: 198, src: d198, type: 'image' },
  { id: 199, src: d199, type: 'image' },
  { id: 200, src: d200, type: 'image' },
  { id: 201, src: d201, type: 'image' },
  { id: 202, src: d202, type: 'image' },
  { id: 203, src: d203, type: 'image' },
  { id: 204, src: d204, type: 'image' },
  { id: 205, src: d205, type: 'image' },
  { id: 206, src: d206, type: 'image' },
  { id: 207, src: d207, type: 'image' },
  { id: 208, src: d208, type: 'image' },
  { id: 209, src: d209, type: 'image' },
  { id: 210, src: d210, type: 'image' },
  { id: 211, src: d211, type: 'image' },
  { id: 212, src: d212, type: 'image' },
  { id: 213, src: d213, type: 'image' },
  { id: 214, src: d214, type: 'image' },
  { id: 215, src: d215, type: 'image' },
  { id: 216, src: d216, type: 'image' },
  { id: 217, src: d217, type: 'image' },
  { id: 218, src: d218, type: 'image' },
  { id: 219, src: d219, type: 'image' },
  { id: 220, src: d220, type: 'image' },
  { id: 221, src: d221, type: 'image' },
  { id: 222, src: d222, type: 'image' },
  { id: 223, src: d223, type: 'image' },
  { id: 224, src: d224, type: 'image' },
  { id: 225, src: d225, type: 'image' },
  { id: 226, src: d226, type: 'image' },
  { id: 227, src: d227, type: 'image' },
  { id: 228, src: d228, type: 'image' },
  { id: 229, src: d229, type: 'image' },
  { id: 230, src: d230, type: 'image' },
  { id: 231, src: d231, type: 'image' },
  { id: 232, src: d232, type: 'image' },
  { id: 233, src: d233, type: 'image' },
  { id: 234, src: d234, type: 'image' },
  { id: 235, src: d235, type: 'image' },
  { id: 236, src: d236, type: 'image' },
  { id: 237, src: d237, type: 'image' },
  { id: 238, src: d238, type: 'image' },
  { id: 239, src: d239, type: 'image' },
  { id: 240, src: d240, type: 'image' },
  { id: 241, src: d241, type: 'image' },
  { id: 242, src: d242, type: 'image' },
  { id: 243, src: d243, type: 'image' },
  { id: 244, src: d244, type: 'image' },
  { id: 245, src: d245, type: 'image' },
  { id: 246, src: d246, type: 'image' },
  { id: 247, src: d247, type: 'image' },
  { id: 248, src: d248, type: 'image' },
  { id: 249, src: d249, type: 'image' },
  { id: 250, src: d250, type: 'image' },
  { id: 251, src: d251, type: 'image' },
  { id: 252, src: d252, type: 'image' },
  { id: 253, src: d253, type: 'image' },
  { id: 254, src: d254, type: 'image' },
  { id: 255, src: d255, type: 'image' },
  { id: 256, src: d256, type: 'image' },
  { id: 257, src: d257, type: 'image' },
  { id: 258, src: d258, type: 'image' },
  { id: 259, src: d259, type: 'image' },
  { id: 260, src: d260, type: 'image' },
  { id: 261, src: d261, type: 'image' },
  { id: 262, src: d262, type: 'image' },
  { id: 263, src: d263, type: 'image' },
  { id: 264, src: d264, type: 'image' },
  { id: 265, src: d265, type: 'image' },
  { id: 266, src: d266, type: 'image' },
  { id: 267, src: d267, type: 'image' },
  { id: 268, src: d268, type: 'image' },
  { id: 269, src: d269, type: 'image' },
  { id: 270, src: d270, type: 'image' },
  { id: 271, src: d271, type: 'image' },
  { id: 272, src: d272, type: 'image' },
  { id: 273, src: d273, type: 'image' },
]

export type GalleryProps = {
  onClick: (item: any) => void
}

export default function Images({ onClick }: GalleryProps) {
  return (
    <>
      {images.map((image) => (
        <div
          key={image.id}
          className='h-[450px] w-full cursor-pointer bg-gray-200'
          onClick={() => onClick(image)}
        >
          <img
            src={image.src}
            alt={`gallery-photo-${image.id}`}
            className='h-full w-full object-cover'
          />
        </div>
      ))}
    </>
  )
}
