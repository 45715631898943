import type { Dispatch, SetStateAction } from 'react'
import { getBaseHeaders, getBaseUrl } from '..'

const mockQuestions = [
  {
    idGrupoPreguntasIa: 0,
    idTextosIa: 0,
    imagenUrl1: 'string',
    imagenUrl2: 'string',
    texto: 'string',
  },
]
type Return = typeof mockQuestions
export default async function getGroups(s: Dispatch<SetStateAction<string>> = () => {}) {
  const info = await fetch(getBaseUrl('api/PreguntasIA/getgrupos'), getBaseHeaders())
  const body = await info.json()

  s(body)
  return body as Return
}
