import type { GalleryProps } from './Images'

export const videos = [
  {
    id: 1,
    src: 'https://www.youtube.com/embed/YZbMUtHNtGY?autoplay=1&loop=1&playlist=YZbMUtHNtGY&mute=1',
    type: 'video',
  },
  {
    id: 2,
    src: 'https://www.youtube.com/embed/Wj52jaBsDzw?autoplay=1&loop=1&playlist=Wj52jaBsDzw&mute=1',
    type: 'video',
  },
  {
    id: 3,
    src: 'https://www.youtube.com/embed/PMbhkFnkyXs?autoplay=1&loop=1&playlist=PMbhkFnkyXs&mute=1',
    type: 'video',
  },
  {
    id: 4,
    src: 'https://www.youtube.com/embed/ZT_l9dDEF0E?autoplay=1&loop=1&playlist=ZT_l9dDEF0E&mute=1',
    type: 'video',
  },
  {
    id: 5,
    src: 'https://www.youtube.com/embed/s9aotp0WO-M?autoplay=1&loop=1&playlist=s9aotp0WO-M&mute=1',
    type: 'video',
  },
  {
    id: 6,
    src: 'https://www.youtube.com/embed/pzRA7f1KGD8?autoplay=1&loop=1&playlist=pzRA7f1KGD8&mute=1',
    type: 'video',
  },
  {
    id: 7,
    src: 'https://www.youtube.com/embed/7ykhGwUWPnE?autoplay=1&loop=1&playlist=7ykhGwUWPnE&mute=1',
    type: 'video',
  },
  {
    id: 8,
    src: 'https://www.youtube.com/embed/6PUjpxjaf1Q?autoplay=1&loop=1&playlist=6PUjpxjaf1Q&mute=1',
    type: 'video',
  },
  {
    id: 9,
    src: 'https://www.youtube.com/embed/UiackZpfX8k?autoplay=1&loop=1&playlist=UiackZpfX8k&mute=1',
    type: 'video',
  },
  {
    id: 10,
    src: 'https://www.youtube.com/embed/pmWP_GhC0Zg?autoplay=1&loop=1&playlist=pmWP_GhC0Zg&mute=1',
    type: 'video',
  },
  {
    id: 11,
    src: 'https://www.youtube.com/embed/4nG7I4HoNWA?autoplay=1&loop=1&playlist=4nG7I4HoNWA&mute=1',
    type: 'video',
  },
  {
    id: 12,
    src: 'https://www.youtube.com/embed/TS161OIhZTU?autoplay=1&loop=1&playlist=TS161OIhZTU&mute=1',
    type: 'video',
  },
  {
    id: 13,
    src: 'https://www.youtube.com/embed/e2fbngRSYCk?autoplay=1&loop=1&playlist=e2fbngRSYCk&mute=1',
    type: 'video',
  },
  {
    id: 14,
    src: 'https://www.youtube.com/embed/-g8xJo4UwqM?autoplay=1&loop=1&playlist=-g8xJo4UwqM&mute=1',
    type: 'video',
  },
  {
    id: 15,
    src: 'https://www.youtube.com/embed/AcGi7PwW9Z0?autoplay=1&loop=1&playlist=AcGi7PwW9Z0&mute=1',
    type: 'video',
  },
  {
    id: 16,
    src: 'https://www.youtube.com/embed/jGIzUDwX2s4?autoplay=1&loop=1&playlist=jGIzUDwX2s4&mute=1',
    type: 'video',
  },
  {
    id: 17,
    src: 'https://www.youtube.com/embed/QvA6jc_K6YY?autoplay=1&loop=1&playlist=QvA6jc_K6YY&mute=1',
    type: 'video',
  },
  {
    id: 18,
    src: 'https://www.youtube.com/embed/Xk_ytSdboBc?autoplay=1&loop=1&playlist=Xk_ytSdboBc&mute=1',
    type: 'video',
  },
  {
    id: 19,
    src: 'https://www.youtube.com/embed/Wj52jaBsDzw?autoplay=1&loop=1&playlist=Wj52jaBsDzw&mute=1',
    type: 'video',
  },
  {
    id: 20,
    src: 'https://www.youtube.com/embed/Rccv2qobjp0?autoplay=1&loop=1&playlist=Rccv2qobjp0&mute=1',
    type: 'video',
  },
  {
    id: 21,
    src: 'https://www.youtube.com/embed/JaG23URsi9M?autoplay=1&loop=1&playlist=JaG23URsi9M&mute=1',
    type: 'video',
  },
  {
    id: 22,
    src: 'https://www.youtube.com/embed/V8kQFxbqhyM?autoplay=1&loop=1&playlist=V8kQFxbqhyM&mute=1',
    type: 'video',
  },
  {
    id: 23,
    src: 'https://www.youtube.com/embed/ntZSalVN3p8?autoplay=1&loop=1&playlist=ntZSalVN3p8&mute=1',
    type: 'video',
  },
  {
    id: 24,
    src: 'https://www.youtube.com/embed/-g8xJo4UwqM?autoplay=1&loop=1&playlist=-g8xJo4UwqM&mute=1',
    type: 'video',
  },
]

export default function Videos({ onClick }: GalleryProps) {
  return (
    <>
      {videos.map((video) => (
        <div
          key={video.id}
          className='relative h-[450px] w-full bg-gray-200'
        >
          <iframe
            className='h-full w-full'
            src={video.src}
            title={`YouTube video ${video.id}`}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>

          {/* Capa invisible para capturar clics y abrir el modal */}
          <div
            className='absolute inset-0 cursor-pointer bg-transparent'
            onClick={() => onClick(video)}
          ></div>
        </div>
      ))}
    </>
  )
}
