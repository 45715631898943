import image1 from '@assets/Comparing/image1.png'
import image2 from '@assets/Comparing/image2.png'
import image3 from '@assets/Comparing/image3.png'
import image4 from '@assets/Comparing/image4.png'
import image5 from '@assets/Comparing/image5.png'
import image6 from '@assets/Comparing/image6.png'
import image7 from '@assets/Comparing/image7.png'
import image8 from '@assets/Comparing/image8.png'

import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import { useWebsocketContext } from '@components/Context'
import Navbar from '@components/Navbar'
import t from '../../store/languageActions'
import CustomHandle from '@components/CustonHandle'

import Footer from '@components/Footer'
import PricingPlans from '@components/plans/PricingPlan'
import PremiumPlans from '@components/plans/PremiumPlans'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function RedecorationInfo() {
  const { creditosActuales } = useWebsocketContext()
  return (
    <>
      <div className='bg-black'>
        <ScrollProgressBar />
        <Navbar
          imgRedirectHome={true}
          creditosActuales={creditosActuales}
        />
        <div className='h-14 w-full bg-black'></div>
        <div className='w-full bg-black p-4 text-center text-white md:p-6 lg:p-12'>
          <h1 className='ml-4 text-left font-montserrat-bold text-3xl font-bold md:text-5xl lg:-ml-6'>
            {t('MSG_68')}
          </h1>
        </div>

        <ReactCompareSlider
          itemOne={
            <ReactCompareSliderImage
              src={image1}
              alt='stay image before generate IA'
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={image2}
              alt='stay image after generate IA'
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          }
          handle={<CustomHandle />}
        />

        <div className='w-full bg-black p-4 text-center text-white md:p-6 lg:p-12'>
          <p className='px-4 text-left font-chopin text-sm font-bold sm:px-6 sm:text-base md:px-12 md:text-lg lg:px-44 lg:text-xl'>
            {t('MSG_145')}
          </p>
        </div>

        <ReactCompareSlider
          itemOne={
            <ReactCompareSliderImage
              src={image3}
              alt='stay image before generate IA'
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={image4}
              alt='stay image after generate IA'
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          }
          handle={<CustomHandle />}
        />

        <div className='w-full bg-black p-4 text-center text-white md:p-6 lg:p-12'>
          <p className='px-4 text-left font-chopin text-sm font-bold sm:px-6 sm:text-base md:px-12 md:text-lg lg:px-44 lg:text-xl'>
            {t('MSG_146')}
          </p>
          <p className='mt-4 px-4 text-left font-chopin text-sm font-bold sm:px-6 sm:text-base md:px-12 md:text-lg lg:px-44 lg:text-xl'>
            {t('MSG_147')}
          </p>
          <p className='mt-4 px-4 text-left font-chopin text-sm font-bold sm:px-6 sm:text-base md:px-12 md:text-lg lg:px-44 lg:text-xl'>
            {t('MSG_148')}
          </p>
        </div>
        <ReactCompareSlider
          itemOne={
            <ReactCompareSliderImage
              src={image5}
              alt='stay image before generate IA'
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={image6}
              alt='stay image after generate IA'
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          }
          handle={<CustomHandle />}
        />
        <div className='w-full bg-black p-4 text-center text-white md:p-6 lg:p-12'>
          <p className='px-4 text-left font-chopin text-sm font-bold sm:px-6 sm:text-base md:px-12 md:text-lg lg:px-44 lg:text-xl'>
            {t('MSG_149')}
          </p>
          <p className='mt-4 px-4 text-left font-chopin text-sm font-bold sm:px-6 sm:text-base md:px-12 md:text-lg lg:px-44 lg:text-xl'>
            {t('MSG_150')}
          </p>
        </div>

        <ReactCompareSlider
          itemOne={
            <ReactCompareSliderImage
              src={image7}
              alt='stay image before generate IA'
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={image8}
              alt='stay image after generate IA'
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          }
          handle={<CustomHandle />}
        />
        <div className='h-2 w-full bg-black'></div>
        <PricingPlans darkMode={true} />
        <PremiumPlans />
      </div>
      <Footer />
    </>
  )
}
