import { getBaseHeaders, getBaseUrl } from '..'
import { store } from '../../store'

export default async function newSession() {
  const state = store.getState()

  const { translations } = state
  if (translations && Object.keys(translations).length > 0) {
    return
  }

  const info = await fetch(getBaseUrl('api/Auth/newSession'), getBaseHeaders())
  const data = await info.json()

  if (!data) throw new Error('No data found')

  store.getState().setTranslations(data.textos)

  if (data.idiomas) {
    store.getState().setIdiomas(data.idiomas)
  }

  if (data.token) {
    sessionStorage.setItem('userToken', data.token)
  }

  return data
}
