import Navbar from '@components/Navbar'
import t from '../../store/languageActions'
import SectionPlans from './SectionPlans'
import SectionPlanPremium from './SectionPlanPremium'
import Footer from '@components/Footer'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

export default function PagePlans() {
  return (
    <div className='font-creato-medium'>
      <ScrollProgressBar />
      <Navbar imgRedirectHome />
      <div className='flex h-[180px] flex-col justify-center bg-black pl-5 pt-16 text-white'>
        <div className='mb-8 w-full max-w-5xl text-start'>
          <h1 className='mt-12 font-montserrat-bold text-4xl font-bold text-white'>
            {t('MSG_100')}
          </h1>
        </div>
      </div>
      <SectionPlans />
      <SectionPlanPremium />
      <Footer />
    </div>
  )
}
