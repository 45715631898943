import { getBaseHeaders, getBaseUrl } from '..'

export async function cancelSuscription(password: string): Promise<string> {
  try {
    const url = getBaseUrl(`api/Auth/cancelarSusbcripcion?password=${encodeURIComponent(password)}`)

    const response = await fetch(
      url,
      getBaseHeaders({
        method: 'get',
        headers: {
          // 'Content-Type': 'application/json',
          'Accept-Language': navigator.language || 'es',
        },
      }),
    )

    if (!response.ok) {
      const errorText = await response.text()
      let errorDetail = 'Se produjo un error desconocido.'

      try {
        const errorData = JSON.parse(errorText)
        errorDetail = errorData.detail || errorDetail
      } catch (e) {
        console.error('No se pudo parsear la respuesta de error:', errorText)
      }
      throw new Error(errorDetail)
    }

    const result = await response.text()
    return result
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message)
    } else {
      throw new Error('Error desconocido ocurrió')
    }
  }
}
