import compararOn from '@assets/Comparar ON.png'
import compararOff from '@assets/Comparar Off.png'
import cargando from '@assets/cargando.png'
import compartir from '@assets/compartir.png'
import estrella from '@assets/estrella.png'
import favorito from '@assets/favorito.png'
import favoritoRojo from '@assets/favoritorojo.png'
import galeria from '@assets/galeria-de-imagenes.png'
import guardar from '@assets/guardar.png'
import descargas from '@assets/descargas.png'
import { useEffect, useState, type ChangeEvent } from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import { store } from '../store'
import SaveModal from './SaveModal'
import toggleFavourite from '@proxies/user/toggleFavourite'
import CustomHandle from './CustonHandle'
import { getBaseHeaders, getBaseUrl } from '@proxies/index'
import ShareModal from './ShareModal'

type ProjectCardProps = {
  textTop: string
  imgSrc: string
  isGenerating?: boolean
  handleGenerateClick?: () => void
  handleSaveClick?: (formData: { title: string; description: string }) => void
  showComparingButton?: boolean
  img: string
  idTrabUrlia: number | null
  useImgSrc?: boolean
  isFromRedecorationGeneratePage?: boolean
}

export default function ImageGenerateFinish({
  textTop,
  imgSrc,
  isGenerating,
  handleGenerateClick,
  handleSaveClick,
  showComparingButton = true,
  img,
  useImgSrc = false,
  idTrabUrlia,
  isFromRedecorationGeneratePage = false,
}: ProjectCardProps) {
  const [isComparing, setIsComparing] = useState(false)
  const [formData, setFormData] = useState({ title: '', description: '' })
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 })
  const [imgSrcSize, setImgSrcSize] = useState({ width: 0, height: 0 })
  const [isFavorited, setIsFavorited] = useState(false)
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const texto = store((s) => s.translations)
  async function toggleFavorito() {
    if (idTrabUrlia !== null) {
      try {
        const newState = !isFavorited
        setIsFavorited(newState)
        await toggleFavourite(idTrabUrlia)
      } catch (error) {
        console.error('Error toggling favorite: ', error)
        setIsFavorited(!isFavorited)
      }
    } else {
      console.error('idTrabUrlia is null')
    }
  }

  function getMaxMessageIndex() {
    let index = 0
    while (texto[`MSG_10${index < 10 ? '0' : ''}${index}`]) {
      index++
    }
    return index
  }

  const totalMessages = getMaxMessageIndex()

  const getRandomMessageIndex = (max: number) => Math.floor(Math.random() * max)

  function t(t: string) {
    return texto[t] ?? t
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex(getRandomMessageIndex(totalMessages))
    }, 9000)

    return () => clearInterval(interval)
  }, [totalMessages])

  const getMessageKey = (index: number) => `MSG_10${index < 10 ? '0' : ''}${index}`

  function toggleComparar() {
    if (isGenerating) return
    setIsComparing(!isComparing)
  }

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  function handleSave() {
    if (handleSaveClick) {
      handleSaveClick(formData)
    }
    setModalIsOpen(true)
    setTimeout(() => {
      setModalIsOpen(false)
    }, 1500)
  }

  function handleGenerate() {
    if (handleGenerateClick) {
      handleGenerateClick()
    }
    setFormData({ title: '', description: '' })
  }
  const handleImgLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImgSize({
      width: e.currentTarget.naturalWidth,
      height: e.currentTarget.naturalHeight,
    })
  }
  const handleImgSrcLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImgSrcSize({
      width: e.currentTarget.naturalWidth,
      height: e.currentTarget.naturalHeight,
    })
  }
  const downloadImage = async (idTrabUrlia: number | null, imageName: string = 'descarga') => {
    if (idTrabUrlia === null) {
      console.error('idTrabUrlia is null')
      return
    }

    try {
      const urlDownload = getBaseUrl(`api/TrabajosIA/downloadimage/${idTrabUrlia}`)
      const response = await fetch(urlDownload, getBaseHeaders())
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${imageName}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading the image: ', error)
    }
  }

  const minWidth = Math.min(imgSize.width, imgSrcSize.width)
  // const minHeight = Math.min(imgSize.height, imgSrcSize.height)
  const imageToDisplay = isFromRedecorationGeneratePage ? imgSrc : useImgSrc ? img : imgSrc

  return (
    <div className='relative flex flex-col items-center justify-center bg-white text-black'>
      <div className='absolute inset-0 top-0 z-0 h-[500px] w-full bg-black'></div>
      <h1 className='z-20 ml-8 mt-32 self-start font-montserrat-bold text-4xl text-white'>
        {textTop}
      </h1>

      {/* Contenedor ajustado */}
      <div className='relative mt-4 bg-white shadow-lg lg:w-4/5'>
        <div className='relative mt-4'>
          {!isComparing ? (
            <img
              className='aspect-[2/1] h-full w-full object-contain'
              src={imageToDisplay}
              alt='Generated Image'
              onLoad={handleImgLoad}
            />
          ) : (
            <div
              style={{
                width: '90%',
                maxWidth: '1200px',
                height: 'auto',
                margin: '0 auto',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ReactCompareSlider
                itemOne={
                  <ReactCompareSliderImage
                    src={img}
                    alt='image uploaded at startup by user'
                    style={{
                      ...(isFromRedecorationGeneratePage ? {} : { objectFit: 'contain' }),
                      width: '100%',
                      height: '100%',
                      maxHeight: '80vh',
                    }}
                    onLoad={handleImgLoad}
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src={imgSrc}
                    alt='image generated by artificial intelligence'
                    style={{
                      ...(isFromRedecorationGeneratePage ? {} : { objectFit: 'contain' }),
                      width: '100%',
                      height: '100%',
                      maxHeight: '80vh',
                    }}
                    onLoad={handleImgSrcLoad}
                  />
                }
                handle={<CustomHandle />}
                style={{
                  width: `${minWidth}px`,
                  // height: '100%',
                  ...(isFromRedecorationGeneratePage ? { height: '95%' } : { height: '100%' }),
                }}
              />
            </div>
          )}
          {isGenerating && (
            <>
              <div className='absolute inset-0'></div>
              <div className='absolute inset-0 flex items-center justify-center'>
                <div className='mx-auto my-auto flex h-64 w-96 flex-col items-center justify-center rounded-lg border border-main bg-white'>
                  <img
                    src={galeria}
                    alt='Image gallery'
                    className='mb-4 h-20 w-20'
                  />
                  <img
                    src={cargando}
                    alt='Charging'
                    className='absolute mb-14 ml-20 h-10 w-10 animate-spin rounded-full bg-white'
                  />
                  <div className='mt-4 h-1 w-80 bg-main'></div>
                  <p className='text-md mt-2 text-center font-creato-medium text-black'>
                    {t('MSG_138')}
                  </p>
                  <p className='mt-2 px-5 text-center font-creato-medium text-xs text-black'>
                    {t(getMessageKey(currentMessageIndex) || '')}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='flex flex-col space-y-4 p-4 text-start lg:flex-row lg:space-x-4 lg:space-y-0'>
          <div className='flex w-full flex-col space-y-2'>
            <input
              type='text'
              className='w-full border p-2'
              placeholder={t('MSG_156')}
              name='title'
              value={formData.title}
              onChange={handleChange}
            />
          </div>
          <div className='w-full'>
            <textarea
              className='w-full border p-2 lg:w-3/5'
              placeholder={t('MSG_157')}
              name='description'
              value={formData.description}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='flex flex-col items-center justify-between p-4 lg:flex-row'>
          <div className='mb-4 flex space-x-4 lg:mb-0'>
            <button
              className='text-black hover:text-gray-600'
              onClick={toggleFavorito}
            >
              <img
                src={isFavorited ? favoritoRojo : favorito}
                alt='Heart image for favorites'
                className='h-5 w-6'
              />
            </button>
            <button
              className='text-black hover:text-gray-600'
              onClick={() => downloadImage(idTrabUrlia)}
            >
              <img
                src={descargas}
                alt='Download'
                className='h-5 w-5'
              />
            </button>
            <button
              className='text-black hover:text-gray-600'
              onClick={() => {
                setIsShareModalOpen(true)
              }}
            >
              <img
                src={compartir}
                alt='Share'
                className='h-5 w-5'
              />
            </button>
            <button
              className='text-black hover:text-gray-600'
              onClick={handleSave}
            >
              <img
                src={guardar}
                alt='Save'
                className='h-5 w-5'
              />
            </button>
          </div>
          <div className='flex flex-1 justify-center'>
            <button
              className='flex w-[270px] items-center justify-center rounded bg-black p-2 text-white hover:bg-gray-800'
              onClick={handleGenerate}
            >
              {t('MSG_136')}
              <div className='ml-4 flex h-6 w-10 items-center rounded-lg border-[1.5px] border-white bg-main'>
                <img
                  src={estrella}
                  alt='Star image'
                  className='ml-1 h-4 w-4'
                />
                <p className='ml-1 text-white'>1</p>
              </div>
            </button>
          </div>
          {showComparingButton && (
            <div className='mt-4 flex items-center lg:mt-0'>
              <p className='mr-1 text-xs'>{t('MSG_94')}</p>
              <button
                onClick={toggleComparar}
                className='focus:outline-none'
              >
                <img
                  src={isComparing ? compararOn : compararOff}
                  alt='Comparing button'
                  className='h-5 w-12'
                />
              </button>
            </div>
          )}
        </div>
      </div>
      <SaveModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      />
      <ShareModal
        isOpen={isShareModalOpen}
        onRequestClose={() => {
          setIsShareModalOpen(false)
        }}
        imgSrc={imgSrc}
      />
    </div>
  )
}
