export default function isIOS() {
  if (typeof navigator === 'undefined') return false

  const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent)
  const isMacIntelWithTouch = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
  const isWebKit =
    /AppleWebKit/.test(navigator.userAgent) && !/CriOS|FxiOS/.test(navigator.userAgent)

  return (isIOSDevice || isMacIntelWithTouch) && isWebKit
}
