import { store } from '../../store'

export default async function handleLogout(connection: signalR.HubConnection | null) {
  if (connection) {
    try {
      await connection.stop()
      console.log('SignalR Disconnected.')
    } catch (error) {
      console.error('Error stopping SignalR connection:', error)
    }
  }

  store.getState().setUserToken(null)
  sessionStorage.removeItem('userToken')
  return true
}
