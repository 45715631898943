import { getBaseUrl } from '..'
import { getFormDataHeaders } from '..'

export default async function uploadImage(file: File): Promise<string> {
  const formData = new FormData()
  formData.append('image', file)

  const response = await fetch(getBaseUrl('api/PreguntasIA/uploadimage'), {
    method: 'POST',
    ...getFormDataHeaders(),
    body: formData,
  })

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  const result = await response.text()
  return result
}
