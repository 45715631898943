import getQuestions from '@proxies/ia/getQuestions'
import { useLayoutEffect, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../store'
import t from '../../store/languageActions'
import CardGrid from '../CardGrid'
import Footer from '../Footer'
import ImageOptions from '../ImageOptions'
import Navbar from '../Navbar'
import { useWebsocketContext } from '../Context'
import { ScrollProgressBar } from '@components/ScrollProgressBar'

type Respuesta = {
  idPreguntasIa?: number
  idRespuestasIa?: number
  texto: string
  imagenUrl1?: string
  acceso?: boolean
  especial?: boolean
  respuestas?: Respuesta[]
}

export default function ExteriorsPage() {
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')
  const { creditosActuales } = useWebsocketContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { img, imageFile: imageFileFromState } = location.state || {}

  const [selectedCard, setSelectedCard] = useState<number | null>(null)
  const [imgSrc, setImageSrc] = useState(img || '')
  const [imageFile, setImageFile] = useState<string>(imageFileFromState || '')

  const [preguntasEspeciales, setPreguntasEspeciales] = useState<Respuesta[]>([])
  const [allQuestions, setAllQuestions] = useState<Respuesta[]>([])

  const exterioresId = 214

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await getQuestions(() => {}, exterioresId)
        setAllQuestions(response)
        const filteredQuestions = response.filter((pregunta: Respuesta) => !pregunta.especial)
        setPreguntasEspeciales(filteredQuestions)
      } catch (error) {
        console.error('Error al obtener las preguntas especiales:', error)
      }
    }
    fetchQuestions()
  }, [exterioresId])

  useLayoutEffect(() => {
    if (!token) navigate('/')
    if (img) {
      setImageSrc(img)
    }
  }, [token, navigate, img])

  function handleCardClick(preguntaIndex: number, respuestaIndex: number) {
    setSelectedCard(preguntaIndex)
    const selectedRespuesta = preguntasEspeciales[preguntaIndex]
    const selectedResponse = selectedRespuesta?.respuestas?.[respuestaIndex]
    const responseText = selectedResponse?.texto || 'Texto no disponible'

    if (selectedRespuesta && selectedResponse) {
      const updatedConcatIds = [selectedResponse.idRespuestasIa]

      navigate('/exteriors/styles', {
        state: {
          respuestas: allQuestions,
          texto: responseText,
          concatIds: updatedConcatIds,
          img: imgSrc,
          imageFile: imageFile,
        },
      })
      window.scrollTo(0, 0)
    }
  }

  return (
    <div>
      <ScrollProgressBar />
      <Navbar
        imgRedirectHome={true}
        creditosActuales={creditosActuales}
      />
      <ImageOptions
        title={t('MSG_467')}
        imgSrc={imgSrc}
        isUpload={true}
        setImageSrc={setImageSrc}
        setImageFile={setImageFile}
      />

      <h1 className='my-8 text-center text-2xl font-bold text-main'>{t('MSG_140')}</h1>
      <p className='-mt-6 mb-4 font-light'> {t('MSG_139')}</p>

      <div className='container mx-auto px-4'>
        <div className='-mx-2 flex flex-wrap'>
          {preguntasEspeciales.length > 0 &&
            preguntasEspeciales.map((pregunta, preguntaIndex) => (
              <div key={preguntaIndex}>
                <div className='-mx-2 flex flex-wrap'>
                  {pregunta.respuestas &&
                    pregunta.respuestas.length > 0 &&
                    pregunta.respuestas.map((respuesta, index) => (
                      <div
                        key={index}
                        className='w-full p-2 sm:w-1/2 md:w-1/3 lg:w-1/4'
                      >
                        <CardGrid
                          imageUrl={respuesta.imagenUrl1}
                          text={respuesta.texto}
                          isSelected={selectedCard === preguntaIndex}
                          onClick={() => handleCardClick(preguntaIndex, index)}
                          acceso={respuesta.acceso}
                          WithoutImage={imageFile === img}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
