//@ts-nocheck
import getQuestions from '@proxies/ia/getQuestions'
import randomHeadImage from '@proxies/images/randomHeadImage'
import login from '@proxies/user/login'
import newSession from '@proxies/user/newSession'
import register from '@proxies/user/register'

import getGroups from '@proxies/ia/getGroups'
import allTypeImages from '@proxies/images/allTypeImages'
import randomTypedImage from '@proxies/images/randomTypeImage'
import getUserHistory from '@proxies/user/getUserHistory'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../tailwind.config'
import t from '../store/languageActions'
const john = {
  correo: 'z5512345z@gmail.com',
  password: '1234',
  remember: true,
}
const monica = {
  correo: 'mgblanco1001@gmail.com',
  password: '1234',
  remember: true,
}

export default function TestAPI() {
  const [image, setImage] = useState('')
  const [questions, setQuestions] = useState('')
  const [portada, setPortada] = useState('')
  const [carusel, setCarusel] = useState('')
  const [interior, setInterior] = useState('')
  const [portadaImage, setPortadaImage] = useState('')
  const [caruselImage, setCaruselImage] = useState('')
  const [interiorImage, setInteriorImage] = useState('')
  const [allImages, setAllImages] = useState([])

  return (
    <div>
      <div onClick={() => newSession()}>New Session</div>
      <div onClick={getUserHistory}>History</div>
      <div onClick={login.bind(null, monica)}>Login</div>
      <div
        onClick={register.bind(null, {
          apellidos: 'bayona',
          cif: '',
          ciudad: '',
          confirmarTerminos: true,
          correo: 'z5512345z@gmail.com',
          correoConfirmar: 'z5512345z@gmail.com',
          cPostal: '',
          direccion: '',
          empresa: '',
          idIdioma: 0,
          nombre: 'john',
          pais: '',
          password: '1234',
          passwordConfirmar: '1234',
          telefono: '',
        })}
      >
        Register
      </div>
      <div
        onClick={() => {
          allTypeImages(setAllImages, 'Portada')
        }}
      >
        All header images: {allImages && allImages.map((el) => <img src={el}></img>)}
      </div>
      <div onClick={() => randomHeadImage(setImage)}>Random head image</div>
      <div onClick={() => randomTypedImage(setPortadaImage, 'Portada')}>
        {' '}
        Random portada image{portadaImage && <img src={portadaImage}></img>}
      </div>
      <div onClick={() => randomTypedImage(setCaruselImage, 'Carusel')}>
        Random carousel image{caruselImage && <img src={caruselImage} />}
      </div>
      <div onClick={() => randomTypedImage(setInteriorImage, 'InteriorDesign')}>
        Random interior image{interiorImage && <img src={interiorImage}></img>}
      </div>
      <div onClick={() => getGroups(setQuestions)}>Questions</div>
      {/* <div></div> */}
      <div onClick={() => getQuestions(setPortada, '1')}>
        Portada<div>{JSON.stringify(portada)}</div>
      </div>
      <div onClick={() => getQuestions(setCarusel, '2')}>
        Carusel<div>{JSON.stringify(carusel)}</div>
      </div>
      <div onClick={() => getQuestions(setInterior, '3')}>
        InteriorDesign<div>{JSON.stringify(interior)}</div>
      </div>
      <Link to='/login'>Login</Link> / <Link to='/register'>Register</Link>
      <div>
        <div>Textos: {t('MSG_1')}</div>
        <div>Texto fake: {t('Hola soy John')}</div>
        <div>Aquí viene la imagen: {image && <img src={image}></img>}</div>
        <div>
          Aquí vienen las questions: <pre>{JSON.stringify(questions, null, 2)}</pre>
        </div>
        <div>
          {' '}
          Aquí los tamaños:{' '}
          {Object.keys(config.theme.extend.fontSize).map((el) => (
            <div className={'text-' + el}>{el}</div>
          ))}
        </div>
        <div>
          Colores: <div className='text-main'>Color naranjita</div>
        </div>
      </div>
    </div>
  )
}
