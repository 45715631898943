let rootUrl: string | null = null

export function createRootUrl(): string | null {
  const l = window.location
  console.log('Idioma del navegador:', navigator.language)
  if (process.env.NODE_ENV === 'production') {
    if (l.protocol === 'http:' && l.hostname === 'for2inspiration.ai') {
      const httpsUrl = `https://${l.hostname}${l.port ? ':' + l.port : ''}${l.pathname}${l.search}`
      window.location.replace(httpsUrl)
      return null
    }

    if (l.hostname === 'for2inspiration.com') {
      const newURL = `https://for2inspiration.ai${l.pathname}${l.search}`
      window.location.replace(newURL)
      return null
    }

    if (l.hostname === 'www.for2inspiration.com') {
      const newURL = `https://for2inspiration.ai${l.pathname}${l.search}`
      window.location.replace(newURL)
      return null
    }

    if (l.hostname.startsWith('www.')) {
      const newHostname = l.hostname.replace('www.', '')
      const newURL = `${l.protocol}//${newHostname}${l.port ? ':' + l.port : ''}${l.pathname}${l.search}`
      window.location.replace(newURL)
      return null
    }
  }

  const split = l.hostname.split('https://')
  let newURL = split
    .map((el, i) => {
      if (i === 0) {
        return el + 'api.'
      } else {
        return el
      }
    })
    .join('')

  if (l.port !== '') {
    newURL += ':' + l.port
  }

  console.log('New url', newURL)
  return 'https://api.for2inspiration.ai'
}

export function getBaseUrl(url: string): string {
  if (rootUrl === null) {
    rootUrl = createRootUrl()
  }

  if (rootUrl === null) {
    throw new Error('rootUrl is null')
  }

  return `${rootUrl}/${url}`
}

export function getUserToken(): string {
  const token = sessionStorage.getItem('userToken')
  return `buda ${token || ''}`
}

type Header = {
  method?: 'get' | 'post'
  body?: Record<string, unknown> | string | undefined
  headers?: Record<string, unknown>
}

export function getBaseHeaders({
  method = 'get',
  body = undefined,
  headers = {},
}: Header = {}): RequestInit {
  const toSend: RequestInit = {
    method,
    body: body && typeof body !== 'string' ? JSON.stringify(body) : body,
    headers: {
      Authorization: getUserToken(),
      mode: 'cors',
      'Accept-Language': navigator.language ?? 'en',
      ...headers,
    },
  }

  if (!body) {
    delete toSend.body
  }

  return toSend
}

export function getFormDataHeaders(): RequestInit {
  return {
    headers: {
      Authorization: getUserToken(),
      mode: 'cors',
      'Accept-Language': navigator.language ?? 'en',
      // No 'Content-Type' ya que el navegador lo manejará automáticamente para FormData
    },
  }
}
