import Modal from 'react-modal'
import { useEffect } from 'react'
import { store } from '../store'
import imgFrequentQuestions from '@assets/foto ia2.jpg'

type FrequentQuestionsProps = {
  isOpen: boolean
  onRequestClose: () => void
}

Modal.setAppElement('#root')

export default function FrequentQuestions({ isOpen, onRequestClose }: FrequentQuestionsProps) {
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Términos y condiciones'
      className='relative mx-auto my-8 max-h-[80vh] w-full max-w-4xl overflow-y-auto rounded-md bg-white p-8 text-black'
      overlayClassName='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-20'
    >
      <button
        onClick={onRequestClose}
        className='absolute right-2 top-2 text-xl font-bold'
      >
        &times;
      </button>
      <h2 className='mb-4 text-2xl'>{t('MSG_386')} </h2>

      <span className='font-creato-bold'> {t('MSG_387')}</span>

      <div className='mb-3'> {t('MSG_388')}</div>

      <span className='font-creato-bold'> {t('MSG_389')}</span>

      <div className='mb-3'>{t('MSG_390')}</div>

      <p className='font-creato-bold'>{t('MSG_391')}</p>
      {t('MSG_392')}
      <div className='mb-3'>{t('MSG_393')}</div>

      <p className='font-creato-bold'>{t('MSG_394')}</p>
      <div className='mb-3'>{t('MSG_395')}</div>

      <p className='font-creato-bold'>{t('MSG_396')}</p>
      <div className='mb-3'>{t('MSG_397')}</div>

      <p className='font-creato-bold'>{t('MSG_398')}</p>
      <div className='mb-3'>{t('MSG_399')}</div>

      <p className='font-creato-bold'>{t('MSG_400')}</p>
      <div className='mb-3'>{t('MSG_401')}</div>

      <p className='font-creato-bold'>{t('MSG_402')}</p>
      <div className='mb-3'>{t('MSG_403')}</div>

      <p className='font-creato-bold'>{t('MSG_404')}</p>
      {t('MSG_405')}

      <div className='mb-8'>{t('MSG_406')}</div>

      <img
        src={imgFrequentQuestions}
        alt='frequently asked questions image indicating how to take the photo to work with AI'
        className='my-4 w-full px-4 md:w-auto md:px-20'
      />

      <div className='mt-8'>{t('MSG_407')}</div>
      {t('MSG_408')}

      <div className='mb-3'>{t('MSG_409')}</div>
    </Modal>
  )
}
