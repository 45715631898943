import { useState } from 'react'
import IncidentModal from './IncidentModal'
import { store } from '../store'

type Item = {
  id: number
  title: string
  imgSrc: string
  acceso: boolean
}

type SelectionStyleProps = {
  title: string
  items: Item[]
  onSelect: (title: string, selectedItemTitle: string, id: number) => void
  selections: Record<string, string>
}

export default function SelectionStyle({
  title,
  items,
  onSelect,
  selections,
}: SelectionStyleProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const texto = store((s) => s.translations)

  function t(t: string) {
    return texto[t] ?? t
  }

  function handleSelect(id: number) {
    const selectedItem = items.find((item) => item.id === id)
    if (selectedItem && selectedItem.acceso) {
      onSelect(title, selectedItem.title, selectedItem.id)
    } else if (selectedItem && !selectedItem.acceso) {
      console.error(`Access denied for item with id ${id}`)
      setModalMessage(t('MSG_374'))
      setModalIsOpen(true)
    }
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div className='container mx-auto mt-6'>
      <div className='flex items-end'>
        <div className='w-48 text-left'>
          <h2 className='border-2 border-main p-1 text-center font-chopin text-xl font-bold'>
            {title}
          </h2>
        </div>
        <div className='flex-grow border-t-2 border-main'></div>
      </div>
      <div className='border-b-2 border-l-2 border-r-2 border-main px-4 py-8'>
        <div className='flex flex-wrap justify-start'>
          {items.map((item) => {
            let comparison = selections[title] === item.title
            if (item.title === 'IA' && !selections[title]) {
              comparison = true
            }
            const isSelected = comparison && item.acceso

            return (
              <div
                key={`${item.id}-${comparison}`}
                className='flex w-full px-1 py-2 sm:w-1/2 lg:w-1/3'
              >
                <div
                  onClick={() => handleSelect(item.id)}
                  className={`group relative w-full cursor-pointer border ${
                    isSelected ? 'border-4 border-main' : 'border-transparent'
                  } ${!item.acceso ? 'cursor-not-allowed' : ''}`}
                >
                  <div
                    className={`absolute -top-4 left-2 z-20 min-w-[9rem] rounded-md border px-2 py-1 shadow-md ${
                      isSelected ? 'bg-main text-white' : 'bg-white text-main'
                    }`}
                  >
                    {isSelected && <span className='mr-1.5 text-green-500'>✔</span>}
                    {item.title}
                  </div>
                  <div className='relative'>
                    <img
                      src={item.imgSrc}
                      alt={item.title}
                      className={`h-60 w-full object-cover ${!item.acceso ? 'cursor-default bg-white opacity-30' : ''}`}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <IncidentModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        message={modalMessage}
        onCloseNavigate={closeModal}
      />
    </div>
  )
}
