import { useNavigate } from 'react-router-dom'
import one from '@assets/one.png'
import three from '@assets/three.png'
import two from '@assets/two.png'
import { store } from '../store'
import t from '../store/languageActions'

export default function StepsSection() {
  const steps = [
    {
      number: one,
      title: t('MSG_35'),
    },
    {
      number: two,
      title: t('MSG_36'),
    },
    {
      number: three,
      title: t('MSG_37'),
    },
  ]

  const navigate = useNavigate()
  const token = store((s) => s.userToken) ?? sessionStorage.getItem('userToken')

  const handleButtonClick = () => {
    if (token) {
      window.scrollTo(0, 0)
      navigate('/user')
    } else {
      navigate('/register')
    }
  }

  return (
    <div className='bg-black py-16 text-white'>
      <div className='container mx-auto px-4'>
        <h2 className='mb-12 text-center font-creato-bold text-2xl leading-snug tracking-tight text-white'>
          {t('MSG_66')}
        </h2>
        <div className='mb-12 flex flex-col items-center justify-center space-y-8 lg:flex-row lg:gap-x-28 lg:space-y-0'>
          {steps.map((step, index) => (
            <div
              key={index}
              className='flex flex-col items-center text-center'
            >
              <img
                src={step.number}
                alt={`Number ${index + 1} ${step.title}`}
                className='mb-4 h-32 w-32'
                loading='lazy'
              />
              <h3 className='font-creato-bold text-lg text-white'>{step.title}</h3>
            </div>
          ))}
        </div>
        <div className='mt-8 flex justify-center'>
          <button
            className='transform rounded-md bg-main px-4 py-2 font-creato-bold text-white transition-transform duration-200 ease-in-out hover:scale-105 focus:scale-105 active:scale-95'
            onClick={handleButtonClick}
          >
            {t('MSG_71')}
          </button>
        </div>
      </div>
    </div>
  )
}
