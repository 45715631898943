import t from '../../store/languageActions'
import imagePlanner from '@assets/Planner/imagePlannerInfo2.jpg'
import imagePlanner2 from '@assets/Planner/imagePlannerInfo3.jpg'
import imagePlanner3 from '@assets/Planner/imagePlannerInfo4.jpg'
import imagePlanner4 from '@assets/Planner/imagePlannerInfo5.jpeg'
import imagePlanner5 from '@assets/Planner/imagePlannerInfo6.jpg'
import imagePlanner6 from '@assets/Planner/imagePlannerInfo7.jpg'
import imagePlanner7 from '@assets/Planner/imagePlannerInfo8.png'
import CarouselPlannerInfo from './CarouselPlannerInfo'

export default function ImagePlannerSection() {
  return (
    <>
      <div className='my-4 w-full space-y-4 bg-black text-left text-white sm:my-8 sm:p-4 lg:mt-4'>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_498')}
        </h1>
      </div>

      {/* Primera imagen */}
      <div className='relative w-full overflow-hidden lg:px-[24%]'>
        <div className='relative flex w-full items-center justify-center'>
          <img
            src={imagePlanner}
            alt='Planner Image'
            className='aspect-[16/9] w-full object-contain'
          />
        </div>
      </div>

      <div className='my-4 w-full space-y-4 bg-black text-left text-white sm:my-8 sm:p-4 lg:mt-4'>
        <p className='px-1 font-creato-bold text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_500')}
        </p>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_499')}
        </h1>
      </div>

      {/* Segunda imagen */}
      <div className='relative w-full overflow-hidden lg:px-[24%]'>
        <div className='relative flex w-full items-center justify-center'>
          <img
            src={imagePlanner2}
            alt='Planner Image'
            className='aspect-[16/9] w-full object-contain'
          />
        </div>
      </div>

      {/* Grid de imágenes responsive con proporciones ajustadas */}
      <div className='mx-auto -mt-3 w-full lg:px-[24%]'>
        <div className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
          {/* Imágenes con la misma proporción */}
          <div className='relative aspect-[16/8] w-full overflow-hidden'>
            <img
              src={imagePlanner3}
              alt='Planner Image 3'
              className='h-full w-full object-cover'
            />
          </div>
          <div className='relative aspect-[16/8] w-full overflow-hidden'>
            <img
              src={imagePlanner4}
              alt='Planner Image 4'
              className='h-full w-full object-cover'
            />
          </div>
          <div className='relative aspect-[16/8] w-full overflow-hidden'>
            <img
              src={imagePlanner5}
              alt='Planner Image 5'
              className='h-full w-full object-cover'
            />
          </div>
          <div className='relative aspect-[16/8] w-full overflow-hidden'>
            <img
              src={imagePlanner6}
              alt='Planner Image 6'
              className='h-full w-full object-top'
            />
          </div>
        </div>
      </div>

      <div className='my-4 w-full space-y-4 bg-black text-left text-white sm:my-8 sm:p-4 lg:mt-4'>
        <p className='px-1 font-creato-bold text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_501')}
        </p>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_502')}
        </h1>
      </div>

      {/* Imagen debajo del grid */}
      <div className='relative w-full overflow-hidden lg:px-[24%]'>
        <div className='relative flex w-full items-center justify-center'>
          <img
            src={imagePlanner7}
            alt='Planner Image'
            className='aspect-[16/9] w-full object-contain'
          />
        </div>
      </div>

      <div className='my-4 w-full space-y-4 bg-black text-left text-white sm:my-8 sm:p-4 lg:mt-4'>
        <p className='px-1 font-creato-bold text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_503')}
        </p>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_504')}
        </h1>
      </div>

      <CarouselPlannerInfo />

      <div className='my-4 w-full space-y-4 bg-black text-left text-white sm:my-8 sm:p-4 lg:mt-4'>
        <p className='px-1 font-creato-bold text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_505')}
        </p>
        <h1 className='px-1 font-chopin text-base sm:px-4 sm:text-lg md:px-8 md:text-lg lg:px-[24%] lg:text-lg'>
          {t('MSG_506')}
        </h1>
      </div>
    </>
  )
}
