import d1 from '@assets/Planner/C1.jpg'
import d2 from '@assets/Planner/C2.jpeg'
import d3 from '@assets/Planner/C3.jpeg'
import d4 from '@assets/Planner/C4.jpeg'
import d5 from '@assets/Planner/C5.jpeg'
import d6 from '@assets/Planner/C6.png'
import d7 from '@assets/Planner/C7.jpeg'
import d8 from '@assets/Planner/C8.jpg'
import d9 from '@assets/Planner/C9.png'
import d10 from '@assets/Planner/C10.png'
import d11 from '@assets/Planner/C11.png'
import d12 from '@assets/Planner/C12.png'
import d13 from '@assets/Planner/C13.jpeg'
import d14 from '@assets/Planner/C14.png'
import d15 from '@assets/Planner/C15.jpeg'
import d16 from '@assets/Planner/C16.png'
import d17 from '@assets/Planner/C17.jpeg'
import d18 from '@assets/Planner/C18.png'

const images = [d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12, d13, d14, d15, d16, d17, d18]
const repeatedImages = Array(7).fill(images).flat()
export default function CarouselPlannerInfo() {
  return (
    <div className='relative w-full overflow-hidden'>
      <div className='mb-8 flex animate-slide-left-infinite'>
        {repeatedImages.map((el, index) => (
          <div
            key={index}
            className='h-[25vh] w-[90%] flex-shrink-0 sm:w-[60%] md:h-[35vh] md:w-[75%] lg:h-[50vh] xl:w-[40%]'
          >
            <img
              className='h-full w-full object-cover p-0.5'
              src={el}
              alt={`gallery-photo-${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
